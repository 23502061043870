import { ElectionType } from 'models'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { dayjsToString } from 'utils/convertors/date'
import { useElectionTitle } from '../../hooks'
import { BallotingElection } from '../../models/balloting-election'
import style from './index.module.scss'

type ElectionItemProps = {
    election: BallotingElection
}

function ElectionItem({ election }: ElectionItemProps) {
    const { t } = useTranslation('voter')
    const navigate = useNavigate()
    const { electionTitle } = useElectionTitle(election)

    const onlineVotingDateText = t('online_voting_date')
    const onlineVotingStartsText = t('online_voting_starts')
    const onlineVotingPeriodText = t('online_voting_period')
    const startDate = dayjsToString(election.start, 'MM/DD/YYYY')
    const electionDay = election.electionType === ElectionType.RBC_ELECTION
        ? dayjsToString(election.end, 'MM/DD/YYYY')
        : dayjsToString(election.electionDay, 'MM/DD/YYYY')

    let dateDisplay
    if (election.electionType === ElectionType.BREAKING_TIE) {
        dateDisplay = <span>{`${onlineVotingStartsText} ${startDate}`}</span>
    } else if (election.electionType === ElectionType.OFFICERS_ELECTION) {
        dateDisplay = <span>{`${onlineVotingDateText} ${startDate}`}</span>
    } else {
        dateDisplay = <span>{`${onlineVotingPeriodText} ${startDate} - ${electionDay}`}</span>
    }

    const handleVoteClick = () => {
        navigate(`/balloting/${election.id}`)
    }

    return (
        <div tabIndex={0} className={style.electionWrapper}>
            <div className={style.textWrapper}>
                <div className={style.election}>
                    {electionTitle}
                </div>
                <div className={style.votePeriodWrapper}>
                    <div className={style.disk} />
                    <div className={style.votePeriod}>
                        {dateDisplay}
                    </div>
                </div>
            </div>
            {election.hasVoted ? (
                <div className={style.statusesBlock}>
                    <div className={style.statusesWrapper}>
                        <span className={style.statuses}>{t('voted')}</span>
                    </div>
                </div>
            ) : (
                <button
                    className={`btn-main-primary ${style.voteButton}`}
                    onClick={handleVoteClick}
                >
                    {t('vote_online')}
                </button>
            )}
        </div>
    )
}

export default ElectionItem
