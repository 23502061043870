import { useAvailableElections } from 'api'
import IconWrapper from 'components/atoms/IconWrapper'
import { electionEditing } from 'features/electionEditing'
import { useAuth, useRoleMap } from 'hooks'
import { ReactComponent as Plus } from 'icons/plus.svg'
import { ElectionType } from 'models'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import style from './index.module.scss'

function RbcElectionsHeader() {
    const { t } = useTranslation('election')
    const { toString } = useRoleMap()
    const { auth } = useAuth()
    const navigate = useNavigate()

    const { mutateAsync: planRbcElectionPeriod } = electionEditing.rbcElection.api.mutations.usePlanRbcElectionPeriod()
    const { data: availableElections } = useAvailableElections()

    const createElection = async (replace: boolean = true) => {
        const periodId = await planRbcElectionPeriod()
        navigate(`/rbc/${periodId}`, { replace })
    }

    const isRbcElectionAvailable = availableElections?.some(
        election => election.electionType === ElectionType.RBC_ELECTION
    )

    return (
        <div className={style.wrapper}>
            <div className={style.content}>
                <div className={style.greeting}>
                    {auth?.role ? t('hello_role', { role: toString(auth.role) }) : ''}
                    <div className={style.subgreeting}>{t('rbc_dashboard')}</div>
                </div>
                <button className="btn-main-primary-md" onClick={() => createElection()}
                    disabled={!isRbcElectionAvailable}
                >
                    <IconWrapper><Plus width="18" height="18" /></IconWrapper>
                    {t('create_an_rbc_election')}
                </button>
            </div>
        </div>
    )
}

export default RbcElectionsHeader