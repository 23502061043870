import Collapse from 'components/atoms/Collapse'
import { ElectionType, ReportFormat, ReportType } from 'models'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ReportGroup } from '../../model'
import { ReportFilter } from '../../model/report-filter'
import ReportGroupItems from '../ReportGroupItems'
import style from './index.module.scss'

function Reports() {
    const { t } = useTranslation('administration')

    const reports: ReportGroup[] = useMemo((): ReportGroup[] => [
        {
            label: t('balloting_for_regional_bahai_councils'),
            key: 'rbc',
            items: [
                {
                    type: ReportType.AnnualLsaMemberParticipation,
                    format: ReportFormat.XLSX,
                    label: t('annual_lsa_member_participation_by_regions'),
                    key: 'almp_rbc',
                    filters: [ReportFilter.Rbc]
                },
                {
                    type: ReportType.RbcAnnualVotersParticipationConsolidatedReport,
                    format: ReportFormat.PDF,
                    label: t('annual_voters_participation'),
                    key: 'avpc_rbc',
                    filters: []
                },
                {
                    type: ReportType.TellingStationCommentsReport,
                    format: ReportFormat.XLSX,
                    label: t('comments_regarding_telling_process_consolidated'),
                    key: 'tsc_rbc',
                    electionType: ElectionType.RBC_ELECTION,
                    filters: [ReportFilter.BallotingYear]
                },
                {
                    type: ReportType.RbcBallotingStatistics,
                    format: ReportFormat.XLSX,
                    label: t('consolidated_rbc_balloting_statistics'),
                    key: 'cbs_rbc',
                    filters: [ReportFilter.BallotingYear]
                },
                {
                    type: ReportType.RbcContactInformationByRegions,
                    format: ReportFormat.PDF,
                    label: t('contact_information_by_regions'),
                    key: 'cibr_rbc',
                    filters: [ReportFilter.BallotingYear, ReportFilter.RbcElection]
                },
                {
                    type: ReportType.DetailedLsaParticipation,
                    format: ReportFormat.XLSX,
                    label: t('detailed_lsa_participation_by_regions'),
                    key: 'dlpbr_rbc',
                    filters: [ReportFilter.BallotingYear, ReportFilter.RbcElection]
                },
                // {
                //     type: ReportType.TellingStationCommentsReport,
                //     format: ReportFormat.PDF,
                //     label: t('memorandum_rbc_liaison'),
                //     key: 'mrl_rbc',
                //     electionType: ElectionType.RBC_ELECTION,
                //     filters: [ReportFilter.BallotingYear]
                // },
                // {
                //     type: ReportType.TellingStationCommentsReport,
                //     format: ReportFormat.PDF,
                //     label: t('rbc_members_by_regions'),
                //     key: 'rmbr_rbc',
                //     electionType: ElectionType.RBC_ELECTION,
                //     filters: [ReportFilter.BallotingYear]
                // },
                {
                    type: ReportType.RolesAndDemographicData,
                    format: ReportFormat.XLSX,
                    label: t('roles_demographic_data_by_regions'),
                    key: 'radd_rbc',
                    filters: [ReportFilter.BallotingYear, ReportFilter.RbcElection]
                }
            ]
        },
        {
            label: t('national_delegate_elections'),
            key: 'nde',
            items: [{
                type: ReportType.BallotingStatisticsReport,
                format: ReportFormat.XLSX,
                label: t('balloting_statistics'),
                key: 'bs_nde',
                includeSubUnits: false,
                filters: [ReportFilter.BallotingYear]
            },
            {
                type: ReportType.BallotingStatisticsReport,
                format: ReportFormat.XLSX,
                label: t('balloting_statistics_by_sub_units'),
                key: 'bsbysu_nde',
                includeSubUnits: true,
                filters: [ReportFilter.BallotingYear]
            },
            {
                type: ReportType.TellingStationCommentsReport,
                format: ReportFormat.XLSX,
                label: t('comments_regarding_telling_process_consolidated'),
                key: 'tsc_nde',
                electionType: ElectionType.NATIONAL_DELEGATE_ELECTION,
                filters: [ReportFilter.BallotingYear]
            },
            {
                type: ReportType.DetailedVoterParticipation,
                format: ReportFormat.XLSX,
                label: t('detailed_voter_participation'),
                key: 'dvp_nde',
                filters: [ReportFilter.BallotingYear, ReportFilter.ElectoralUnit],
                electionType: ElectionType.NATIONAL_DELEGATE_ELECTION
            },
            {
                type: ReportType.NationalDelegateElectionResultsReport,
                format: ReportFormat.XLSX,
                label: t('election_results'),
                key: 'nde_election_results',
                filters: [ReportFilter.BallotingYear],
                electionType: ElectionType.NATIONAL_DELEGATE_ELECTION
            },
            {
                type: ReportType.InvalidBallotAndVoteoteReasonsReport,
                format: ReportFormat.XLSX,
                label: t('reasons_of_invalid_ballots_n_votes'),
                key: 'roibv_nde',
                electionType: ElectionType.NATIONAL_DELEGATE_ELECTION,
                filters: [ReportFilter.BallotingYear]
            },
            {
                type: ReportType.RejectedUnityWebPushReasonReport,
                format: ReportFormat.XLSX,
                label: t('reasons_of_rejected_unityweb_response'),
                key: 'roruwr_nde',
                electionType: ElectionType.NATIONAL_DELEGATE_ELECTION,
                filters: [ReportFilter.BallotingYear]
            },
            {
                type: ReportType.VoterPartisipationByAgeCategories,
                format: ReportFormat.XLSX,
                label: t('voter_participation_by_age_categories_consolidated'),
                key: 'vpbecc_nde',
                electionType: ElectionType.NATIONAL_DELEGATE_ELECTION,
                filters: [ReportFilter.BallotingYear]
            },
            {
                type: ReportType.VoterPartisipationDetailsReport,
                format: ReportFormat.XLSX,
                label: t('voter_participation_details'),
                key: 'vpd_nde',
                filters: [ReportFilter.BallotingYear],
                electionType: ElectionType.NATIONAL_DELEGATE_ELECTION
            }]
        },
        {
            label: t('lsa_elections'),
            key: 'lsa_elections',
            items: [{
                type: ReportType.TellingStationCommentsReport,
                format: ReportFormat.XLSX,
                label: t('comments_regarding_telling_process_consolidated'),
                key: 'tsc_lsa',
                filters: [ReportFilter.BallotingYear, ReportFilter.ElectionType]
            },
            {
                type: ReportType.DetailedVoterParticipation,
                format: ReportFormat.XLSX,
                label: t('detailed_voter_participation'),
                key: 'dvp_lsa',
                electionTypesInFilter: [ElectionType.RIDVAN_ELECTION, ElectionType.LOCAL_DELEGATE_ELECTION],
                filters: [ReportFilter.BallotingYear, ReportFilter.ElectionType, ReportFilter.LsaInstitution]
            },
            {
                type: ReportType.RidvanConsolidatedReport,
                format: ReportFormat.XLSX,
                label: t('election_results'),
                key: 'ridvan',
                filters: [ReportFilter.BallotingYear],
                electionType: ElectionType.RIDVAN_ELECTION
            },
            {
                type: ReportType.InvalidBallotAndVoteoteReasonsReport,
                format: ReportFormat.XLSX,
                label: t('reasons_of_invalid_ballots_n_votes'),
                key: 'roibv_lsa',
                filters: [ReportFilter.BallotingYear, ReportFilter.ElectionType]
            },
            {
                type: ReportType.RejectedUnityWebPushReasonReport,
                format: ReportFormat.XLSX,
                label: t('reasons_of_rejected_unityweb_response'),
                key: 'roruwr_lsa',
                filters: [ReportFilter.BallotingYear, ReportFilter.ElectionType]
            },
            {
                type: ReportType.VoterPartisipationByAgeCategories,
                format: ReportFormat.XLSX,
                label: t('voter_participation_by_age_categories_consolidated'),
                key: 'vpbecc_lsa',
                electionTypesInFilter: [ElectionType.RIDVAN_ELECTION, ElectionType.LOCAL_DELEGATE_ELECTION],
                filters: [ReportFilter.BallotingYear, ReportFilter.ElectionType]
            },
            {
                type: ReportType.VoterPartisipationDetailsReport,
                format: ReportFormat.XLSX,
                label: t('voter_participation_details'),
                key: 'vpd_lsa',
                filters: [ReportFilter.BallotingYear, ReportFilter.ElectionType]
            },
            {
                type: ReportType.LocalDelegateElections,
                format: ReportFormat.XLSX,
                label: t('voting_statistics'),
                key: 'lde',
                filters: [ReportFilter.BallotingYear]
            }]
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [])

    return (
        <div className={style.reportsWrapper}>
            <Collapse
                className="obs-collapse"
                items={reports.map(({ key, label, items }) => ({
                    key,
                    label,
                    children: <ReportGroupItems items={items} />
                }))}
                defaultActiveKey={['rbc']} />
        </div>
    )
}

export default Reports