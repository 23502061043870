import { Select as AntSelect, Form, FormInstance } from 'antd'
import { Select } from 'components/atoms/Select'
import dayjs from 'dayjs'
import { ElectionStatusType, ElectionType, RidvanElectionPeriod } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toDayjs } from 'utils'
import ElectionEditingProcess from '../../../../components/ElectionEditingProcess'
import {
    useExtractSetUpElectionSchedule,
    useSetUpElectionActions
} from '../../../../hooks'
import { SetUpElectionStepConfig, SetUpTellersStepConfig } from '../../../../models'
import { useAnnounceRidvanElectionPeriod, useSetUpRidvanSchedule } from '../../api'
import { getLocalDelegateStatus } from '../../utils'
import LocalUnitList from '../LocalUnitList'
import VerifyLocalDelegateParticipants from '../VerifyLocalDelegateParticipants'

type LocalDelegateEditingProcessProps = {
    ridvanElectionPeriod: RidvanElectionPeriod
}

function LocalDelegateEditingProcess({ ridvanElectionPeriod }: LocalDelegateEditingProcessProps) {
    const { t } = useTranslation('election')

    const [numberOfDelegates, setNumberOfDelegates] = useState(0)
    const [setUpElectionForm, setSetUpElectionForm] = useState<FormInstance<any>>()

    const { extractSchedule } = useExtractSetUpElectionSchedule()
    const { saveSetUpElectionForm } = useSetUpElectionActions()

    const { mutateAsync: setUpSchedule } = useSetUpRidvanSchedule()
    const { mutateAsync: announceRidvanElectionPeriod } = useAnnounceRidvanElectionPeriod()

    useEffect(() => {
        if (ridvanElectionPeriod) {
            const allDelegates = ridvanElectionPeriod.localDelegateElectionStage!
                .elections.map(e => e.numberOfDelegates)
            setNumberOfDelegates(allDelegates.reduce((sum, current) => sum + current, 0)
            )
        }
    }, [])

    const setUpElectionFormInitial = {
        ballotingYear: t('year_b_e_period',
            {
                year: ridvanElectionPeriod.ballotingYear,
                period: `${dayjs().year()}-${dayjs().year() + 1}`
            }),
        numberOfDelegates,
        numberOfVacancies: numberOfDelegates,
        timeZone: ridvanElectionPeriod.localDelegateElectionStage!.timeZone,
        electionDay: ridvanElectionPeriod.localDelegateElectionStage!.electionDay ?
            toDayjs(ridvanElectionPeriod.localDelegateElectionStage!.electionDay)
            : undefined,
        onlineVotingStart: ridvanElectionPeriod.localDelegateElectionStage!.onlineVotingStart ?
            toDayjs(ridvanElectionPeriod.localDelegateElectionStage!.onlineVotingStart)
            : undefined
    }

    const saveLocalDelegateSetUpForm = async () => {
        if (setUpElectionForm) {
            const electionSchedule = extractSchedule(setUpElectionForm)
            await setUpSchedule({
                schedule: electionSchedule,
                electionPeriod: ridvanElectionPeriod.id.toString(),
                type: ElectionType.LOCAL_DELEGATE_ELECTION
            })
        }
    }

    const getVotingStartDate = () => {
        if (setUpElectionForm) {
            const formValue = setUpElectionForm.getFieldValue('onlineVotingStart')
            if (formValue)
                return formValue
            if (ridvanElectionPeriod && ridvanElectionPeriod.localDelegateElectionStage!.onlineVotingStart) {
                return dayjs(new Date(ridvanElectionPeriod.localDelegateElectionStage!.onlineVotingStart))
            }
        }

        return null
    }

    const saveElectionAsDraftCb = async (currentStepIndex: number) => {
        if (setUpElectionForm) {
            if (currentStepIndex === 0) {
                return await saveSetUpElectionForm({
                    form: setUpElectionForm,
                    saveFunc: saveLocalDelegateSetUpForm
                })
            }
        }

        return Promise.resolve(true)
    }

    const completeElectionSetup = async () => {
        await announceRidvanElectionPeriod({
            type: ElectionType.LOCAL_DELEGATE_ELECTION,
            electionPeriod: ridvanElectionPeriod.id.toString()
        })
    }

    const setUpElectionStepConfig: SetUpElectionStepConfig = {
        saveSetUpForm: saveLocalDelegateSetUpForm,
        formInitialValue: setUpElectionFormInitial,
        extraContent: <Form.Item
            name="numberOfDelegates"
            label={<>
                {t('number_of_delegates')}
            </>}>
            <Select
                disabled={true}
                placeholder={t('number_of_delegates')}>
                <AntSelect.Option value={numberOfDelegates} >
                    {numberOfDelegates}
                </AntSelect.Option>
            </Select>
        </Form.Item>
    }

    const setUpTellersStepConfig: SetUpTellersStepConfig = {
        isStepReady: () => Promise.resolve(true),
        completeElectionSetup,
        content: <LocalUnitList
            elections={ridvanElectionPeriod.localDelegateElectionStage!.elections}
            periodId={ridvanElectionPeriod.id} />
    }

    return (
        <>
            {ridvanElectionPeriod && <ElectionEditingProcess
                electionStatus={getLocalDelegateStatus(ridvanElectionPeriod.localDelegateElectionStage!.elections)}
                announced={ridvanElectionPeriod.localDelegateElectionStage!.elections[0].status
                    !== ElectionStatusType.DRAFT}
                ballotingYear={ridvanElectionPeriod.ballotingYear}
                electionType={ElectionType.LOCAL_DELEGATE_ELECTION}
                getVotingStartDate={getVotingStartDate}
                periodId={ridvanElectionPeriod.id}
                initSetUpElectionForm={setSetUpElectionForm}
                initManageTellersForm={() => { }}
                saveElectionAsDraftCb={saveElectionAsDraftCb}
                setUpElectionStepConfig={setUpElectionStepConfig}
                verifyParticipantsContent={<VerifyLocalDelegateParticipants
                    electionPeriodId={ridvanElectionPeriod.id.toString()}
                    localUnits={ridvanElectionPeriod.localDelegateElectionStage!.elections.map(e => e.localUnitCode)}
                />}
                setUpTellersStepConfig={setUpTellersStepConfig}
            />
            }
        </>
    )
}

export default LocalDelegateEditingProcess