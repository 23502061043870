import { ConfigProvider } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useElectionCandidates } from 'api'
import Card from 'components/atoms/Card'
import IconWrapper from 'components/atoms/IconWrapper'
import Empty from 'components/molecules/Empty'
import OptionalTooltip from 'components/molecules/OptionalTooltip'
import Preloader from 'components/molecules/Preloader'
import Table from 'components/molecules/Table'
import { ballotingAssets } from 'features/ballotingAssets'
import { useAuth, useConfirmPopup, usePopup } from 'hooks'
import { ReactComponent as ArrowDown } from 'icons/arrow-down.svg'
import { Vote } from 'models/ballot/vote'
import { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useEnterBallotsInfo } from '../../hooks'
import style from './index.module.scss'

const MAX_RATED_CANDIDATES_SHOWN = 10
const TOO_MANY_NAMES_KEY = 'TOO_MANY_NAMES'
const DUPLICATED_NAMES_KEY = 'DUPLICATED_NAMES'

type RatedVote = Vote & {
    total: number
}

function RealTimeResults() {
    const { t } = useTranslation('ballot')
    const { info } = useEnterBallotsInfo()
    const { auth } = useAuth()
    const [hovered, setHovered] = useState<RatedVote | undefined>()
    const [candidateId, setCandidateId] = useState<string>()
    const { data: ballotInvalidationReasons }
        = ballotingAssets.api.queries.useBallotInvalidationReasons(auth?.electionId!)
    const { popupPortal, show, hide } = usePopup()
    const { confirmPortal, showConfirm } = useConfirmPopup()
    const { appendVote, canAppendMore, isAlreadyInBallot } = ballotingAssets.hooks.useBallotVotesActions()
    const { showVotesMismatchPopup } = ballotingAssets.hooks.useBallotActions()
    const [loadParticipant, setLoadParticipant] = useState(false)

    const { data: participants, isFetching: isParticipantsFetching } = useElectionCandidates(auth?.electionId!,
        { limit: 1, offset: 0, id: candidateId },
        loadParticipant)


    const ballotId = () => `${auth?.stationId}-${(info.ballots?.length || 0) + 1}`

    const isHovered = (vote: RatedVote) => {
        if (!hovered)
            return false

        return hovered.candidate.id === vote.candidate.id
    }

    useEffect(() => {
        if (!isParticipantsFetching && participants?.length) {
            const participant = participants[0]

            if (participant.candidate) {
                showConfirm({
                    title: t('quick_add'),
                    okText: t('confirm'),
                    text: <Trans i18nKey={t(
                        'please_confirm_adding_the_vote_for_candidate_to_the_current_ballot_entry_form',
                        {
                            interpolation: { escapeValue: false },
                            candidate: `<strong>${participant.name}</strong>`
                        })} components={[<strong />]} />,
                    onOk: async () => {
                        appendVote(participant)
                    }
                })
            }
        }
        setLoadParticipant(false)
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isParticipantsFetching])

    const quickAdd = (vote: RatedVote) => {
        if (isHovered(vote)) {
            const id = vote.candidate.id
            setCandidateId(id)
            if (canAppendMore()) {
                if (isAlreadyInBallot(id)) {
                    showVotesMismatchPopup({
                        show,
                        hide,
                        ballotId: ballotId(),
                        title: t('duplicate_name'),
                        text: t('if_the_name_just_entered_is_a_duplicate_name_select_reason_and_mark_it_as_invalid',
                            {
                                reason: ballotInvalidationReasons?.find(r => r.id === DUPLICATED_NAMES_KEY)?.text
                                    || 'No reasons'
                            }),
                        invalidationReasonsKey: DUPLICATED_NAMES_KEY
                    })
                } else {
                    setLoadParticipant(true)
                }
            } else {
                showVotesMismatchPopup({
                    show,
                    hide,
                    ballotId: ballotId(),
                    title: t('too_many_names'),
                    text: t('if_the_ballot_has_more_names_than_required_select_reason_and_mark_it_as_invalid',
                        {
                            reason: ballotInvalidationReasons?.find(r => r.id === TOO_MANY_NAMES_KEY)?.text
                                || 'No reasons'
                        }),
                    invalidationReasonsKey: TOO_MANY_NAMES_KEY
                })
            }
        }
    }

    const rated = useMemo(() => {
        if (info.ballots?.length) {
            const allBallotEntries: Vote[] = []

            info.ballots.forEach(b => {
                if (!b.deleted && !b.invalidationReason) {
                    b.votes.forEach(v => {
                        if (!v.invalidationReason && v.candidate.id) {
                            allBallotEntries.push({ ...v })
                        }
                    })
                }
            })

            const countedEntries: any = {}

            allBallotEntries.forEach(vote => {
                if (countedEntries[vote.candidate.id]) {
                    countedEntries[vote.candidate.id].total++
                } else {
                    countedEntries[vote.candidate.id] = { ...vote, total: 1 }
                }
            })

            const ratedVotes: RatedVote[] = []
            Object.values(countedEntries).forEach((ratedVote: any) => {
                ratedVotes.push(ratedVote)
            })

            ratedVotes.sort((a, b) => {
                if (a.total === b.total) {
                    if (a.candidate.name < b.candidate.name)
                        return -1
                    if (a.candidate.name > b.candidate.name)
                        return 1

                    return 0
                }

                return b.total - a.total
            })

            return ratedVotes
        }

        return []

    }, [info])

    const columns: ColumnsType<RatedVote> = [
        {
            title: t('common:position'),
            width: 91,
            render: (vote: RatedVote, __, index) => <div
                className={`${style.positionWrapper} ${style.customCell} ${isHovered(vote)
                    ? style.hoveredPosition : ''}`}
                onMouseEnter={() => setHovered(vote)}
                onMouseLeave={() => setHovered(undefined)}
                onClick={() => quickAdd(vote)}
            >
                {
                    isHovered(vote)
                        ?
                        <div className={style.iconWrapper}>
                            <IconWrapper>
                                <ArrowDown className={`${style.icon} rotate-90`} width="22" height="22" />
                            </IconWrapper>
                        </div>
                        : <div className={style.position}>
                            {index + 1}
                        </div>
                }
            </div>
        },
        {
            title: t('common:full_name'),
            width: 261,
            render: (vote: RatedVote) => (
                <div
                    className={`${style.customCell} ${isHovered(vote) ? style.hoveredName : ''}`}
                    onMouseEnter={() => setHovered(vote)}
                    onMouseLeave={() => setHovered(undefined)}
                    onClick={() => quickAdd(vote)}
                    style={{ width: 261 }}>
                    <div style={{ maxWidth: isHovered(vote) ? 145 : 230 }}>
                        <OptionalTooltip contentWrapperClassName="ellipsis">
                            {vote.candidate.name}
                        </OptionalTooltip>
                    </div>
                    {isHovered(vote) && <div className={style.quickAdd}>({t('quick_add')})</div>}
                </div>
            )
        },
        {
            title: t('votes'),
            width: 80,
            render: (vote: RatedVote) => <div
                className={`${style.customCell} ${isHovered(vote) ? style.hoveredVotes : ''}`}
                onMouseEnter={() => setHovered(vote)}
                onMouseLeave={() => setHovered(undefined)}
                onClick={() => quickAdd(vote)}
            >{vote.total}</div>
        }
    ]

    return (
        <Card noHeaderLine noContentPadding className={style.realTimeResults}
            id="real-time-results"
            title={<div className={style.header} >
                <div className={style.title}>
                    {t('real_time_results')}
                </div>
                <div className={style.subtitle}>
                    {t('current_station')}
                </div>
            </div>}

        >
            <div className={style.content}>
                {popupPortal}
                {confirmPortal}
                {isParticipantsFetching && <Preloader />}
                <ConfigProvider renderEmpty={() => (<Empty height={400} text={t('there_are_no_results')} />)}>
                    <Table
                        obsSize="small"
                        obsHeaderSize="small"
                        dataSource={rated ? rated.slice(0, MAX_RATED_CANDIDATES_SHOWN) : []}
                        columns={columns}
                        pagination={false}
                        rootClassName={style.table}
                        noOuterBorder
                        rowKey={(ratedVote) => ratedVote.candidate.id}
                    />
                </ConfigProvider>
            </div>
        </Card>
    )
}

export default RealTimeResults