import ContentWrapper from 'components/atoms/ContentWrapper'
import PageHeader from 'components/atoms/PageHeader'
import { useAuth, usePageHeader, useRoleMap } from 'hooks'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import 'style/shared/collapse-like-card.scss'
import ElectionList from '../features/dashboard/components/ElectionList'
import PageHeaderActions from '../features/dashboard/components/PageHeaderActions'

const Dashboard = () => {
    const { setPageHeader } = usePageHeader()
    const { t } = useTranslation('election')
    const { toString } = useRoleMap()
    const { auth } = useAuth()

    useEffect(() => {
        setPageHeader(
            <PageHeader
                sticky={true}
                greeting={auth?.role ? t('hello_role', { role: toString(auth.role) }) : ''}
                subgreeting={t('to_create_a_new_election_please_click_create_an_election_button')}>
                <PageHeaderActions />
            </PageHeader>
        )

        return () => setPageHeader(null)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ContentWrapper>
            <ElectionList />
        </ContentWrapper>
    )
}

export default Dashboard