import { useOnlineVoting, useVotersCount } from 'api'
import { Can } from 'components/atoms/Can'
import ElectionBadge from 'components/molecules/ElectionBadge'
import { BreakingTieRound, ElectionStatusType } from 'models'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useMergeResultsData } from '../../hooks'
import style from './index.module.scss'

type TieRoundBlockProps = {
    showEndOnlineVotingPopup?: () => void
    showClearAllVotesPopup?: () => void
    showDeleteRoundPopup?: () => void
    showSelectElectionTypeFlowPopup?: (electionId: string) => void
    isRoundEnd?: boolean
    round?: BreakingTieRound
    isLastRound?: boolean
    isSigned?: string | null
}

function TieRoundBlock({
    showEndOnlineVotingPopup,
    showClearAllVotesPopup,
    showSelectElectionTypeFlowPopup,
    showDeleteRoundPopup,
    isRoundEnd,
    round,
    isLastRound,
    isSigned
}: TieRoundBlockProps) {
    const { t } = useTranslation('teller')
    const navigate = useNavigate()
    const { mergedResultsData } = useMergeResultsData()
    const electionId = mergedResultsData.lastRound?.id.toString() || ''
    const { data: votersCount } = useVotersCount(electionId, !!electionId)
    const { data: onlineVoting } = useOnlineVoting(electionId || '', !!electionId)

    return (
        <div className={style.tieRoundWrapper}>
            <div className={style.tieRoundTitle}>
                <div className={style.roundTitle}>
                    {t('revote_using_obs_round_number', {
                        number: isRoundEnd ? round?.roundNumber : mergedResultsData.lastRound?.roundNumber
                    })}
                </div>
                {(isRoundEnd || mergedResultsData.lastRound)
                    && <ElectionBadge status={isRoundEnd ? round!.status
                        : mergedResultsData.lastRound!.status} />
                }
            </div>
            <div className={style.buttonsWrapper}>
                {isRoundEnd
                    ? (round?.status === ElectionStatusType.COMPLETED && (
                        <>
                            <Can I="view" a="DeleteRoundButton">
                                {isLastRound && !isSigned
                                    && (
                                        <div>
                                            <button className="btn-error-tertiary"
                                                onClick={showDeleteRoundPopup}
                                            >
                                                {t('administration:delete_round')}
                                            </button>
                                        </div>
                                    )}
                            </Can>
                            <div>
                                <button className="btn-main-secondary"
                                    onClick={() => navigate(`/tie-round-info/${round?.id}/${round?.roundNumber}`)}>
                                    {t('view_results')}
                                </button>
                            </div>
                        </>
                    ))
                    : <>
                        <div className={style.votesWrapper}>
                            {`${t('election:voted_online')}: ${votersCount?.totalCount || 0}`}
                        </div>
                        <div>
                            <button
                                onClick={showClearAllVotesPopup}
                                className="btn-main-secondary-md">
                                {t('clear_all_votes')}
                            </button>
                        </div>
                        {onlineVoting?.closed
                            ? <div>
                                <button
                                    onClick={() => {
                                        if (mergedResultsData.lastRound?.bypassFlow === null) {
                                            showSelectElectionTypeFlowPopup?.(electionId)
                                        } else if (!mergedResultsData.lastRound?.bypassFlow) {
                                            navigate(`/tie-physical-ballots/${mergedResultsData.lastRound?.id}`,
                                                { replace: true })
                                        }
                                    }}
                                    className="btn-main-primary-md">
                                    {t('proceed_to_telling')}
                                </button>
                            </div>
                            : <div>
                                <button
                                    onClick={showEndOnlineVotingPopup}
                                    className="btn-main-primary-md">
                                    {t('end_online_voting')}
                                </button>
                            </div>
                        }
                    </>
                }
            </div>
        </div>
    )
}

export default TieRoundBlock