import { useElectionSummary } from 'api'
import Input from 'components/atoms/Input'
import FilterableHeaderCell from 'components/molecules/FilterableHeaderCell'
import Preloader from 'components/molecules/Preloader'
import SortableHeaderCell from 'components/molecules/SortableHeaderCell'
import { useFilterable, useJsonReportLoader, useSortable } from 'hooks'
import { ReportType, SortOrder } from 'models'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import 'style/shared/full-data-table/full-data-table.scss'
import { isElectionFrozen } from 'utils'
import { useVotingStatisticTableData } from '../../hooks'
import { LocalityVotingStatistic, VotingStatisticTableData, VotingStatisticTableLocalityFilter } from '../../models'
import { filterLocalities, getTableColumnWidths } from '../../utils'

const DEFAULT_FILTER: VotingStatisticTableLocalityFilter = {
    sortBy: {
        field: 'name',
        order: SortOrder.ASC
    }
}

const VotingStatisticHeader = () => {

    const { electionId } = useParams()
    const { setTableData } = useVotingStatisticTableData()
    const { data: summary, isFetched: isSummaryFetched } = useElectionSummary(electionId || '')
    const [allLocalities, setAllLocalities] = useState<LocalityVotingStatistic[]>([])
    const [localities, setLocalities] = useState<LocalityVotingStatistic[]>([])
    const [filter, setFilter] = useState<VotingStatisticTableLocalityFilter>({ ...DEFAULT_FILTER })
    const { prepareFiltersForRequest } = useFilterable<VotingStatisticTableLocalityFilter>()
    const { changeFilter } = useFilterable<VotingStatisticTableLocalityFilter>()
    const { changeSortBy } = useSortable()
    const { t } = useTranslation()

    const tableColumnWidths = useMemo(() => getTableColumnWidths(), [])

    const onReportReady = useCallback((reportData: VotingStatisticTableData) => {
        if (reportData) {
            const localitiesData = reportData.localities
            setAllLocalities(localitiesData)
            setTableData({
                summary: reportData.summary,
                localities: localitiesData
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setTableData])

    const { isLoading } = useJsonReportLoader({
        reportType: ReportType.OnlineVotingStatistics,
        params: {
            election: electionId,
            status: isElectionFrozen(summary) ? 'FROZEN' : 'ACTIVE'
        },
        onReportReady,
        enabled: isSummaryFetched
    })

    const onSortingChange = (field: string, order?: SortOrder) => {
        setFilter(changeSortBy(filter, field, order) as VotingStatisticTableLocalityFilter)
    }

    useEffect(() => {
        if (!allLocalities.length) return
        const filterToApply = prepareFiltersForRequest(filter)
        const filteredLocalities = filterLocalities(allLocalities, filterToApply)

        setLocalities(prevLocalities => {
            if (JSON.stringify(prevLocalities) !== JSON.stringify(filteredLocalities)) {
                return filteredLocalities
            }

            return prevLocalities
        })
    }, [allLocalities, filter, prepareFiltersForRequest])

    useEffect(() => {
        setTableData(prevData => ({
            ...prevData,
            localities: localities
        }))
    }, [localities, setTableData])

    const filterCellContentWith = (cellWidth: number) => `calc(${cellWidth}px - calc(var(--default-space) * 2))`

    return (
        <>
            {isLoading && <Preloader title={t('teller:working_on_your_statistics')} />}
            <div className="full-data-table-header">
                <div style={{ width: tableColumnWidths.localityName }} className="full-data-table-column">
                    <FilterableHeaderCell
                        size="small"
                        filter={
                            <Input
                                allowClear={true}
                                className="small"
                                style={{ width: filterCellContentWith(tableColumnWidths.localityName) }}
                                value={filter.localityName || undefined}
                                onChange={(e) => setFilter(changeFilter(filter, 'localityName', e.target.value))}
                                placeholder={t('search_by_name')}
                                onClick={(e) => e.stopPropagation()}
                            />
                        }
                        title={
                            <SortableHeaderCell
                                value={filter.sortBy}
                                title={t('teller:lsa_rgp_loc')}
                                field={'localityName'}
                                onChange={onSortingChange}
                            />
                        }
                    />
                </div>
                <div style={{ width: tableColumnWidths.localityCode }} className="full-data-table-column">
                    <FilterableHeaderCell
                        size="small"
                        filter={
                            <Input
                                className="small"
                                style={{ width: filterCellContentWith(tableColumnWidths.localityCode) }}
                                allowClear={true}
                                value={filter.localityCode || undefined}
                                onChange={(e) => setFilter(changeFilter(filter, 'localityCode', e.target.value))}
                                placeholder={t('teller:search_by_code')}
                                onClick={(e) => e.stopPropagation()}
                            />
                        }
                        title={
                            <SortableHeaderCell
                                value={filter.sortBy}
                                title={t('locality_code')}
                                field={'localityCode'}
                                onChange={onSortingChange}
                            />
                        }
                    />
                </div>
                <div style={{ width: tableColumnWidths.eligibleToVote }} className="full-data-table-column">
                    <FilterableHeaderCell
                        size="small"
                        filter={null}
                        title={
                            <SortableHeaderCell
                                value={filter.sortBy}
                                title={t('election:eligible_to_vote')}
                                field={'eligibleToVote'}
                                onChange={onSortingChange}
                            />
                        }
                    />
                </div>
                <div style={{ width: tableColumnWidths.onlineVoters }} className="full-data-table-column">
                    <FilterableHeaderCell
                        size="small"
                        filter={null}
                        title={
                            <SortableHeaderCell
                                value={filter.sortBy}
                                title={t('teller:online_voters')}
                                field={'onlineVoters'}
                                onChange={onSortingChange}
                            />
                        }
                    />
                </div>
                <div style={{ width: tableColumnWidths.paperBallots }} className="full-data-table-column">
                    <FilterableHeaderCell
                        size="small"
                        filter={null}
                        title={
                            <SortableHeaderCell
                                value={filter.sortBy}
                                title={t('teller:voted_with_paper_ballots')}
                                field={'paperBallots'}
                                onChange={onSortingChange}
                            />
                        }
                    />
                </div>
                <div style={{ width: tableColumnWidths.totalVoted }} className="full-data-table-column">
                    <FilterableHeaderCell
                        size="small"
                        filter={null}
                        title={
                            <SortableHeaderCell
                                value={filter.sortBy}
                                title={t('teller:total_voters')}
                                field={'totalVoted'}
                                onChange={onSortingChange}
                            />
                        }
                    />
                </div>
                <div style={{ width: tableColumnWidths.percentageTotalVoted }} className="full-data-table-column">
                    <FilterableHeaderCell
                        size="small"
                        filter={null}
                        title={
                            <SortableHeaderCell
                                value={filter.sortBy}
                                title={t('teller:percentage_of_total_voted_from_eligible')}
                                field={'percentageTotalVoted'}
                                onChange={onSortingChange}
                            />
                        }
                    />
                </div>
            </div>
        </>
    )
}

export default VotingStatisticHeader