import Tooltip from 'components/atoms/Tooltip'
import OptionalTooltip from 'components/molecules/OptionalTooltip'
import { Ballot, Election, InvalidationReason } from 'models'
import { CSSProperties, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { convertUtcToElectionTimeZone } from 'utils'
import { ballotVotesString, inactiveBallotCellClassName, validAndTotalVotesLabel } from '../../utils'
import style from './index.module.scss'

type InternalScrollBallotsTableItemProps = {
    style?: CSSProperties
    ballot: Ballot
    voteInvalidationReasons?: InvalidationReason[]
    ballotInvalidationReasons?: InvalidationReason[]
    election: Election
}

function InternalScrollBallotsTableItem({
    voteInvalidationReasons,
    ballotInvalidationReasons,
    style: itemStyle,
    ballot,
    election
}: InternalScrollBallotsTableItemProps) {
    const { t } = useTranslation()
    const [isStation, setIsStation] = useState(false)
    const votesString = ballotVotesString(
        t, ballot, false,
        voteInvalidationReasons ?? [],
        ballotInvalidationReasons ?? [], isStation)
    const className = inactiveBallotCellClassName(ballot)
    const validVotesCount = validAndTotalVotesLabel(ballot, isStation)

    useEffect(() => {
        setIsStation(!!ballot.station)
    }, [ballot.station])

    return (
        <div className={style.listItem} style={itemStyle}>
            <div style={{
                width: 110
            }}
                className={style.column}>
                {ballot.id}
            </div >
            <div style={{
                width: 80
            }}
                className={style.column}>
                {<Tooltip title={ballot.deleted ? t('ballot:deleted_ballot') : (ballot.invalidationReason
                    ? t('ballot:invalid_ballot') : t('ballot:valid_ballot'))}>
                    <div className={`${style.statusIndicator} ${ballot.deleted
                        ? style.deleted
                        : (ballot.invalidationReason
                            ? style.invalid
                            : style.valid)}`} />
                </Tooltip>}
            </div >
            <div style={{
                width: 713
            }}
                className={style.column}>
                <div className={className}
                    style={{ maxWidth: 680 }}>
                    <OptionalTooltip
                        className={style.tooltip}
                        contentWrapperClassName={'ellipsis'}
                        title={ballotVotesString(
                            t, ballot, true, voteInvalidationReasons ?? [],
                            ballotInvalidationReasons ?? [], isStation)}>
                        {votesString}
                    </OptionalTooltip>
                </div>
            </div >
            <div style={{
                width: 200
            }}
                className={style.column}>
                {<div className={className}>
                    {isStation ?
                        convertUtcToElectionTimeZone(ballot.submitTimeUtc, election.timeZone, 'MM/DD/YYYY hh:mm A')
                        : String.fromCharCode(8212)}
                </div>}
            </div >
            <div style={{
                width: 140
            }}
                className={style.column}>
                {<div className={className}>{
                    isStation ? t('station:station_number', { number: ballot.station.replace('S', '') }) :
                        t('teller:online')}
                </div>}
            </div >
            <div style={{
                width: 100
            }}
                className={style.column}>
                {<div className={className}>
                    {validVotesCount}
                </div>}
            </div >
        </div >
    )
}

export default InternalScrollBallotsTableItem