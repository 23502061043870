import { Form } from 'antd'
import {
    useAppointedTellers,
    useElection,
    useElectionSummary,
    useElectionTellingStations,
    useVoteCountingStarted
} from 'api'
import ContentWrapper from 'components/atoms/ContentWrapper'
import PageHeader from 'components/atoms/PageHeader'
import BackToDashboardBtn from 'components/molecules/BackToDashboardBtn'
import BottomFixedPanel from 'components/molecules/BottomFixedPanel'
import {
    useAuth,
    useConfirmPopup,
    useHandleEntityLoadingError,
    useHeaderLogoRedirect,
    useInfoPopup,
    usePageHeader,
    useScrollToBottom
} from 'hooks'
import { ReactComponent as Plus } from 'icons/plus.svg'
import { ElectionStation, ElectionStationTeller, ElectionType } from 'models'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { isFormValid } from 'utils'
import { useRegisterTellingStations } from '../../api'
import { EMPTY_TELLER, MAX_STATIONS_COUNT, MIN_TELLERS_PER_STATION } from '../../constants'
import { useStationActions, useStations } from '../../hooks'
import StationForm from '../StationForm'
import StationsHeader from '../StationsHeader'
import styles from './index.module.scss'

const electionsWithoutLsaOfficer = [ElectionType.RBC_ELECTION]

const SetUpStationsForm = () => {
    const { t } = useTranslation('station')
    const { auth } = useAuth()
    const { electionId } = useParams()
    const { data: tellingStations, isSuccess: isTellingStationsSuccess, isFetched: stantionsFetched }
        = useElectionTellingStations(electionId || '', !!electionId)
    const { data: election, error } = useElection(electionId!)
    const { data: summary } = useElectionSummary(electionId!, !!electionId!)
    const { stations, setStations } = useStations()
    const { appendNewStation } = useStationActions()
    const { data: availableTellers, isSuccess } = useAppointedTellers(electionId!)
    const { data: voteCountingStarted } = useVoteCountingStarted(electionId || auth!.electionId!,
        !!electionId || !!auth?.electionId)
    const { mutateAsync: registerTellingStations } = useRegisterTellingStations()
    const { showConfirm, confirmPortal } = useConfirmPopup()
    const { showInfo, infoPortal } = useInfoPopup()
    const { setPageHeader } = usePageHeader()
    const { bottomRef, targetRef, setShouldScroll } = useScrollToBottom(stations)
    const { setConfirmationCallback } = useHeaderLogoRedirect()
    const { handleEntityLoadingError } = useHandleEntityLoadingError()

    const [form] = Form.useForm()
    const navigate = useNavigate()

    const backToDashboardClick = async () => {
        showConfirm({
            text: <>{t('would_you_like_to_leave_this_page_without_saving')}</>,
            title: <>{t('leave_current_page')}</>,
            okText: <>{t('leave')}</>,
            cancelText: <>{t('continue_editing')}</>,
            onOk: async () => navigate(-1)
        })
    }

    useEffect(() => {
        if (error) {
            handleEntityLoadingError(error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    useEffect(() => {
        if (!!election?.bypassFlow || !!summary?.mergedBy) {
            navigate('/access-denied', { replace: true })
        }
    }, [election, summary])

    useEffect(() => {
        setPageHeader(
            <PageHeader
                sticky={true}
                greeting={`${tellingStations?.length
                    ? t('manage_telling_station_s')
                    : t('teller:set_up_telling_station_s')}${election?.region?.localUnit
                        ? ': ' + election.region.localUnit
                        : ''
                    }`}
                minHeaderContentWidth={1148}
                subheader={
                    <div className={styles.actions}>
                        <BackToDashboardBtn onClick={backToDashboardClick} />
                        <button type="submit" className="btn-main-primary-md" onClick={formSubmit}>
                            <div>{t('common:save_changes')}</div>
                        </button>
                    </div>
                }
            >
                <StationsHeader electionId={electionId} />
            </PageHeader>
        )

        return () => setPageHeader(null)
    }, [election, tellingStations, stations]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setConfirmationCallback(() => backToDashboardClick)

        return () => {
            setConfirmationCallback(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!election) return

        if (!availableTellers || (availableTellers.length >= MIN_TELLERS_PER_STATION)) return

        const contactLsaSecretaryNote = electionsWithoutLsaOfficer.includes(election.type)
            ? ''
            : <> <br /><br /><strong>{t('teller:please_contact_your_lsa_secretary_to_set_up_tellers')}</strong> </>

        showInfo({
            text: <>
                {t('teller:you_cannot_set_up_telling_station')}
                {voteCountingStarted
                    ? <><br /><br /><strong>{t('teller:please_set_up_at_least_two_tellers')}</strong></>
                    : contactLsaSecretaryNote
                } </>,
            stayOpenedOnCancel: true,
            title: <>{t('teller:no_set_up_tellers')}</>,
            okText: <>{voteCountingStarted ? t('teller:set_up_tellers') : t('ok')}</>,
            onOk: async () => voteCountingStarted ? navigate(`/election-team/${electionId}`) : navigate(-1)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableTellers, election])

    useEffect(() => {
        if (stantionsFetched) {
            if (!stations.length) {
                if (tellingStations?.length) {
                    const sortStations = (stations: ElectionStation[]) => stations.sort((a, b) => {
                        const numA = parseInt(a.id.substring(1), 10)
                        const numB = parseInt(b.id.substring(1), 10)

                        return numA - numB
                    })

                    const sorted = sortStations([...tellingStations])

                    sorted.forEach(s => {
                        s.saved = true

                        s.tellers.forEach(t => (!s.closed && (t.isSavedInOpenStation = true)))
                    })

                    const formatted = sorted.map(station => {
                        if (station.tellers.length < MIN_TELLERS_PER_STATION) {
                            let updateTellers = station.tellers

                            for (let i = 0; i < (MIN_TELLERS_PER_STATION - station.tellers.length); i++) {
                                updateTellers = [...updateTellers, EMPTY_TELLER]
                            }

                            return { ...station, tellers: updateTellers }
                        }

                        return station
                    })

                    setStations(formatted)
                } else {
                    appendNewStation()
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tellingStations, stantionsFetched])

    useEffect(() => {
        form.setFieldsValue(stations?.length
            ? { stations: stations.map(station => ({ tellers: station.tellers })) }
            : { stations: [] })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stations])

    const formSubmit = async () => {
        if (await isFormValid({ form })) {
            showConfirm({
                text: <>{t('are_you_sure_you_want_to_save_changes_of_the_stations')}</>,
                title: <>{t('save_changes')}</>,
                okText: <>{t('save_changes')}</>,
                onOk: async () => {
                    const sentData = {
                        electionId: electionId!,
                        stationsWithTellers: stations.map((station: any, index: number) => ({
                            station: stations[index].id,
                            tellers: station.tellers.map((t: ElectionStationTeller) => t.id)
                        }))
                    }
                    await registerTellingStations(sentData)
                    navigate(-1)
                }
            })
        }
    }

    const onAddStationClick = () => {
        appendNewStation()
        setShouldScroll(true)
    }

    return (
        <>
            {isSuccess && isTellingStationsSuccess &&
                <Form
                    form={form}
                    layout="vertical"
                    autoComplete="off"
                    name="createStations"
                >
                    <div ref={targetRef} className={styles.wrapperForAddBtn}>
                        <ContentWrapper>
                            <Form.List
                                name="stations"
                            >
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <StationForm
                                                key={field.key}
                                                field={field}
                                                remove={remove}
                                                availableTellers={availableTellers}
                                            />
                                        )
                                        )}
                                        <Form.Item>
                                            <BottomFixedPanel>
                                                <button
                                                    type="button"
                                                    className={`btn-main-secondary ${styles.addStationBtn}`}
                                                    disabled={stations.length >= MAX_STATIONS_COUNT}
                                                    onClick={onAddStationClick}
                                                >
                                                    <Plus width="12" height="12" />
                                                    <div>{t('add_new_station')}</div>
                                                </button>
                                            </BottomFixedPanel>
                                        </Form.Item>
                                    </>)
                                }
                            </Form.List>
                        </ContentWrapper>
                    </div>
                </Form>
            }
            <div ref={bottomRef} />
            {confirmPortal}
            {infoPortal}
        </>
    )
}

export default SetUpStationsForm