import { useElectedCandidates, useElectionResultsTie, useElectionSummary, useTieDesignatedCandidates } from 'api'
import Note from 'components/molecules/Note'
import { useRevoteRoundTieCandidates } from 'hooks'
import { Election, ElectionType } from 'models'
import { useTranslation } from 'react-i18next'
import { isElectionFrozen } from 'utils'
import { reportingAssets } from '../../features/reportingAssets'
import AlternativeCandidatesStatistic from '../AlternativeCandidatesStatistic'
import NoLongerServingOfficers from '../NoLongerServingOfficers'
import TieResolvedInfo from '../TieResolvedInfo'
import style from './index.module.scss'

type ElectionCandidatesStatisticProps = {
    election: Election
}

function ElectionCandidatesStatistic({ election }: ElectionCandidatesStatisticProps) {
    const { t } = useTranslation()

    const { data: summary, isFetched: isSummaryFetched } =
        useElectionSummary(election.id.toString(), !!election.id)
    const { data: electedCandidates } = useElectedCandidates(
        election.id.toString(), isElectionFrozen(summary), !!election.id && isSummaryFetched)
    const { data: electionResultTie } = useElectionResultsTie(election?.id.toString() || '', !!election)
    const { data: tieDesignatedCandidates } = useTieDesignatedCandidates(election.id.toString(),
        isElectionFrozen(summary), !!election && isSummaryFetched)
    const { formattedNames: revoteRoundTieCandidates } =
        useRevoteRoundTieCandidates(electionResultTie?.resolution.candidates || []
            , electionResultTie?.candidates || [], tieDesignatedCandidates || [])

    if (election.type === ElectionType.RBC_ELECTION) {
        return <></>
    }

    const getElectionTypeText = (electionType: ElectionType) => {
        switch (electionType) {
            case ElectionType.LOCAL_DELEGATE_ELECTION:
                return t('teller:elected_local_delegate')
            case ElectionType.NATIONAL_DELEGATE_ELECTION:
                return t('teller:elected_delegates')
            default:
                return t('teller:elected_assembly_members')
        }
    }

    const electionTypeText = getElectionTypeText(election.type)

    return (
        <>
            {election.type === ElectionType.NATIONAL_DELEGATE_ELECTION && summary?.tiePresent && (
                <div className={style.warningNote}>
                    <Note mode={'warning'} icon={'filled'}>
                        <div>
                            {t('teller:please_note_the_election_has_resulted_in_a_tie')}
                        </div>
                    </Note>
                </div>
            )}
            <reportingAssets.components.TitleStatistic mainText={electionTypeText} />
            <reportingAssets.components.ListOfCandidates
                candidates={electedCandidates}
                election={election}
                tiePresent={summary?.tiePresent}
            />
            {(revoteRoundTieCandidates.length > 0 || (tieDesignatedCandidates?.length ?? 0) > 0) && (
                <TieResolvedInfo
                    electionId={election?.id.toString() || ''}
                    tieDesignatedCandidates={tieDesignatedCandidates}
                    revoteRoundTieCandidates={revoteRoundTieCandidates}
                />
            )}
            <AlternativeCandidatesStatistic
                election={election}
                tiePresent={summary?.tiePresent}
            />
            <NoLongerServingOfficers election={election} />
        </>
    )
}

export default ElectionCandidatesStatistic