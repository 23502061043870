import Preloader from 'components/molecules/Preloader'
import { useJsonReportLoader } from 'hooks'
import { ReportType } from 'models'
import { useEffect } from 'react'
import { useOfficersElectionsData } from '../../hooks'
import { OfficersElection } from '../../model'

function OfficersElectionsLoader() {
    const { officersElectionsData, setOfficersElectionsData } = useOfficersElectionsData()

    const { isLoading, reportError } = useJsonReportLoader<OfficersElection[]>({
        reportType: ReportType.OfficersElections,
        params: {
            ballotingYear: officersElectionsData?.badiYear
        },
        onReportReady: (report) => {
            setOfficersElectionsData((current) => ({
                ...current,
                allElections: report
            }))
        }
    })

    useEffect(() => {
        setOfficersElectionsData((current) => ({
            ...current,
            isReportLoading: isLoading,
            reportError: reportError || undefined
        }))
    }, [isLoading, reportError, setOfficersElectionsData])

    return <>{isLoading && <Preloader />}</>
}

export default OfficersElectionsLoader