import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { NdeElectoralUnit } from '../../model'

export const useNdeElectoralUnits = (bahaiYear: number, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getNdeElectoralUnits = async () => {
        const result = await axios<NdeElectoralUnit[]>(moduleUrl
            + 'institutions/national-delegate-elections/' + bahaiYear)

        return result.data
    }

    return useQuery({
        queryKey: ['nde-electoral-unit', bahaiYear],
        queryFn: getNdeElectoralUnits,
        enabled
    })
}