import { SelectProps } from 'antd'
import { ColumnsType } from 'antd/es/table'
import Input from 'components/atoms/Input'
import { Select } from 'components/atoms/Select'
import FilterableHeaderCell from 'components/molecules/FilterableHeaderCell'
import MultiSelect from 'components/molecules/MultiSelect'
import OptionalTooltip from 'components/molecules/OptionalTooltip'
import SkeletonTableCell from 'components/molecules/SkeletonTableCell'
import SortableHeaderCell from 'components/molecules/SortableHeaderCell'
import StatisticCounter from 'components/molecules/StatisticCounter'
import Table from 'components/molecules/Table'
import { useAgeCategoryMap, useAllOption, useFilterable, useGenderMap } from 'hooks'
import { useSortable } from 'hooks/useSortable'
import { debounce, forIn } from 'lodash'
import { AgeCategory, ElectionParticipant, Gender, HomeLocality, ParticipantsStatistic } from 'models'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ParticipantFilter } from '../../models'
import style from './index.module.scss'

const DEBOUNCE_TIME_MS = 400
type VerifyParticipantsTableProps = {
    withLocalUnit?: boolean,
    localUnits?: string[],
    participants: ElectionParticipant[]
    participantsStatistic?: ParticipantsStatistic
    onFilterChange: (filter: ParticipantFilter) => void,
    localities?: HomeLocality[],
    cities?: string[],
    onScrollToBottom?: () => void,
    defaultFilter?: ParticipantFilter,
    isFetching?: boolean
}

function VerifyParticipantsTable({ withLocalUnit, localUnits, participants,
    participantsStatistic, onFilterChange, onScrollToBottom,
    defaultFilter, isFetching, localities, cities }
    : VerifyParticipantsTableProps) {
    const { t } = useTranslation()
    const allOption = useAllOption()

    const [filterCleared, setFilterCleared] = useState(false)
    const { toString: ageCategoryToString } = useAgeCategoryMap()
    const { toString: genderToString } = useGenderMap()
    const { changeSortBy } = useSortable()
    const { changeFilter, prepareFiltersForRequest } = useFilterable<ParticipantFilter>()
    const [filter, setFilter] = useState<ParticipantFilter>({ ...defaultFilter } || {})

    const ageCategoryOptions: SelectProps['options'] = []

    forIn(AgeCategory, (value) => {
        ageCategoryOptions.push({ value: value, label: ageCategoryToString(value) })
    })

    const genderOptions: SelectProps['options'] = []
    forIn(Gender, (value) => {
        genderOptions.push({ value: value, label: genderToString(value) })
    })

    const onSortingChange = (field: string) => onChange(changeSortBy(filter, field))

    const clearAllFilters = () => {
        onChange({ ...defaultFilter } || {})
        setFilterCleared(() => true)
    }

    const onChangeWithDebounce = useMemo(
        () =>
            debounce((field: keyof ParticipantFilter, value: string) => {
                const f = changeFilter(filter, field, value)
                onChange(f)
            }, DEBOUNCE_TIME_MS),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [filter]
    )

    const onChange = (f: ParticipantFilter) => {
        onFilterChange?.(prepareFiltersForRequest(f))
        setFilter(f)
        setFilterCleared(() => false)
    }

    useEffect(() => () => {
        onChangeWithDebounce.cancel()
    }, [onChangeWithDebounce])

    const columns: ColumnsType<ElectionParticipant> = [
        {
            title: (
                <FilterableHeaderCell
                    size="small"
                    filter={
                        <Input
                            allowClear
                            className="small"
                            style={{ width: withLocalUnit ? 305 : 330 }}
                            value={filter.name || undefined}
                            onChange={(e) => onChangeWithDebounce('name', e.target.value)}
                            placeholder={t('search_by_name')}
                            onClick={(e) => e.stopPropagation()}
                        />
                    }
                    title={
                        <SortableHeaderCell
                            value={filter.sortBy}
                            title={t('full_name')}
                            field={'NAME'}
                            onChange={onSortingChange}
                        />
                    }
                />
            ),
            dataIndex: 'name',
            render: (name: string) => isFetching
                ? <SkeletonTableCell />
                : <div style={{ maxWidth: `calc(${withLocalUnit ? 320 : 340}px - calc(var(--double-space) * 2)` }}>
                    <OptionalTooltip contentWrapperClassName="ellipsis" title={name}>{name}</OptionalTooltip>
                </div >,
            width: withLocalUnit ? 310 : 340
        },
        {
            title: (
                <FilterableHeaderCell
                    size="small"
                    filter={
                        <Input
                            className="small"
                            allowClear
                            style={{ width: 120 }}
                            value={filter.id || undefined}
                            onChange={(e) => onChangeWithDebounce('id', e.target.value)}
                            placeholder={t('election:search_by_id')}
                            onClick={(e) => e.stopPropagation()}
                        />
                    }
                    title={
                        <SortableHeaderCell
                            value={filter.sortBy}
                            title={t('bahai_id')}
                            field={'ID'}
                            onChange={onSortingChange}
                        />
                    }
                />
            ),
            render: (participant: ElectionParticipant) => isFetching ? <SkeletonTableCell /> : participant.id,
            width: 130
        },
        {
            title: (
                <FilterableHeaderCell
                    size="small"
                    filter={
                        <Select
                            title=""
                            className="small"
                            style={{ width: 100 }}
                            value={filter.gender || undefined}
                            onChange={(value) => onChange(changeFilter(filter, 'gender', value))}
                            placeholder={t('all')}
                            onClick={(e) => e.stopPropagation()}
                            options={[allOption, ...genderOptions]}
                        />
                    }
                    title={t('gender')}
                />
            ),
            render: (participant: ElectionParticipant) => isFetching
                ? <SkeletonTableCell />
                : genderToString(participant.gender),
            width: 99
        },
        {
            title: (
                <FilterableHeaderCell
                    size="small"
                    filter={
                        <Select
                            title=""
                            className="small"
                            style={{ width: 100 }}
                            value={filter.ageCategory || undefined}
                            onChange={(value) => onChange(changeFilter(filter, 'ageCategory', value))}
                            placeholder={t('all')}
                            onClick={(e) => e.stopPropagation()}
                            options={[allOption, ...ageCategoryOptions]}
                        />
                    }
                    title={t('age_category')}
                />
            ),
            render: (participant: ElectionParticipant) => isFetching
                ? <SkeletonTableCell />
                : ageCategoryToString(participant.ageCategory),
            width: 115
        },
        {
            title: (
                <>
                    <FilterableHeaderCell
                        size="small"
                        filter={
                            <MultiSelect
                                searchCleared={filterCleared}
                                onSearchValueChanged={() => setFilterCleared(false)}
                                className="small"
                                popupClassName="small"
                                style={{ width: 118 }}
                                popupWidth={200}
                                value={filter.homeLocality || ([] as any)}
                                onChange={(value) => onChange(
                                    changeFilter(filter, 'homeLocality', value as string[]))}
                                options={localities?.map(l => ({ value: l.code, label: l.code })) || []}
                                placeholder={t('all')}
                            />
                        }
                        title={
                            <SortableHeaderCell
                                value={filter.sortBy}
                                title={t('locality_code')}
                                field={'HOME_LOCALITY'}
                                onChange={onSortingChange}
                            />
                        }
                    />
                </>
            ),
            render: (record: ElectionParticipant) => isFetching
                ? <SkeletonTableCell />
                : record.homeLocality.code,
            width: 135
        },
        {
            title: (
                <FilterableHeaderCell
                    size="small"
                    filter={
                        <MultiSelect
                            searchCleared={filterCleared}
                            onSearchValueChanged={() => setFilterCleared(false)}
                            className="small"
                            popupClassName="small"
                            style={{ width: withLocalUnit ? 190 : 305 }}
                            value={filter.homeLocalityCity || []}
                            onChange={(value) => onChange(
                                changeFilter(filter, 'homeLocalityCity', value as string[]))}
                            options={cities?.map(c => ({ value: c, label: c })) || []}
                            placeholder={t('all')}
                        />
                    }
                    title={
                        <SortableHeaderCell
                            value={filter.sortBy}
                            title={t('city')}
                            field={'HOME_LOCALITY_CITY'}
                            onChange={onSortingChange}
                        />
                    }
                />
            ),
            render: (record: ElectionParticipant) => isFetching
                ? <SkeletonTableCell />
                : <div style={{ maxWidth: `calc(${withLocalUnit ? 204 : 321}px - calc(var(--double-space) * 2)` }}>
                    <OptionalTooltip contentWrapperClassName="ellipsis" title={record.city}>
                        {record.city}
                    </OptionalTooltip>
                </div >,
            width: withLocalUnit ? 204 : 321
        },
        {
            title: (
                <FilterableHeaderCell
                    size="small"
                    filter={
                        <button className="btn-main-tertiary-md" onClick={clearAllFilters}>
                            {t('clear_all_fillters')}
                        </button>
                    }
                    title={
                        <div>
                            {t('election:eligible_to')}
                            <br />
                            {t('election:serve_vote')}
                        </div>
                    }
                />
            ),
            key: 'action',
            width: 135,
            render: (record: ElectionParticipant) => isFetching
                ? <SkeletonTableCell />
                : (
                    <div className={style.disksWrapper}>
                        {record.candidate
                            ? <div className={style.disk} style={{ background: 'var(--surface-status-eligible)' }} />
                            : <div className={style.disk}
                                style={{ background: 'var(--surface-status-not-eligible)' }} />}
                        {record.voter
                            ? <div className={style.disk} style={{ background: 'var(--surface-status-eligible)' }} />
                            : <div className={style.disk}
                                style={{ background: 'var(--surface-status-not-eligible)' }} />}
                    </div>
                )
        }
    ]

    if (withLocalUnit) {
        columns.splice(5, 0, {
            title: (
                <>
                    <FilterableHeaderCell
                        size="small"
                        filter={
                            <MultiSelect
                                searchCleared={filterCleared}
                                onSearchValueChanged={() => setFilterCleared(false)}
                                className="small"
                                popupClassName="small"
                                style={{ width: 100 }}
                                popupWidth={200}
                                value={filter.homeLocalUnit || ([] as any)}
                                onChange={(value) => onChange(
                                    changeFilter(filter, 'homeLocalUnit', value as string[]))}
                                options={localUnits?.map(u => ({ value: u, label: u })) || []}
                                placeholder={t('all')}
                            />
                        }
                        title={
                            <SortableHeaderCell
                                value={filter.sortBy}
                                title={t('election:local_unit')}
                                field={'HOME_LOCAL_UNIT'}
                                onChange={onSortingChange}
                            />
                        }
                    />
                </>
            ),
            render: (record: ElectionParticipant) => isFetching ? <SkeletonTableCell /> : record.homeLocalUnit?.code,
            width: 99
        })
    }

    return <Table
        onScrollToBottom={onScrollToBottom}
        columns={columns}
        pagination={false}
        dataSource={participants}
        rowKey="id"
        headerDividerVisible={true}
        filterable={true}
        obsHeaderSize="small"
        obsSize="small"
        stickyOffset="159px"
        caption={
            <div className={style.captionWrapper}>
                <div className={style.captionText}>
                    <span>{t('election:please_ensure_all_community_members_are_present')}</span>
                    <span>{t('election:if_necessary_update_their_data_in_eMembership2')}</span>
                </div>
                {participantsStatistic && <div className={style.statistic}>
                    <StatisticCounter value={participantsStatistic.totalCount}
                        title={t('election:total_number_of_individuals')} />
                    <StatisticCounter value={participantsStatistic.candidateCount}
                        title={t('election:eligible_to_serve')} />
                    <StatisticCounter value={participantsStatistic.voterCount} title={t('election:eligible_to_vote')} />
                    {withLocalUnit && <StatisticCounter value={localUnits?.length || 0}
                        title={t('election:number_of_local_units')} />}
                </div>}
            </div>
        }
    />

}

export default VerifyParticipantsTable