import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { ElectionType } from 'models'
import { LsaInstitution } from '../../model'

export const useLsaInstitutions = (bahaiYear: number, electionType: ElectionType, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getLsaInstitutions = async () => {
        let election = ''
        switch (electionType) {
            case ElectionType.RIDVAN_ELECTION:
                election = 'ridvan'
                break
            case ElectionType.LOCAL_DELEGATE_ELECTION:
                election = 'local-delegate'
                break
            default:
                election = 'unknown-election'
        }

        const result = await axios<LsaInstitution[]>(moduleUrl
            + `institutions/lsa/${election}/` + bahaiYear)

        return result.data

    }

    return useQuery({
        queryKey: ['lsa-institutions', bahaiYear, electionType],
        queryFn: getLsaInstitutions,
        enabled
    })
}