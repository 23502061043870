import { Ballot, InvalidationReason } from 'models'
import style from './balloting.module.scss'

export const ballotVotesString =
    (t: any, ballot: Ballot, tooltipMode: boolean,
        voteInvalidationReasons: InvalidationReason[],
        ballotInvalidationReasons: InvalidationReason[],
        isStation: boolean) => {

        if (ballot.deleted) {
            return <span key={`${ballot.id}_${tooltipMode}`}>
                {t('ballot:ballot_is_deleted_due_to_the_following_reason',
                    { reason: ballot.deletionComment })}
            </span>
        }
        if (ballot.invalidationReason) {
            const invalidationReason = ballotInvalidationReasons?.find(r => r.id === ballot.invalidationReason)

            return <span key={`${ballot.id}_${tooltipMode}`}>
                {t('ballot:ballot_is_invalid_due_to_the_following_reason',
                    { reason: invalidationReason?.text || 'Unknown reason' })}
            </span>
        }

        if (tooltipMode) {
            return ballotVotesTooltipString(t, ballot, voteInvalidationReasons, isStation)
        }

        return ballot.votes ? ballot.votes.map((v, index) => {
            const trailer = index === (ballot.votes.length - 1) ? '' : ', '
            if (v.valid || !isStation) {
                const candidateName = v.candidate?.name || `[${t('deactivated_member')}]`

                return <span key={`${ballot.id}_${index}`}>{candidateName}{trailer}</span>
            }

            return <span key={`${ballot.id}_${index}`} className={style.invalidVote}>
                {t('ballot:invalid_vote')}{trailer}
            </span>
        }) : <span>No votes</span>
    }

const ballotVotesTooltipString = (
    t: any, ballot: Ballot, voteInvalidationReasons: InvalidationReason[],
    isStation: boolean) => <ul className={style.votes}>
        {(ballot.votes || []).map((v, index) => {
            const candidateName = v.candidate?.name || `[${t('deactivated_member')}]`

            return (
                <li key={index}>
                    {v.valid || !isStation ? candidateName :
                        <>
                            <span className={style.invalidVote}>
                                {`${t('ballot:invalid_vote')}. ${t('reason')}`}:
                            </span>
                            {voteInvalidationReasons?.find(r => r.id ===
                                v.invalidationReason)?.text || 'Unknown cause'}
                        </>
                    }
                </li>
            )
        })}
    </ul>

export const inactiveBallotCellClassName = (ballot: Ballot) => ballot.invalidationReason || ballot.deleted
    ? `${style.customСolor} custom-cell-color` : ''


export const validAndTotalVotesLabel = (ballot: Ballot, isStation: boolean) => {
    if (ballot.deleted || ballot.invalidationReason) {
        return String.fromCharCode(8212)
    }
    const valid = ballot.votes ? ballot.votes.filter(v => v.valid || !isStation).length : 0
    const total = ballot.votes ? ballot.votes.length : 0

    return `${valid}/${total}`
}
