import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { Ballot } from 'models'

export const useBallot = (ballotId: string, electionId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.TellingStation)

    const getBallot = async (): Promise<Ballot> => {
        const response = await axios<Ballot>(moduleUrl + `elections/${electionId}/ballots/${ballotId}`)

        return response.data
    }

    return useQuery({
        queryKey: ['ballot', ballotId, electionId],
        queryFn: getBallot,
        enabled
    })
}