import { useDeleteElectionPeriod } from 'api'
import { useConfirmPopup } from 'hooks'
import { ReactComponent as Bin } from 'icons/bin.svg'
import { ReactComponent as Pen } from 'icons/pen.svg'
import { ElectionStatusType, UrlPeriodType } from 'models'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styles from './index.module.scss'

type RbcElectionActionsProps = {
    electionStatus: ElectionStatusType
    electionPeriod: number
}

function RbcElectionActions({ electionStatus, electionPeriod }: RbcElectionActionsProps) {
    const { t } = useTranslation('election')
    const { showConfirm, confirmPortal } = useConfirmPopup()
    const navigate = useNavigate()

    const { mutateAsync: deleteElectionPeriod } = useDeleteElectionPeriod()

    const deleteElection = () => {
        showConfirm({
            text: <div>
                <div className={styles.lose}>
                    {t('by_deleting_you_will_lose_the_data_entered')}
                </div>
                <div className={styles.highlighted}>
                    {t('are_you_sure_you_want_to_delete_this_election')}
                </div>
            </div>,
            title: t('delete_election'),
            onOk: async () => {
                await deleteElectionPeriod({
                    electionPeriodId: electionPeriod,
                    periodType: UrlPeriodType.RbcElectionPeriods
                })
            },
            okText: t('delete_election'),
            reverseButtons: true
        })
    }

    const navigateToEdit = () => {
        navigate(`/rbc/${electionPeriod}`)
    }

    const navigateToElectionBoard = () => {
        navigate(`/rbc-election-board/${electionPeriod}`)
    }

    return (
        <>
            {confirmPortal}
            {electionStatus === ElectionStatusType.NOT_STARTED &&
                <div>
                    <button
                        className="btn-main-tertiary-md"
                        onClick={() => navigateToEdit()}
                    >
                        <Pen width="15" height="16" />
                        <div>{t('common:edit')}</div>
                    </button>
                </div>
            }
            {(electionStatus === ElectionStatusType.NOT_STARTED
                || electionStatus === ElectionStatusType.DRAFT
                || process.env.NODE_ENV === 'development')
                && <div>
                    <button
                        className="btn-main-tertiary-md"
                        onClick={() => deleteElection()}
                    >
                        <Bin width="15" height="16" />
                        <div>{t('delete_election')}</div>
                    </button>
                </div >
            }
            {electionStatus === ElectionStatusType.DRAFT
                ? <div>
                    <button
                        className="btn-main-primary-md not-underline"
                        onClick={() => navigateToEdit()}
                    >
                        {t('complete_setup')}
                    </button>
                </div>
                :
                <div>
                    <button
                        className={electionStatus === ElectionStatusType.COMPLETED
                            ? 'btn-main-secondary-md not-underline'
                            : 'btn-main-primary-md not-underline'}
                        onClick={() => navigateToElectionBoard()}
                    >
                        {t('go_to_election_board')}
                    </button>
                </div>
            }
        </>
    )
}

export default RbcElectionActions