import { useElectionResultsTie } from 'api'
import OptionalTooltip from 'components/molecules/OptionalTooltip'
import { Election, ElectionResult, ElectionType } from 'models'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { NoLongerServingOfficers } from './../../../../models'
import style from './index.module.scss'

function isElectionResult(candidate: ElectionResult | NoLongerServingOfficers): candidate is ElectionResult {
    return (candidate as ElectionResult).voteCount !== undefined
}

function getStyleForVotes(
    election: Election | undefined,
    isUnresolvedTieCandidate: boolean
) {
    if (isUnresolvedTieCandidate && election?.type === ElectionType.NATIONAL_DELEGATE_ELECTION) {
        return { color: 'var(--text-error)' }
    }

    return undefined
}

type ListOfCandidatesProps = {
    candidates?: Array<ElectionResult | NoLongerServingOfficers>,
    election?: Election,
    tiePresent?: boolean
}

function ListOfCandidates({ candidates, election, tiePresent }: ListOfCandidatesProps) {
    const { t } = useTranslation('teller')

    const { data: electionResultTie } =
        useElectionResultsTie(election?.id?.toString() || '', !!election?.id)

    const unresolvedTieCandidates = useMemo(() => {
        if (!electionResultTie || !candidates) return []

        const candidateIds = new Set(candidates.map(c => c.id))

        const filteredCandidates = electionResultTie.candidates?.filter(c => !candidateIds.has(c.id)) || []

        return filteredCandidates.sort((a, b) => {
            if (a.name < b.name) return -1
            if (a.name > b.name) return 1

            return 0
        })
    }, [electionResultTie, candidates])

    const shouldRenderUnresolvedTieCandidates = election?.type === ElectionType.NATIONAL_DELEGATE_ELECTION
        && election?.hasSubUnits && tiePresent && unresolvedTieCandidates.length > 0

    const renderCandidate = (candidate: ElectionResult | NoLongerServingOfficers,
        index: number, isUnresolvedTieCandidate: boolean = false) => {
        const isLastItem = isUnresolvedTieCandidate
            ? index === unresolvedTieCandidates.length - 1
            : index === candidates!.length - 1 && !shouldRenderUnresolvedTieCandidates

        return (
            <div key={`${candidate.id}-${isUnresolvedTieCandidate ? 'unresolved' : 'main'}`}
                className={`${style.memberInfoWrapper} ${isLastItem ? style.lastMember : ''}`}>
                <div className={style.memberWrapper}>
                    <div className={style.memberCell}>
                        <span className={style.titleText}>
                            <OptionalTooltip contentWrapperClassName="ellipsis">
                                {candidate.id === null ? t('election:vacancy') : candidate.name}
                            </OptionalTooltip>
                        </span>
                        {candidate.id !== null &&
                            <span className={style.subTitleText}>{`${t('bahai_id')}: ${candidate.id}`}</span>
                        }
                    </div>
                    {candidate.id !== null ?
                        <div className={style.memberCell}>
                            <span className={style.phoneNumber}>{isElectionResult(candidate) ?
                                candidate.phone || t('no_phone')
                                : (candidate as NoLongerServingOfficers).reasonForVacancy}</span>
                            <span className={style.subTitleText}>
                                <OptionalTooltip contentWrapperClassName="ellipsis">
                                    {isElectionResult(candidate) ? candidate.email || t('no_email')
                                        : (candidate as NoLongerServingOfficers).officeHeld
                                        || t('no_officer_position')}
                                </OptionalTooltip>
                            </span>
                        </div>
                        :
                        String.fromCharCode(8212)
                    }
                    {isElectionResult(candidate) && <div className={`${style.memberCell} ${style.votesWrapper}`}>
                        <span className={style.titleText}
                            style={getStyleForVotes(election, isUnresolvedTieCandidate)}>
                            {candidate.voteCount}</span>
                        <span className={style.subTitleText}
                            style={getStyleForVotes(election, isUnresolvedTieCandidate)}>
                            {t('votes')}</span>
                    </div>
                    }
                </div>
            </div>
        )
    }

    return (
        <>
            {candidates?.map((candidate, index) => renderCandidate(candidate, index))}
            {shouldRenderUnresolvedTieCandidates &&
                unresolvedTieCandidates.map((candidate, index) => renderCandidate(candidate, index, true))}
        </>
    )
}

export default ListOfCandidates