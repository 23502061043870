import { getRbcBackgroundColor } from 'features/reporting/utils/rbcBackgroundColors'
import { ElectionType, RbcElectionsColoring } from 'models'
import { PropsWithChildren } from 'react'
import style from './index.module.scss'

type ReportLeftCardTitleProps = PropsWithChildren<{
    title: string
    electionType?: ElectionType
    rbcName?: string
    rbcElectionsColoring?: RbcElectionsColoring[]
}>

function ReportLeftCardTitle({ title, electionType, rbcName, children, rbcElectionsColoring
}: ReportLeftCardTitleProps) {

    const rbcStyles = rbcName && rbcElectionsColoring
        ? getRbcBackgroundColor(rbcName, rbcElectionsColoring)
        : { backgroundColor: '', color: '' }
    const isRbcElection = electionType === ElectionType.RBC_ELECTION

    return (
        <div className={style.contentWrapper}>
            <div className={style.sticky}>
                <div className={`${style.title} 
                ${!isRbcElection ? style.titleColor : ''}`}
                    style={isRbcElection ? rbcStyles : {}}
                >{title}</div>
                {children}
            </div>
        </div>
    )
}

export default ReportLeftCardTitle
