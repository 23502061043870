import { SortOrder } from 'models'
import { Voter, VotersFilter } from '../models'
type VoterKey = keyof Voter

export const filterVoters = (allVoters: Voter[], filter: VotersFilter): Voter[] => {
    let voters = [...allVoters]

    const sortingOrder = filter.sortBy?.order || SortOrder.ASC
    const sortingField = (filter.sortBy?.field || 'name') as VoterKey

    voters = voters.sort((a, b) => {
        const compareValues = (val1: any, val2: any): number => {
            if (typeof val1 === 'string' && typeof val2 === 'string') {
                return val1.localeCompare(val2, undefined, { sensitivity: 'base' })
            }
            if (val1 < val2)
                return -1
            if (val1 > val2)
                return 1

            return 0
        }

        if (sortingField === 'verifier') {
            if (!a[sortingField] && b[sortingField])
                return 1
            if (!b[sortingField] && a[sortingField])
                return -1
        }

        let result = compareValues(a[sortingField], b[sortingField])

        if (result === 0 && sortingField !== 'name') {
            result = compareValues(a.name, b.name)
        }

        return sortingOrder === SortOrder.ASC ? result : -result
    })

    if (filter.name) {
        const names = filter.name!.trim().toLowerCase().split(' ').filter(n => !!n)
        names.forEach(name => {
            voters = voters.filter(v => v.name.toLowerCase().includes(name))
        })
    }

    if (filter.id) {
        const id = filter.id!.trim().toLowerCase()
        voters = voters.filter(v => v.id.toLowerCase().includes(id))
    }

    if (filter.state?.length) {
        const states = filter.state!.map(s => s.toLowerCase())
        voters = voters.filter(v => states.includes(v.homeLocality?.state?.toLowerCase()))
    }

    if (filter.homeLocality?.length) {
        const localities = filter.homeLocality!.map(l => l.toLowerCase())
        voters = voters.filter(v => localities.includes(v.homeLocality?.name?.toLowerCase()))
    }

    return voters
}