import { ColumnsType } from 'antd/es/table'
import TableHeaderCell from 'components/atoms/TableHeaderCell'
import Tooltip from 'components/atoms/Tooltip'
import OptionalTooltip from 'components/molecules/OptionalTooltip'
import Table from 'components/molecules/Table'
import { LocalDelegateStageElection } from 'models'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { getTellers } from 'utils'
import TellersTooltipContent from '../../../../components/TellersTooltipContent'
import { useLocalDelegateParticipantsStatistic } from '../../api'
import style from './index.module.scss'

type LocalUnitListProps = {
    elections: LocalDelegateStageElection[],
    periodId: number
}

function LocalUnitList({ elections, periodId }: LocalUnitListProps) {
    const { t } = useTranslation('election')
    const { data: statistic } = useLocalDelegateParticipantsStatistic(periodId.toString())

    const getStatistic = (unitId: string) => {
        if (!statistic) {
            return null
        }

        return statistic[unitId as any]
    }

    const columns: ColumnsType<LocalDelegateStageElection> = [
        {
            title: <TableHeaderCell>{t('local_electoral_unit')}</TableHeaderCell>,
            dataIndex: 'localUnitCode',
            width: 140,
            key: 'localUnitCode'
        },
        {
            title: <TableHeaderCell>{t('#_of_delegates')}</TableHeaderCell>,
            dataIndex: 'numberOfDelegates',
            width: 124,
            key: 'numberOfDelegates'
        },
        {
            title: <TableHeaderCell>{t('eligible_to_vote')} </TableHeaderCell>,
            render: (election: LocalDelegateStageElection) => {
                const stat = getStatistic(election.localUnitCode)

                if (!stat) {
                    return String.fromCharCode(8212)
                }

                return (<>{stat.voterCount}</>)
            },
            width: 140
        },
        {
            title: <TableHeaderCell>{t('eligible_to_serve')} </TableHeaderCell>,
            render: (election: LocalDelegateStageElection) => {
                const stat = getStatistic(election.localUnitCode)

                if (!stat) {
                    return String.fromCharCode(8212)
                }

                return (<>{stat.candidateCount}</>)
            },
            width: 140
        },
        {
            title: <TableHeaderCell>{t('common:chief_teller')} </TableHeaderCell>,
            render: (election: LocalDelegateStageElection) => {
                const tellers = getTellers(election)

                if (tellers.chief) {
                    return <div style={{ maxWidth: `calc(${330}px - calc(var(--double-space) * 2)` }}>
                        <OptionalTooltip contentWrapperClassName="ellipsis">
                            {tellers.chief.name}
                        </OptionalTooltip>
                    </div>
                }

                return String.fromCharCode(8212)
            },
            width: 330
        },
        {
            title: <TableHeaderCell>{t('other_tellers')}</TableHeaderCell>,
            render: (election: LocalDelegateStageElection) => {
                const tellers = getTellers(election)

                if (!tellers.tellers?.length && !tellers.assistants?.length) {
                    return String.fromCharCode(8212)
                }

                const firstTeller = tellers.assistants?.[0] || tellers.tellers?.[0]
                const otherTellersLength = (tellers.tellers?.length || 0) + (tellers.assistants?.length || 0)

                return <div className={style.other}
                    style={{ maxWidth: `calc(${330}px - calc(var(--double-space) * 2)` }}>
                    <OptionalTooltip contentWrapperClassName="ellipsis">
                        {firstTeller.name}
                    </OptionalTooltip>
                    {(otherTellersLength > 1) &&
                        <div className={style.andMore}>{t('common:and')}
                            <Tooltip title={<TellersTooltipContent team={tellers} />}>
                                <span className={style.more}>
                                    {t('common:count_more', { count: otherTellersLength - 1 })}
                                </span>
                            </Tooltip>
                        </div>}
                </div>
            },
            width: 330
        },
        {
            title: <TableHeaderCell>{t('common:action')}</TableHeaderCell>,
            width: 140,
            render: (election: LocalDelegateStageElection) =>
                <Link
                    to={`/election-team/${election.id}`} className={election.tellers?.length ? '' : style.noTellers}
                    state={{ backButtonTitle: t('back_to_list') }}
                >
                    {election.tellers?.length ? t('manage_tellers') : t('set_up_tellers')}
                </Link>,
            key: 'voter_verification'
        }
    ]

    return (
        <>
            {statistic && <Table
                obsSize="small"
                dataSource={elections.sort((a, b) => a.localUnitCode.localeCompare(b.localUnitCode))}
                columns={columns}
                pagination={false}
                stickyOffset="159px"
                headerDividerVisible={true}
                rowKey="id" />
            }
        </>
    )
}

export default LocalUnitList