import { Col, Form, FormInstance, Row } from 'antd'
import { RangePickerProps } from 'antd/es/date-picker'
import { useForm } from 'antd/es/form/Form'
import { useElectionRestrictionsOld } from 'api'
import DatePicker from 'components/atoms/DatePicker'
import { queryClient } from 'config/query-client'
import dayjs from 'dayjs'
import { useHandleEntityLoadingError } from 'hooks'
import { ConfigDateType, ElectionStatusType, ElectionType, RbcElectionSchedule, TimeZone } from 'models'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { isFormValid, toDayjs } from 'utils'
import ElectionEditingProcess from '../../../../components/ElectionEditingProcess'
import {
    useSetUpElectionActions
} from '../../../../hooks'
import { SetUpElectionStepConfig, SetUpTellersStepConfig } from '../../../../models'
import { useAnnounceRbcElectionPeriod, useElectionPeriod, useSetUpSchedule } from '../../api'
import RbcSetUpTellers from '../RbcSetUpTellers'
import RbcVerifyParticipants from '../RbcVerifyParticipants'

function RbcElectionEditingProcess() {
    const { t } = useTranslation('election')
    const { electionPeriodId } = useParams()

    const [setUpElectionForm, setSetUpElectionForm] = useState<FormInstance<any>>()
    const [setUpTellersForm] = useForm()

    const { handleEntityLoadingError } = useHandleEntityLoadingError()

    const { data: electionPeriod, error } = useElectionPeriod(electionPeriodId!)
    const { saveSetUpElectionForm } = useSetUpElectionActions()
    const { data: restrictions } = useElectionRestrictionsOld()
    const { mutateAsync: setUpSchedule } = useSetUpSchedule()
    const { mutateAsync: announce } = useAnnounceRbcElectionPeriod()

    const electionDateText = t('online_voting_ends')

    useEffect(() => {
        if (error) {
            handleEntityLoadingError(error)
        }
    }, [error])

    const schedule = useMemo(() => {
        if (electionPeriod && electionPeriod.elections?.length) {
            const firstElection = electionPeriod.elections[0]

            let timeZone: TimeZone
            if (!firstElection.timeZone) {
                timeZone = null as any
            } else {
                const counts: { [key: string]: number } = {}
                electionPeriod.elections.forEach(election => {
                    counts[election.timeZone] = (counts[election.timeZone] || 0) + 1
                })
                timeZone = (Object.keys(counts).reduce((a, b) => counts[a] > counts[b] ? a : b)) as TimeZone
            }

            return {
                onlineVotingEnd: firstElection.onlineVotingEnd,
                onlineVotingStart: firstElection.onlineVotingStart,
                tellingWeekendStart: firstElection.tellingWeekendStart,
                tellingWeekendEnd: firstElection.tellingWeekendEnd,
                timeZone: timeZone
            }
        }
    }, [electionPeriod])

    const setUpElectionFormInitial = {
        ballotingYear: t('common:year_b_e_period',
            {
                year: electionPeriod?.ballotingYear,
                period: `${dayjs().year()}-${dayjs().year() + 1}`
            }),
        timeZone: schedule?.timeZone || (null as any),
        onlineVotingEnd: schedule?.onlineVotingEnd ?
            toDayjs(schedule.onlineVotingEnd) : undefined,
        onlineVotingStart: schedule?.onlineVotingStart ?
            toDayjs(schedule.onlineVotingStart) : undefined,
        tellingWeekendEnd: schedule?.tellingWeekendEnd ?
            toDayjs(schedule.tellingWeekendEnd) : undefined,
        tellingWeekendStart: schedule?.tellingWeekendStart ?
            toDayjs(schedule.tellingWeekendStart) : undefined
    }

    const saveRbcSetUpForm = async () => {
        if (setUpElectionForm) {
            const formValues = setUpElectionForm.getFieldsValue()
            const rbcElectionSchedule: RbcElectionSchedule = {
                timeZone: formValues.timeZone,
                onlineVotingEnd: formValues?.onlineVotingEnd?.format('YYYY-MM-DD'),
                onlineVotingStart: formValues?.onlineVotingStart?.format('YYYY-MM-DD'),
                tellingWeekendEnd: formValues?.tellingWeekendEnd?.format('YYYY-MM-DD'),
                tellingWeekendStart: formValues?.tellingWeekendStart?.format('YYYY-MM-DD')
            }

            await setUpSchedule({
                schedule: rbcElectionSchedule,
                electionPeriod: electionPeriod?.id.toString() || ''
            })
            queryClient.invalidateQueries(['rbc-election-period', electionPeriodId])
        }
    }

    const completeElectionSetup = async () => {
        await announce(electionPeriod?.id.toString() || '')
        queryClient.invalidateQueries(['rbc-election-period', electionPeriodId])
    }

    const getVotingStartDate = () => {
        if (setUpElectionForm) {
            const formValue = setUpElectionForm.getFieldValue('onlineVotingStart')
            if (formValue)
                return formValue
            if (schedule && schedule.onlineVotingStart) {

                return dayjs(schedule.onlineVotingStart)
            }
        }

        return null
    }

    const tellingWeekendStartDisabledDate: RangePickerProps['disabledDate'] = (current) => {
        if (restrictions) {
            const rbcRestrictions = restrictions.rbcElectionRestriction
            if (rbcRestrictions.voteCountingStartType === ConfigDateType.CERTAIN_DATE) {
                const voteCountingStartDate = dayjs(rbcRestrictions.voteCountingStart, 'YYYY-MM-DD')
                if (voteCountingStartDate.isValid() &&
                    voteCountingStartDate.endOf('day').diff(current.endOf('day'), 'days') > 0) {
                    return true
                }
            }
        }
        if (setUpElectionForm) {
            const onlineVotingStart = setUpElectionForm.getFieldValue('onlineVotingStart')
            if (onlineVotingStart) {
                const onlineVotingStartDate = dayjs(onlineVotingStart, 'YYYY-MM-DD')
                if (onlineVotingStartDate.isValid() &&
                    onlineVotingStartDate.endOf('day').diff(current.endOf('day'), 'days') > 0) {
                    return true
                }
            }
        }
        const isInPast = current && current.endOf('day').diff(dayjs().endOf('day'), 'days') < 0

        return isInPast
    }

    const tellingWeekendEndDisabledDate: RangePickerProps['disabledDate'] = (current) => {
        const isTellingWeekendStartDisabled = tellingWeekendStartDisabledDate(current, { type: 'date' })
        if (isTellingWeekendStartDisabled) {
            return true
        }

        if (setUpElectionForm) {
            const tellingWeekendStart = setUpElectionForm.getFieldValue('tellingWeekendStart')
            if (tellingWeekendStart) {
                const tellingWeekendStartDate = dayjs(tellingWeekendStart, 'YYYY-MM-DD')
                if (tellingWeekendStartDate.isValid() &&
                    tellingWeekendStartDate.endOf('day').diff(current.endOf('day'), 'days') > 0) {
                    return true
                }
            }
        }

        return false
    }

    const onOnlineVotingStartDateChange = () => {
        if (setUpElectionForm) {
            setUpElectionForm.setFieldValue('tellingWeekendStart', null)
            setUpElectionForm.setFieldValue('tellingWeekendEnd', null)
        }
    }

    const onOnlineVotingEndDateChange = () => {
        if (setUpElectionForm) {
            setUpElectionForm.setFieldValue('tellingWeekendStart', null)
            setUpElectionForm.setFieldValue('tellingWeekendEnd', null)
        }
    }

    const onTellingWeekendStartChange = () => {
        if (setUpElectionForm) {
            setUpElectionForm.setFieldValue('tellingWeekendEnd', null)
        }
    }

    const setUpElectionStepConfig: SetUpElectionStepConfig = {
        onOnlineVotingStartDateChange,
        onOnlineVotingEndDateChange,
        electionDayPropName: 'onlineVotingEnd',
        saveSetUpForm: saveRbcSetUpForm,
        formInitialValue: setUpElectionFormInitial,
        electionDateLabel: electionDateText,
        electionDateValidationLabel: electionDateText,
        extraContent: <Row gutter={24}>
            <Col span={12}>
                <Form.Item
                    name="tellingWeekendStart"
                    label={t('telling_weekend_starts')}
                    rules={[{
                        required: true,
                        message: t('please_select_the_input', { input: t('telling_weekend_starts') })
                    },
                    {
                        message: t('please_select_either_the_current_date_or_the_future_date'),
                        validator: (_, value) => {
                            if (value) {
                                const dayjsValue = toDayjs(value)

                                return tellingWeekendStartDisabledDate(dayjsValue, {
                                    type: 'date'
                                })
                                    ? Promise.reject()
                                    : Promise.resolve()
                            }

                            return Promise.resolve()
                        }
                    }]}
                    validateTrigger={['onBlur', 'onChange']}
                    validateDebounce={200}
                >
                    <DatePicker
                        onChange={onTellingWeekendStartChange}
                        placeholder={t('select_input', { input: t('date') })}
                        inputReadOnly={true}
                        disabledDate={tellingWeekendStartDisabledDate}
                        format="MM/DD/YYYY"
                        style={{ width: '100%' }} />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name="tellingWeekendEnd"
                    label={t('telling_weekend_ends')}
                    rules={[{
                        required: true,
                        message: t('please_select_the_input', { input: t('telling_weekend_ends') })
                    },
                    {
                        message: t('please_select_either_the_current_date_or_the_future_date'),
                        validator: (_, value) => {
                            if (value) {
                                const dayjsValue = toDayjs(value)

                                return tellingWeekendEndDisabledDate(dayjsValue, {
                                    type: 'date'
                                })
                                    ? Promise.reject()
                                    : Promise.resolve()
                            }

                            return Promise.resolve()
                        }
                    }]}
                    validateTrigger={['onBlur', 'onChange']}
                    validateDebounce={200}
                >
                    <DatePicker
                        placeholder={t('select_input', { input: t('date') })}
                        inputReadOnly={true}
                        disabledDate={tellingWeekendEndDisabledDate}
                        format="MM/DD/YYYY"
                        style={{ width: '100%' }} />
                </Form.Item>
            </Col>
        </Row>
    }

    const setUpTellersStepConfig: SetUpTellersStepConfig = {
        isStepReady: async () => {
            const tellersValid = await isFormValid({ form: setUpTellersForm })
            if (!tellersValid) {
                return false
            }

            return true
        },
        completeElectionSetup,
        content: <>{electionPeriod && <RbcSetUpTellers
            rbcElections={electionPeriod.elections}
            form={setUpTellersForm} />}</>
    }

    const saveElectionAsDraftCb = async (currentStepIndex: number) => {
        switch (currentStepIndex) {
            case 0:
                if (setUpElectionForm) {
                    return await saveSetUpElectionForm({
                        form: setUpElectionForm,
                        saveFunc: saveRbcSetUpForm
                    })
                }
                break
        }

        return Promise.resolve(true)
    }

    return (
        <>
            {electionPeriod?.elections?.length && schedule && <ElectionEditingProcess
                electionStatus={electionPeriod.elections[0].status}
                announced={electionPeriod.elections[0].status !== ElectionStatusType.DRAFT}
                ballotingYear={electionPeriod.ballotingYear}
                electionType={ElectionType.RBC_ELECTION}
                getVotingStartDate={getVotingStartDate}
                periodId={electionPeriod.id}
                initSetUpElectionForm={setSetUpElectionForm}
                initManageTellersForm={() => { }}
                saveElectionAsDraftCb={saveElectionAsDraftCb}
                setUpElectionStepConfig={setUpElectionStepConfig}
                verifyParticipantsContent={<RbcVerifyParticipants
                    ballotingYear={electionPeriod.ballotingYear} />}
                setUpTellersStepConfig={setUpTellersStepConfig}
            />
            }
        </>
    )
}

export default RbcElectionEditingProcess