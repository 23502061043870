import { ElectionParticipant, SortOrder } from 'models'
import { EligibleIndividualsParticipantFilter } from '../models'

export const filterParticipants = (
    allParticipants: ElectionParticipant[],
    filter: EligibleIndividualsParticipantFilter
): ElectionParticipant[] => {
    let participants = [...allParticipants]

    let sortingOrder = SortOrder.ASC
    let sortingField = 'name'

    if (filter.sortBy) {
        sortingOrder = filter.sortBy.order
        sortingField = filter.sortBy.field
    }

    participants = participants.sort((a: any, b: any) => {
        const compareStrings
            = (str1: string, str2: string) => str1.localeCompare(str2, undefined, { sensitivity: 'base' })

        const compareValues = (val1: any, val2: any) => {
            if (typeof val1 === 'string' && typeof val2 === 'string') {
                return compareStrings(val1, val2)
            }
            if (val1 < val2)
                return -1
            if (val1 > val2)
                return 1

            return 0
        }

        let result: number

        if (sortingField === 'homeLocalUnit') {
            result = compareValues(a[sortingField]['code'], b[sortingField]['code'])
        } else {
            result = compareValues(a[sortingField], b[sortingField])
        }

        if (result === 0 && sortingField !== 'name') {
            result = compareStrings(a.name, b.name)
        }

        return sortingOrder === SortOrder.ASC ? result : -result
    })

    if (filter.name) {
        const names = filter.name!.trim().toLowerCase().split(' ').filter(n => !!n)
        names.forEach(name => {
            participants = participants.filter(p => p.name.toLowerCase().includes(name))
        })
    }

    if (filter.id) {
        const id = (filter.id! as any).trim()
        participants = participants.filter(p => p.id.toLowerCase().includes(id))
    }

    if (filter.gender) {
        const gender = (filter.gender! as any).trim()
        participants = participants.filter(p => p.gender === gender)
    }

    if (filter.ageCategory) {
        const ageCategory = (filter.ageCategory! as any).trim()
        participants = participants.filter(p => p.ageCategory === ageCategory)
    }

    if (filter.homeLocality?.length) {
        const states = filter.homeLocality!.map(s => s.toLowerCase())
        participants = participants.filter(p => states.includes(p.homeLocality?.code?.toLowerCase()))
    }

    if (filter.homeLocalUnit?.length) {
        const units = filter.homeLocalUnit!.map(s => s.toLowerCase())
        participants = participants.filter(p => units.includes(p.homeLocalUnit?.code?.toLowerCase()))
    }

    if (filter.city?.length) {
        const localities = filter.city!.map(l => l.toLowerCase())
        participants = participants.filter(p => localities.includes(p.city?.toLowerCase()))
    }

    return participants
}