import { ApiModule } from 'api/api-module'
import { BASE_URL, obsApi } from 'api/obs-api'
import axios, { AxiosError } from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { useAccessToken, useRefreshTokenInfo } from 'hooks'
import { Role } from 'models'
import { useEffect } from 'react'
import { getCurrentDateForLogging, isSandbox } from 'utils'

export const useAxios = () => {
    const { refreshTokenInfo, setRefreshTokenInfo } = useRefreshTokenInfo()
    const { setAccessToken } = useAccessToken()
    const fetchNewToken = async () => {

        const apiModule = ApiModule.AuthenticationService

        const obsPublicApi = axios.create({
            baseURL: BASE_URL,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'User-Local-Time-and-Time-Zone': getCurrentDateForLogging(),
                'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
            withCredentials: true
        })
        const userInStorage = localStorage.getItem('user')
        const userFromStorage = userInStorage ? JSON.parse(userInStorage) : null
        const isStationUser = userFromStorage?.role === Role.TELLING_STATION
        const url = isStationUser
            ? `${apiModule}station-user/refresh-access-token`
            : `${apiModule}user/token/refresh-access-token`

        try {
            const token: string = await obsPublicApi
                .post(url, {})
                .then(res => res.data)

            return token
        } catch (error) {
            return null
        }
    }

    const refreshAuth = async (failedRequest: AxiosError) => {
        const newToken = await fetchNewToken()

        if (newToken) {
            if (failedRequest.response) {
                failedRequest.response.config.headers.Authorization = `Bearer ${newToken}`
            }
            setAccessToken(newToken)

            return Promise.resolve(newToken)
        } else {
            setRefreshTokenInfo(current => ({
                ...current,
                logoutIsRequired: true
            })
            )

            return Promise.reject()
        }
    }

    useEffect(() => {
        const storageKey = 'RefreshTokenInerceptorId'
        if (refreshTokenInfo.interceptorId == null) {
            if (sessionStorage.getItem(storageKey) == null) {
                const interceptorId = createAuthRefreshInterceptor(obsApi, refreshAuth, {
                    statusCodes: [401, 500],
                    shouldRefresh: (error) => {
                        if (error.response?.status === 500 && isSandbox) {
                            setRefreshTokenInfo(current => ({
                                ...current,
                                logoutIsRequired: true
                            }))
                        }
                        if (error.request?.responseUrl) {
                            return !(error.request.responseUrl as any).includes('/authenticate')
                        }

                        return true
                    }
                })
                sessionStorage.setItem(storageKey, interceptorId.toString())
                setRefreshTokenInfo(current => ({
                    ...current,
                    interceptorId: current.interceptorId !== undefined
                        ? current.interceptorId
                        : interceptorId
                })
                )
            }
        } else {
            sessionStorage.removeItem(storageKey)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    obsApi.defaults.headers.common['User-Local-Time-and-Time-Zone'] = getCurrentDateForLogging()

    return obsApi
}