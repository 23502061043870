import { useElection, useElectionSummary, useElectionTellingStations } from 'api'
import { Can } from 'components/atoms/Can'
import Card from 'components/atoms/Card'
import BackToDashboardLine from 'components/molecules/BackToDashboardLine'
import CardHeader from 'components/molecules/CardHeader'
import Preloader from 'components/molecules/Preloader'
import { useAuth, useBackToDashboardLine, useHandleEntityLoadingError, useReportLoader } from 'hooks'
import { ReactComponent as InfoRounded } from 'icons/info-rounded.svg'
import { ReactComponent as Pdf } from 'icons/pdf.svg'
import { ReactComponent as Xls } from 'icons/xls.svg'
import { ElectionStation, ElectionType, ReportFormat, ReportType } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import style from './index.module.scss'

function Reports() {
    const { t } = useTranslation('teller')
    const { auth } = useAuth()
    const navigate = useNavigate()
    const { handleEntityLoadingError } = useHandleEntityLoadingError()
    const { setBackToDashboardLine } = useBackToDashboardLine()
    const { electionId } = useParams<{ electionId: string }>()
    const { data: tellingStations } = useElectionTellingStations(electionId || auth!.electionId!,
        !!electionId || !!auth?.electionId)
    const { data: election, error } = useElection(electionId || auth!.electionId!, !!electionId || !!auth?.electionId)
    const { data: electionSummary, isError } =
        useElectionSummary(electionId || auth!.electionId!,
            (!!electionId || !!auth?.electionId) && !!election)

    const [sortedStations, setSortedStations] = useState<ElectionStation[]>()

    const { loadReport, isReportLoading } = useReportLoader()

    const isRbcElection = election?.type === ElectionType.RBC_ELECTION
    const voterParticipationReportElectionTypes = [
        ElectionType.NATIONAL_DELEGATE_ELECTION,
        ElectionType.RIDVAN_ELECTION,
        ElectionType.LOCAL_DELEGATE_ELECTION
    ]

    useEffect(() => {
        if (error) {
            handleEntityLoadingError(error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    const loadElectedCandidatesReport = () => {
        loadReport({
            reportType: ReportType.ElectedCandidateTellerReport,
            reportFormat: ReportFormat.PDF,
            params: {
                election: electionId || auth!.electionId!
            }
        })
    }

    const loadConfirmedCandidatesReport = () => {
        loadReport({
            reportType: ReportType.ConfirmedCandidateTellerReport,
            reportFormat: ReportFormat.PDF,
            params: {
                election: electionId || auth!.electionId!
            }
        })
    }
    const loadPdfReport = (reportType: ReportType) => {
        loadReport({
            reportType,
            reportFormat: ReportFormat.PDF,
            params: {
                election: electionId || auth!.electionId!
            }
        })
    }

    const loadVoterParticipationReport = () => {
        loadReport({
            reportType: ReportType.VoterByAgeAndVotingTypeReport,
            reportFormat: ReportFormat.XLSX,
            params: {
                ballotingYear: election?.ballotingYear,
                electionType: election?.type,
                election: election?.id.toString()
            }
        })
    }

    const handleViewClick = (stationId: string, electionId: string) => {
        let url = ''
        if (stationId) {
            url = `/station-report/${stationId}/${electionId}`
        }
        navigate(url)
    }

    const handleSignClick = (electionId: string) => {
        let url = ''
        if (electionId) {
            url = `/report/${electionId}`
        }
        navigate(url)
    }

    useEffect(() => {
        if (isError) {
            navigate('/page-not-found', { replace: true })

            return
        }
        if (!!electionSummary && !electionSummary.mergedBy) {
            navigate('/page-not-found', { replace: true })

            return
        }
        if (!!electionSummary?.tiePresent
            && !electionSummary.breakingTieSkipped
            && election?.type !== ElectionType.RBC_ELECTION) {
            navigate('/page-not-found', { replace: true })
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [electionSummary, isError])

    useEffect(() => {
        setSortedStations(tellingStations?.sort(
            (a, b) => {
                const stationNumberA = parseInt(a.id.substring(1))
                const stationNumberB = parseInt(b.id.substring(1))

                return stationNumberA - stationNumberB
            }))
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [tellingStations])

    useEffect(() => {
        setBackToDashboardLine(
            <BackToDashboardLine sticky fixedWidth onBackToDashboardBtnClick={() => {
                auth?.electionId ? navigate('/') : navigate(`/election-board/${electionId}`, { replace: true })
            }} />
        )

        return () => { setBackToDashboardLine(null) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //TODO: Refactor this component. Create a config array and render the reports sections based on that array.
    // Array content should be dependent on election type and status and summary. Might be moved to an util or hook
    // Currently mix of business logic and markup makes it difficult to read and update

    return (
        <div className={style.cardsWrapper}>
            {isReportLoading && <Preloader title={t('working_on_your_report')} />}
            <div >
                <Card noContentPadding={true} title={<CardHeader title={t('station_reports')} />} >
                    {!election?.bypassFlow ?
                        sortedStations?.map(station => (
                            <div className={`${style.reportsWrapper} ${style.topBorder}`} key={station.id}>
                                <div className={style.stationNumber}>
                                    {`${t('station')} ${station.id.replace('S', '')} `}
                                    <span className={style.stationText}>
                                        {`${String.fromCharCode(8212)} ${t('telling-station:tellers_statistics')}`}
                                    </span>
                                </div>
                                <div className={style.statusButtonWrapper}>
                                    <div className={style.statusesWrapper}>
                                        <span className={style.statuses}>{t('signed')}</span>
                                    </div>
                                    <div>
                                        <button className={`btn-main-secondary ${style.buttonWrapper}`}
                                            onClick={() => handleViewClick(station.id, electionId!)}>
                                            {t('view')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))
                        :
                        <div className={style.noReportsWrapper}>
                            {t('no_station_reports')}
                        </div>
                    }
                </Card>
            </div>
            <div >
                <Card noContentPadding title={<CardHeader title={t('teller_s_reports')} />}>
                    {isRbcElection && electionSummary?.confirmedBy &&
                        <Can I="view" a="RbcElectionResults">
                            <div className={style.reportsWrapper}>
                                <div className={style.stationText}>
                                    {t('election_results')}
                                </div>
                                <div className={style.statusButtonWrapper}>
                                    <div>
                                        <button
                                            disabled={isReportLoading}
                                            onClick={() => loadPdfReport(ReportType.RbcElectionConfirmedResultsReport)}
                                            className={`btn-main-secondary ${style.buttonWrapper}`}
                                        >
                                            <span className={style.reportIcon}><Pdf /></span>
                                            <span>{t('download_pdf')}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Can>}
                    <div className={style.reportsWrapper}>
                        <div className={style.stationText}>
                            {isRbcElection
                                ? t('teller_s_report')
                                : t('tellers_report_unconfirmed_results')}
                        </div>
                        <div className={style.statusButtonWrapper}>
                            {!electionSummary?.signedBy ? (
                                <>
                                    <div className={style.info}>
                                        <div className={style.icon}><InfoRounded /></div>
                                        <div>{t('signature_required')}</div>
                                    </div>
                                    <div>
                                        <button
                                            className={`btn-main-primary ${style.buttonWrapper}`}
                                            onClick={() => handleSignClick(electionId || auth!.electionId!)}
                                        >
                                            {t('sign')}
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className={style.statusesWrapper}>
                                        <span className={style.statuses}>{t('signed')}</span>
                                    </div>
                                    <div>
                                        <button
                                            onClick={() => loadElectedCandidatesReport()}
                                            disabled={isReportLoading}
                                            className={`btn-main-secondary ${style.buttonWrapper}`}
                                        >
                                            <span className={style.reportIcon}><Pdf /></span>
                                            <span>{t('download_pdf')}</span>
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    {electionSummary?.confirmedBy && !isRbcElection && (
                        <div className={style.reportsWrapper}>
                            <div className={style.stationText}>
                                {t('tellers_report_confirmed_results')}
                            </div>
                            <div className={style.statusButtonWrapper}>
                                <div>
                                    <button
                                        disabled={isReportLoading}
                                        onClick={() => loadConfirmedCandidatesReport()}
                                        className={`btn-main-secondary ${style.buttonWrapper}`}
                                    >
                                        <span className={style.reportIcon}><Pdf /></span>
                                        <span>{t('download_pdf')}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    <Can I="view" a="DownloadReportButton">
                        {electionSummary?.confirmedBy && election?.type
                            && voterParticipationReportElectionTypes.includes(election.type) && (
                                <div className={style.reportsWrapper}>
                                    <div className={style.stationText}>
                                        {t('election:voter_participation')}
                                    </div>
                                    <div className={style.statusButtonWrapper}>
                                        <div>
                                            <button
                                                disabled={isReportLoading}
                                                onClick={() => loadVoterParticipationReport()}
                                                className={`btn-main-secondary ${style.buttonWrapper}`}
                                            >
                                                <span className={style.reportIcon}><Xls /></span>
                                                <span>{t('download_xls')}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>)}
                    </Can>
                </Card>
            </div>
        </div>
    )
}

export default Reports