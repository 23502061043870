import { useOnlineVotingInfos } from 'api'
import ElectionBadge from 'components/molecules/ElectionBadge'
import { ElectionStatusType } from 'models'
import { useEffect, useState } from 'react'

type SubElectionOnlineVotingStatusProps = {
    allElectionsIds: string[],
    electionId: string
}

function SubElectionOnlineVotingStatus({
    allElectionsIds,
    electionId
}: SubElectionOnlineVotingStatusProps) {
    const [status, setStatus] = useState<ElectionStatusType>(ElectionStatusType.NOT_STARTED)
    const { data: onlineVotingInfos } = useOnlineVotingInfos({
        electionIds: allElectionsIds
    })

    useEffect(() => {
        if (onlineVotingInfos) {
            const electionInfo = onlineVotingInfos.find(i => i.election.toString() === electionId)
            if (electionInfo) {
                if (electionInfo.closed) {
                    if (electionInfo.votingReportAccepted) {
                        setStatus(ElectionStatusType.COMPLETED)
                    } else {
                        setStatus(ElectionStatusType.NOT_STARTED)
                    }
                } else {
                    setStatus(ElectionStatusType.IN_PROGRESS)
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onlineVotingInfos])

    return (
        <ElectionBadge
            status={status}
        />
    )
}

export default SubElectionOnlineVotingStatus