import Empty from 'components/molecules/Empty'
import InternalScrollTableDefaultHeader from 'components/molecules/InternalScrollTableDefaultHeader'
import { ElectionResult, InternalScrollTableDefaultColumn } from 'models'
import { useEffect, useMemo, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import { useTranslation } from 'react-i18next'
import { FixedSizeList as List } from 'react-window'
import { useMergeResultsData } from '../../hooks'
import TieItem from '../TieItem'
import TieRevoteUsingHeader from '../TieRevoteUsingHeader'
import style from './index.module.scss'

const LIST_ITEM_HEIGHT = 40

type TieTableProps = {
    onCandidateSelect: (candidateId: string, isChecked: boolean) => void
    onSendInvitationChange: (sendInvitation: boolean) => void
    selectedCandidates: string[]
    tiePositions: number
    isDesignateElectee?: boolean
    tieDesignatedCandidates?: ElectionResult[]
    electedCandidates?: ElectionResult[]
}

function TieTable({
    onCandidateSelect,
    onSendInvitationChange,
    tieDesignatedCandidates,
    selectedCandidates,
    tiePositions,
    electedCandidates,
    isDesignateElectee }: TieTableProps) {
    const { t } = useTranslation('teller')
    const { mergedResultsData } = useMergeResultsData()
    const [designatedCandidates, setDesignatedCandidates] = useState<ElectionResult[]>([])
    const electionResultTie = mergedResultsData.electionResultTie

    useEffect(() => {
        const electedCandidateIds = new Set(electedCandidates?.map(cand => cand.id))
        const remainingCandidates = electionResultTie?.candidates
            .filter(cand => !electedCandidateIds.has(cand.id))
            .sort((a, b) => {
                const nameCompare = a.name?.localeCompare(b.name)
                if (nameCompare !== 0) {
                    return nameCompare
                }

                return a.id.localeCompare(b.id)
            })

        const combinedCandidates = [...(remainingCandidates ?? []), ...(tieDesignatedCandidates ?? [])]
            .sort((a, b) => {
                const nameCompare = a.name?.localeCompare(b.name)
                if (nameCompare !== 0) {
                    return nameCompare
                }

                return a.id.localeCompare(b.id)
            })

        setDesignatedCandidates(isDesignateElectee ? combinedCandidates : remainingCandidates ?? [])
    }, [tieDesignatedCandidates, electedCandidates, electionResultTie?.candidates, isDesignateElectee])

    const columns: InternalScrollTableDefaultColumn[] = useMemo(() => {
        const cols: InternalScrollTableDefaultColumn[] = [
            { title: t('full_name'), width: isDesignateElectee ? 220 : 230 },
            { title: t('bahai_id'), width: isDesignateElectee ? 95 : 100 },
            { title: t('gender'), width: isDesignateElectee ? 95 : 100 },
            { title: t('race'), width: isDesignateElectee ? 155 : 160 },
            { title: t('ethnicity'), width: isDesignateElectee ? 155 : 160 },
            { title: t('votes'), width: 70 }
        ]

        if (isDesignateElectee) {
            cols.unshift({ title: t(' '), width: 47 })
        }

        return cols.filter((col): col is InternalScrollTableDefaultColumn => Boolean(col))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDesignateElectee])

    return (
        <div className={style.listContainer}>
            {!isDesignateElectee && <TieRevoteUsingHeader
                tiePositions={tiePositions} onSendInvitationChange={onSendInvitationChange} />}
            <InternalScrollTableDefaultHeader columns={columns} />
            {designatedCandidates.length
                ? <List
                    style={{
                        ...style,
                        maxHeight: 400
                    }}
                    outerElementType={Scrollbars}
                    className={style.list}
                    height={(designatedCandidates.length ?? 0) * LIST_ITEM_HEIGHT}
                    itemCount={designatedCandidates.length}
                    itemSize={LIST_ITEM_HEIGHT}
                    width={'auto'}
                >
                    {({ index, style: itemStyle }) => (
                        <TieItem
                            isDesignateElectee={isDesignateElectee}
                            style={itemStyle}
                            result={designatedCandidates[index]}
                            onCandidateSelect={onCandidateSelect}
                            selected={selectedCandidates.includes(designatedCandidates[index].id)}
                        />
                    )}
                </List>
                : <div className={style.empty}>
                    <Empty />
                </div>
            }
        </div>
    )
}

export default TieTable