import { Checkbox } from 'components/atoms/Checkbox'
import OptionalTooltip from 'components/molecules/OptionalTooltip'
import { CSSProperties, memo, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { areEqual } from 'react-window'
import { applyPhoneMask, capitalizeFirstLetter } from 'utils'
import { ResultsTableItemData } from '../../model'
import style from './index.module.scss'

type ResultTableItemProps = {
    style?: CSSProperties,
    index: number,
    data: ResultsTableItemData[]
}
const ResultTableItem = memo(function ResultTableItem({
    index,
    style: itemStyle,
    data
}: ResultTableItemProps) {
    const { t } = useTranslation()
    const tableData = data[index] || {}
    const [isConfirmed, setIsConfirmed] = useState(false)

    const {
        result,
        resultsConfirmed,
        onConfirmCheckboxChange,
        withLatestRound
    } = tableData

    const gender = useMemo(() => tableData?.result?.gender
        ? capitalizeFirstLetter(tableData.result.gender)
        : String.fromCharCode(8212),
        [tableData?.result?.gender])

    useEffect(() => {
        if (tableData?.result?.confirmed) {
            setIsConfirmed(true)
        }
    }, [tableData])

    const itemClassName = useMemo(() => {
        const {
            inTieCandidate,
            isTieElectee,
            isTieButNotElected,
            isConfirmedTieCandidate
        } = tableData

        return `${style.listItem} 
            ${isTieElectee ? style.tieElectee : ''} 
            ${inTieCandidate ? style.inTieCandidate : ''} 
            ${isTieButNotElected && isConfirmedTieCandidate ? style.tieElectee : ''}`
    }, [
        tableData
    ])

    if (!tableData || !tableData.result) {
        return <div className={style.placeholder}>
            {String.fromCharCode(8212)}
        </div>
    }

    return (
        <div className={itemClassName} style={itemStyle}>
            <div style={{ width: 60 }} className={style.column}>
                {index + 1}
            </div >
            <div style={{ width: 78 }} className={style.column}>
                {result.id}
            </div >
            <div style={{ width: !!withLatestRound ? 220 : 300 }} className={style.column}>
                <OptionalTooltip>
                    {result.name || `[${t('deactivated_member')}]`}
                </OptionalTooltip>
            </div >
            <div style={{ width: 72 }} className={style.column}>
                {gender}
            </div >
            <div style={{ width: 90 }} className={style.column}>
                {result.locality?.code || String.fromCharCode(8212)}
            </div >
            <div
                style={{ width: 136 }}
                className={`${style.column} ${style.pointer}`}>
                {result.phone ? applyPhoneMask(result.phone || '') : String.fromCharCode(8212)}
            </div >
            <div style={{ width: 214 }} className={style.column}>
                <div className="ellipsis">{result.race}</div>
            </div >
            <div style={{ width: 215 }} className={style.column}>
                <div className="ellipsis">{result.ethnicity}</div>
            </div >
            <div style={{ width: 80 }} className={style.column}>
                {result.voteCount}
            </div >
            {withLatestRound
                && <div style={{ width: 80 }} className={style.column}>
                    {result.tieLatestRoundVoteCount ? '+' + result.tieLatestRoundVoteCount : ''}
                </div >
            }
            <div style={{ width: 86 }} className={`${style.column} ${style.centered}`}>
                <Checkbox
                    checked={isConfirmed}
                    disabled={resultsConfirmed}
                    onChange={() => {
                        setIsConfirmed(current => !current)
                        onConfirmCheckboxChange(result.id)
                    }
                    }
                />
            </div >
        </div >
    )
}, areEqual)

export default ResultTableItem