
import { useTieDesignatedCandidates } from 'api'
import { useStopOnlineVoting } from 'api/mutations'
import Note from 'components/molecules/Note'
import { useAbility, useAuth, useConfirmPopup, usePopup, useRevoteRoundTieCandidates } from 'hooks'
import { Election, ElectionResultSummary, ElectionStatusType, ElectionType } from 'models'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { formatNames, isElectionFrozen } from 'utils'
import { useSkipBreakingTie } from '../../api/mutations'
import {
    useBreakTheTieFlowPopup,
    useCancelLastTieRoundResultPopup,
    useDesignateElecteePopup,
    useMergeResultsData,
    useRevotePopup,
    useSelectTypeOfElectionFlow
} from '../../hooks'
import ResolvedTieText from '../ResolvedTieText'
import TieInfoBlockAction from '../TieInfoBlockAction'
import TieRoundBlock from '../TieRoundBlock'
import UnresolvedTieText from '../UnresolvedTieText'
import style from './index.module.scss'

type TieInfoBlockProps = {
    election?: Election
    summary?: ElectionResultSummary
}

function TieInfoBlock({
    summary,
    election
}: TieInfoBlockProps) {
    const { t } = useTranslation('teller')
    const { auth } = useAuth()
    const { ability } = useAbility()
    const { electionId } = useParams()
    const navigate = useNavigate()
    const { popupPortal, show, hide } = usePopup()
    const { confirmPortal, showConfirm, hide: closePopup } = useConfirmPopup()

    const { cancelLastTieRoundResultPopup } = useCancelLastTieRoundResultPopup(showConfirm, closePopup)
    const { showBreakTheTieFlowPopup } = useBreakTheTieFlowPopup(show, hide)
    const { showDesignateElecteePopup } = useDesignateElecteePopup(show, hide)
    const { showRevotePopup } = useRevotePopup(show, hide)
    const { showSelectElectionTypeFlowPopup } = useSelectTypeOfElectionFlow(show, hide)
    const { mergedResultsData } = useMergeResultsData()

    const { mutateAsync: stopOnlineVotingMutation } = useStopOnlineVoting()
    const { mutateAsync: skipBreakingTie } = useSkipBreakingTie()
    const { data: tieDesignatedCandidates } =
        useTieDesignatedCandidates(election?.id.toString() || '', isElectionFrozen(summary),
            !!election)

    const { formattedNames: revoteRoundTieCandidates } =
        useRevoteRoundTieCandidates(mergedResultsData.electionResultTie?.resolution.candidates || [],
            mergedResultsData.electionResultTie?.candidates || [], tieDesignatedCandidates || [])

    const isTieSkipped = useMemo(() => summary?.breakingTieSkipped || false, [summary])
    const isTieResolved = useMemo(() => mergedResultsData.isTieResolved, [mergedResultsData])
    const isElectionSigned = useMemo(() => !!summary ? !!summary.signedBy : false, [summary])
    const hasDesignatedCandidates = useMemo(() => (tieDesignatedCandidates?.length ?? 0) > 0, [tieDesignatedCandidates])
    const canChangeTieResults = useMemo(() => isTieResolved && hasDesignatedCandidates && !isElectionSigned,
        [isTieResolved, hasDesignatedCandidates, isElectionSigned])
    const isTieAutoSkipped = useMemo(() => election ? [ElectionType.RBC_ELECTION].includes(election.type) : false,
        [election])
    const canResolveTheTie = useMemo(() => !isTieResolved && !isTieSkipped && !isTieAutoSkipped,
        [isTieResolved, isTieSkipped, isTieAutoSkipped])
    const canSkipTheTie = useMemo(() => election
        ? election.type === ElectionType.NATIONAL_DELEGATE_ELECTION && election.hasSubUnits : false, [election])

    const canProceedToReporting = useMemo(() => {
        const canPerformChiefTellerActions = !ability!.can('view-only', 'ChiefTellerActions')

        return canPerformChiefTellerActions && !isElectionSigned &&
            (isTieAutoSkipped || (isTieSkipped && !!election?.hasSubUnits))
    }, [ability, isElectionSigned, isTieAutoSkipped, isTieSkipped, election])

    const breakTheTie = () => {
        showBreakTheTieFlowPopup(
            () => showDesignateElecteePopup(),
            () => showRevotePopup())
    }
    const designateElectee = () => {
        showDesignateElecteePopup()
    }

    const peopleDesignatedAsElectee = useMemo(() => {
        if (!hasDesignatedCandidates) {
            return null
        } else {
            const designatesTieCandidates =
                formatNames(tieDesignatedCandidates?.map(candidate => candidate.name
                    || `[${t('deactivated_member')}]`) || [], t('and'))

            return (
                <>
                    <span className={style.nameFont}>
                        {`${designatesTieCandidates} `}
                    </span>
                    <span className={style.textFont}>{`${t('designated_as_electee')}.`}</span>
                </>
            )
        }
    }, [hasDesignatedCandidates, tieDesignatedCandidates])

    const endOnlineVotingPopup = () => {
        showConfirm({
            stayOpenedOnCancel: true,
            title: <>{`${t('end_online_voting')}?`}</>,
            text:
                <>
                    <div>
                        {t('you_are_about_to_end_online_voting_to_start_telling_process')}
                        <div style={{ paddingTop: 'var(--default-space' }}>
                            <b>{t('please_note_this_is_an_irreversible_action')}</b>
                        </div>
                    </div>
                </>,
            okText: <>{t('cancel')}</>,
            cancelText: <>{t('end_online_voting')}</>,
            onCancel: async (e) => {
                e.preventDefault()
                const electionId = mergedResultsData.lastRound?.id.toString() || ''
                await stopOnlineVotingMutation(electionId)
                await showSelectElectionTypeFlowPopup(electionId)
                closePopup()
            },
            onOk: async () => { },
            onClose: () => { }
        })
    }

    const skipBreakTheTie = () => {
        showConfirm({
            stayOpenedOnCancel: true,
            title: <>{`${t('skip_break_the_tie')}?`}</>,
            text:
                <>
                    <div>
                        {t('you_are_about_to_skip_break_the_tie_process')}
                        <div style={{ paddingTop: 'var(--default-space' }}>
                            <b>{t('please_note_this_is_an_irreversible_action')}</b>
                        </div>
                    </div>
                </>,
            okText: <>{t('cancel')}</>,
            cancelText: <>{t('skip_break_the_tie')}</>,
            onCancel: async (e) => {
                e.preventDefault()
                await skipBreakingTie(electionId || auth?.electionId?.toString() || '')
                navigate(`/report/${electionId || auth?.electionId?.toString() || ''}`)
                closePopup()
            },
            onOk: async () => { },
            onClose: () => { }
        })
    }

    return (
        <>
            {confirmPortal}
            {popupPortal}
            {mergedResultsData.lastRound?.status !== ElectionStatusType.IN_PROGRESS
                && <Note mode={isTieResolved ? 'success' : 'warning'} icon={'regular'}>
                    <div className={style.infoWrapper}>
                        {isTieResolved
                            ? <ResolvedTieText
                                isElectionSigned={isElectionSigned}
                                hasDesignatedCandidates={hasDesignatedCandidates}
                                resolutionComment={mergedResultsData.electionResultTie?.resolution.comment}
                                peopleElectedViaRevoteRound={revoteRoundTieCandidates}
                                peopleDesignatedAsElectee={peopleDesignatedAsElectee} />

                            : <UnresolvedTieText
                                isTieSkipped={isTieSkipped}
                                isTieAutoSkipped={isTieAutoSkipped}
                                canSkipTheTie={canSkipTheTie}
                                hasDesignatedCandidates={hasDesignatedCandidates}
                                peopleDesignatedAsElectee={peopleDesignatedAsElectee}
                                resolutionComment={mergedResultsData.electionResultTie?.resolution.comment}
                                peopleElectedViaRevoteRound={revoteRoundTieCandidates}
                                canProceedToReporting={canProceedToReporting}
                            />
                        }
                        <div className={style.action}>
                            <TieInfoBlockAction
                                canChangeTieResults={canChangeTieResults}
                                breakTheTie={breakTheTie}
                                designateElectee={designateElectee}
                                canDesignateElecteeOnly={canSkipTheTie}
                                canResolveTheTie={canResolveTheTie}
                                canSkipTheTie={canSkipTheTie}
                                skipBreakingTheTie={skipBreakTheTie}
                            />
                        </div>
                    </div>
                </Note >
            }
            {mergedResultsData.lastRound?.status === ElectionStatusType.IN_PROGRESS
                && <TieRoundBlock
                    showSelectElectionTypeFlowPopup={showSelectElectionTypeFlowPopup}
                    showClearAllVotesPopup={() => cancelLastTieRoundResultPopup(false)}
                    showEndOnlineVotingPopup={endOnlineVotingPopup}
                />
            }
        </>
    )
}

export default TieInfoBlock