import { Collapse, CollapseProps } from 'antd'
import { ReactComponent as Chevron } from 'icons/chevron.svg'
import { useTranslation } from 'react-i18next'
import { useAdminCompletedElections, useAdminOngoingElections } from '../../api'
import ActiveElections from '../ActiveElections'
import CompletedElections from '../CompletedElections'
import RbcElectionsHeader from '../RbcElectionsHeader'
import style from './index.module.scss'

function RbcElectionsList() {
    const { t } = useTranslation('election')
    const { data: activeElections, isSuccess } = useAdminOngoingElections()
    const { data: completedElections } = useAdminCompletedElections()

    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: t('history') + ' (' + t('completed_elections') + ')',
            children: <CompletedElections
                elections={completedElections || []}
            />
        }
    ]

    return (
        <div className={style.wrapper}>
            <div className={style.headerSurface} />
            <RbcElectionsHeader />
            <ActiveElections
                elections={activeElections || []}
                isSuccess={isSuccess} />
            {!!completedElections?.length &&
                <div className="collapse-like-card">
                    <Collapse
                        defaultActiveKey="1"
                        items={items}
                        expandIcon={() => <Chevron />}
                    />
                </div>
            }
        </div>
    )
}

export default RbcElectionsList