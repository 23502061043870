import { Form } from 'antd'
import { FormInstance } from 'antd/lib'
import IconWrapper from 'components/atoms/IconWrapper'
import { Select } from 'components/atoms/Select'
import Tooltip from 'components/atoms/Tooltip'
import OptionalTooltip from 'components/molecules/OptionalTooltip'
import { ReactComponent as Warning } from 'icons/warning.svg'
import { ElectionType } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLsaInstitutions } from '../../api'
import { LsaInstitution } from '../../model'
import style from './index.module.scss'

type LsaInstitutionFilterProps = {
    disabled?: boolean
    bahaiYear?: number
    electionType?: ElectionType
    form: FormInstance
}

function LsaInstitutionFilter({ form, disabled, bahaiYear, electionType }: LsaInstitutionFilterProps) {
    const { t } = useTranslation('administration')
    const [sortedInstitutions, setSortedInstitutions] = useState<LsaInstitution[]>()
    const { data: lsaInstitutions } = useLsaInstitutions(bahaiYear!, electionType!, !!bahaiYear && !!electionType)

    useEffect(() => {
        if (lsaInstitutions) {
            const institutions = [...lsaInstitutions]
            institutions.sort((a, b) => a.locName?.toLowerCase().localeCompare(b.locName?.toLowerCase()) || 1)
            setSortedInstitutions(institutions)
        }
    }, [lsaInstitutions])

    useEffect(() => {
        form.setFieldValue('locCode', undefined)
    }, [form, electionType])

    return (
        <Form.Item
            name={['locCode']}
            className="no-padding"
            label={t('election:institution')}
            style={{ width: 300 }}
            required
        >
            <Select
                disabled={disabled}
                showSearch
                options={sortedInstitutions?.map(i => ({
                    label: `${i.locCode}, ${i.locName}`,
                    value: i.locCode,
                    title: ''
                })) || []}
                optionFilterProp="label"
                placeholder={t('select_input', { input: t('election:institution') })}
                notFoundContent={t('no_results_found')}
                optionRender={(option, { index }) => <div className={style.option}>
                    <OptionalTooltip>
                        {option.label}
                    </OptionalTooltip>
                    {!!sortedInstitutions?.[index] && sortedInstitutions[index].electionCompleted
                        ? <></>
                        : <Tooltip title={t('empty_report')}>
                            <span className={style.icon}>
                                <IconWrapper><Warning className={style.warning} /></IconWrapper>
                            </span>
                        </Tooltip>}
                </div>}
                className="small" />
        </Form.Item>
    )
}

export default LsaInstitutionFilter