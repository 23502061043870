import { MenuProps } from 'antd'
import { useElectionsSummary } from 'api'
import IconWrapper from 'components/atoms/IconWrapper'
import Dropdown from 'components/molecules/Dropdown'
import Preloader from 'components/molecules/Preloader'
import { useReportLoader } from 'hooks'
import { ReactComponent as ArrowDownload } from 'icons/arrow-download.svg'
import { ElectionResultSummary, ElectionType, ReportFormat, ReportType } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from './index.module.scss'

type SubElectionReportsProps = {
    allElectionsIds: string[]
    electionId: string
    electionType: ElectionType
}

function SubElectionReports({
    allElectionsIds,
    electionId,
    electionType
}: SubElectionReportsProps) {
    const { t } = useTranslation('election')
    const { loadReport, isReportLoading } = useReportLoader()

    const { data: electionsSummary } = useElectionsSummary({
        electionIds: allElectionsIds
    })

    const [electionSummary, setElectionSummary] = useState<ElectionResultSummary>({
        confirmedBy: '', mergedBy: '', signedBy: '', tiePresent: false,
        comment: '', commentedAt: '', commentedBy: '', breakingTieSkipped: false,
        breakingTieResolved: false, tieResolutionComment: ''
    })

    const downloadPdfReport = (reportType: ReportType) => {
        loadReport({
            reportType: reportType,
            reportFormat: ReportFormat.PDF,
            params: {
                election: electionId
            }
        })
    }


    const getElectionTypeReports = () => {
        switch (electionType) {
            case ElectionType.RBC_ELECTION:
                return [
                    {
                        key: '1',
                        label: t('teller:teller_s_report'),
                        onClick: () => downloadPdfReport(ReportType.ElectedCandidateTellerReport)
                    },
                    {
                        key: '2',
                        label: t('election_results'),
                        onClick: () => downloadPdfReport(ReportType.RbcElectionConfirmedResultsReport)
                    }
                ]
        }

        return [
            {
                key: '1',
                label: t('election_results'),
                onClick: () => downloadPdfReport(ReportType.ElectedCandidateTellerReport)
            },
            {
                key: '2',
                label: t('confirmed_results'),
                onClick: () => downloadPdfReport(ReportType.ConfirmedCandidateTellerReport)
            }
        ]
    }


    const items: MenuProps['items'] = getElectionTypeReports()

    useEffect(() => {
        if (electionsSummary) {

            const summary = electionsSummary.find(i => i.election.toString() === electionId)

            if (summary) {
                setElectionSummary(summary.electionResults)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [electionsSummary])

    return (
        <>
            {isReportLoading && <Preloader title={t('working_on_your_report')} />}
            {
                !electionSummary.confirmedBy ?
                    <></>
                    :
                    <Dropdown menu={{ items }} openClassName={style.reports}
                        trigger={['click']}
                    >
                        <button className={`btn-main-tertiary-md ${style.download}`}>
                            <IconWrapper>
                                <ArrowDownload />
                            </IconWrapper>
                        </button>
                    </Dropdown>

            }

        </>

    )
}

export default SubElectionReports