import { Select as AntSelect, Form, FormInstance } from 'antd'
import { Select } from 'components/atoms/Select'
import { queryClient } from 'config/query-client'
import dayjs from 'dayjs'
import { useHandleEntityLoadingError } from 'hooks'
import { ElectionStatusType, ElectionType } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { isFormValid, toDayjs } from 'utils'
import ElectionEditingProcess from '../../../../components/ElectionEditingProcess'
import ManageElectionTellersForm from '../../../../components/ManageElectionTellersForm'
import {
    useExtractSetUpElectionSchedule,
    useManageTellersActions,
    useSetUpElectionActions
} from '../../../../hooks'
import { SetUpElectionStepConfig, SetUpTellersStepConfig } from '../../../../models'
import { useAnnounceNationalDelegateElectionPeriod, useElectionPeriod, useSetUpSchedule } from '../../api'
import VerifyNationalDelegateElectionParticipants from '../VerifyNationalDelegateElectionParticipants'

function NationalDelegateElectionEditingProcess() {
    const { t } = useTranslation('election')

    const [setUpElectionForm, setSetUpElectionForm] = useState<FormInstance<any>>()
    const [manageTellersForm, setManageTellersForm] = useState<FormInstance<any>>()
    const { electionPeriodId } = useParams()

    const { handleEntityLoadingError } = useHandleEntityLoadingError()
    const { saveSetUpElectionForm } = useSetUpElectionActions()
    const { extractSchedule } = useExtractSetUpElectionSchedule()
    const { manageTellers } = useManageTellersActions()

    const { data: electionPeriod, error } = useElectionPeriod(electionPeriodId!)
    const { mutateAsync: setUpSchedule } = useSetUpSchedule()
    const { mutateAsync: announce } = useAnnounceNationalDelegateElectionPeriod()

    const electionDateText = t('convention_date')

    useEffect(() => {
        if (error) {
            handleEntityLoadingError(error)
        }
    }, [error])

    const saveSetUpForm = async () => {
        if (setUpElectionForm) {
            const electionSchedule = extractSchedule(setUpElectionForm)

            await setUpSchedule({
                schedule: electionSchedule,
                electionPeriod: electionPeriod?.id.toString() || ''
            })

            await queryClient.invalidateQueries(['national-delegate-election-period', electionPeriodId])
        }
    }

    const setUpElectionFormInitial = {
        ballotingYear: t('year_b_e_period',
            {
                year: electionPeriod?.ballotingYear,
                period: `${dayjs().year()}-${dayjs().year() + 1}`
            }),
        numberOfVacancies: electionPeriod?.election?.numberOfVacancies || 0,
        timeZone: electionPeriod?.election.timeZone || (null as any),
        electionDay: electionPeriod?.election.electionDay ?
            toDayjs(electionPeriod.election.electionDay)
            : undefined,
        onlineVotingStart: electionPeriod?.election.onlineVotingStart ?
            toDayjs(electionPeriod.election.onlineVotingStart)
            : undefined
    }

    const getVotingStartDate = () => {
        if (setUpElectionForm) {
            const formValue = setUpElectionForm.getFieldValue('onlineVotingStart')
            if (formValue)
                return formValue
            if (electionPeriod && electionPeriod!.election.onlineVotingStart) {
                return dayjs(new Date(electionPeriod!.election.onlineVotingStart))
            }
        }

        return null
    }

    const saveElectionAsDraftCb = async (currentStepIndex: number) => {
        if (setUpElectionForm) {
            switch (currentStepIndex) {
                case 0:
                    return await saveSetUpElectionForm({
                        form: setUpElectionForm,
                        saveFunc: saveSetUpForm
                    })
                case 2:
                    if (manageTellersForm) {
                        await manageTellers({
                            form: manageTellersForm,
                            electionId: electionPeriod?.election.id.toString() || ''
                        })
                    }

                    return Promise.resolve(true)
            }
        }

        return Promise.resolve(true)
    }

    const isSetupTellersStepReady = async () => {
        if (!manageTellersForm) {
            return false
        }
        const tellersValid = await isFormValid({ form: manageTellersForm })
        if (!tellersValid) {
            return false
        }

        return true
    }

    const completeElectionSetup = async () => {
        if (manageTellersForm) {
            await manageTellers({
                form: manageTellersForm,
                electionId: electionPeriod?.election.id.toString() || ''
            })
            await announce(electionPeriod?.id.toString() || '')
        }
    }

    const setUpElectionStepConfig: SetUpElectionStepConfig = {
        saveSetUpForm,
        formInitialValue: setUpElectionFormInitial,
        electionDateLabel: `${electionDateText} (${t('day_votes_counted')})`,
        electionDateValidationLabel: electionDateText,
        extraContent: <Form.Item
            name="numberOfVacancies"
            label={<>
                {t('number_of_delegates')}
            </>}>
            <Select
                disabled={true}
                placeholder={t('number_of_delegates')}>
                <AntSelect.Option value={0}>
                    {0}
                </AntSelect.Option>
            </Select>
        </Form.Item>
    }

    const setUpTellersStepConfig: SetUpTellersStepConfig = {
        isStepReady: isSetupTellersStepReady,
        completeElectionSetup,
        content: !!electionPeriod && !!manageTellersForm && <ManageElectionTellersForm
            form={manageTellersForm} canSaveWithoutMandatoryTellers
            election={electionPeriod?.election} />
    }

    return (
        <>
            {electionPeriod &&
                <ElectionEditingProcess
                    electionStatus={electionPeriod.election.status}
                    announced={electionPeriod.election.status !== ElectionStatusType.DRAFT}
                    ballotingYear={electionPeriod.ballotingYear}
                    electionType={ElectionType.NATIONAL_DELEGATE_ELECTION}
                    getVotingStartDate={getVotingStartDate}
                    periodId={electionPeriod.id}
                    initSetUpElectionForm={setSetUpElectionForm}
                    initManageTellersForm={setManageTellersForm}
                    saveElectionAsDraftCb={saveElectionAsDraftCb}
                    setUpElectionStepConfig={setUpElectionStepConfig}
                    verifyParticipantsContent={<VerifyNationalDelegateElectionParticipants
                        electionPeriodId={electionPeriod?.id.toString() || ''}
                    />}
                    setUpTellersStepConfig={setUpTellersStepConfig}
                />
            }
        </>
    )
}

export default NationalDelegateElectionEditingProcess