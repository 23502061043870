import { useElection } from 'api'
import { useAuth } from 'hooks'
import { ElectionType } from 'models'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import VotingStatisticTableDataProvider from '../../providers/VotingStatisticTableDataProvider'
import VotingStatisticTable from '../VotingStatisticTable'

const votingStatisticSupportedFor = [ElectionType.NATIONAL_DELEGATE_ELECTION, ElectionType.RBC_ELECTION]

function VotingStatistic() {

    const { auth } = useAuth()
    const { electionId } = useParams()
    const navigate = useNavigate()

    const { data: election } = useElection(electionId || auth!.electionId!, !!electionId || !!auth?.electionId)

    useEffect(() => {
        if (!!election && !votingStatisticSupportedFor.includes(election.type)) {
            navigate('/access-denied', { replace: true })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [election])

    return <VotingStatisticTableDataProvider>
        <VotingStatisticTable />
    </VotingStatisticTableDataProvider>
}

export default VotingStatistic