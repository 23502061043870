import PageHeader from 'components/atoms/PageHeader'
import BackToDashboardBtn from 'components/molecules/BackToDashboardBtn'
import Wizard from 'components/molecules/Wizard'
import WizardStep from 'components/molecules/Wizard/WizardStep'
import { IWizardStepProps } from 'components/molecules/Wizard/models'
import { useConfirmPopup, useElectionTypeMap, useHeaderLogoRedirect } from 'hooks'
import { ReactComponent as Save } from 'icons/save.svg'
import { ElectionType } from 'models'
import { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from './index.module.scss'

type ElectionEditingWizardProps = {
    steps: IWizardStepProps[]
    saveAsDraftCb: () => void
    type: ElectionType
    region?: string
    year: number
    wizardStepStorageKey?: string
    lastStepNote?: ReactNode
    showLastStepNote?: boolean
    onCurrentStepChange?: (stepIndex: number) => void
    initStep?: number
    announced: boolean
}

function ElectionEditingWizard(
    { steps, saveAsDraftCb, type, region, year, initStep,
        wizardStepStorageKey, lastStepNote, onCurrentStepChange, announced, showLastStepNote = true }
        : ElectionEditingWizardProps) {
    const { t } = useTranslation('election')
    const [pageHeader, setPageHeader] = useState<ReactNode>(null)
    const { showConfirm, confirmPortal } = useConfirmPopup()
    const { toString } = useElectionTypeMap()
    const [stepIndex, setStepIndex] = useState(0)
    const { setConfirmationCallback } = useHeaderLogoRedirect()

    useEffect(() => {
        setConfirmationCallback(() => backToDashboard)

        return () => {
            setConfirmationCallback(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const backToDashboard = (navigateToDashboard: (replace?: boolean) => void) => {
        if (navigateToDashboard) {
            showConfirm({
                title: t('common:leave_current_page'),
                text:
                    <>
                        <div>
                            {t('common:would_you_like_to_leave_this_page_without_saving')}
                            <div style={{ paddingTop: 'var(--default-space' }}>
                                <b>{t('common:please_note_that_data_on_previous_step_already_saved')}</b>
                            </div>
                        </div>
                    </>,
                okText: <>{t('common:leave')}</>,
                cancelText: <>{t('common:continue_editing')}</>,
                onOk: async () => navigateToDashboard(false)
            })
        }
    }

    const updateGreeting = (currentStepIndex?: number) => {
        let index = stepIndex
        if (currentStepIndex != null) {
            setStepIndex(currentStepIndex)
            onCurrentStepChange?.(currentStepIndex)
            index = currentStepIndex
        }

        setPageHeader(
            <PageHeader
                greeting={<div className={style.header}>
                    {t('year_b_e_election', { year, election: toString(type) })} {region}
                    {index === 2 && showLastStepNote
                        && <div className={style.note}>
                            {lastStepNote}
                        </div>}
                </div>}
            >
                {!announced &&
                    <button className={`${style.draft} btn-main-tertiary-md`} onClick={() => saveAsDraftCb()}>
                        <Save width="20" height="20" />
                        <div>{t('save_as_draft')}</div>
                    </button>
                }
            </PageHeader>
        )
    }

    useEffect(() => {
        updateGreeting()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showLastStepNote, lastStepNote])

    return (
        <>
            {confirmPortal}
            <Wizard headerLeftPanel={
                <BackToDashboardBtn onClick={backToDashboard} />
            }
                steps={steps.map((step, index) => <WizardStep key={index} {...step} />)}
                stepStorageKey={wizardStepStorageKey}
                onStepChange={updateGreeting}
                pageHeader={pageHeader}
                initStep={initStep}
            />
        </>
    )
}

export default ElectionEditingWizard