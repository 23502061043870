import { useConfirmedCandidates, useElection, useElectionResultsTie } from 'api'
import Note, { NoteProps } from 'components/molecules/Note'
import { ElectionResult, ElectionResultSummary, ElectionType } from 'models'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { formatNames, isElectionFrozen } from 'utils'
import style from './index.module.scss'

type TieResolvedInfoProps = {
    electionId: string
    noteProps?: NoteProps
    summary?: ElectionResultSummary
    tieDesignatedCandidates?: ElectionResult[]
    revoteRoundTieCandidates?: string
}

function TieResolvedInfo({
    electionId,
    noteProps,
    summary,
    tieDesignatedCandidates,
    revoteRoundTieCandidates
}: TieResolvedInfoProps) {
    const { t } = useTranslation('teller')

    const { data: electionResultTie } = useElectionResultsTie(electionId, !!electionId)
    const { data: election } = useElection(electionId, !!electionId)
    const { data: confirmedCandidates } =
        useConfirmedCandidates(electionId || '', isElectionFrozen(summary), !!electionId)

    const designatesTieCandidates =
        formatNames(tieDesignatedCandidates?.map(candidate => candidate.name
            || `[${t('deactivated_member')}]`) || [], t('and'))

    const tieDesignatedCandidatesByNsa = useMemo(() => {
        if (!electionResultTie?.candidates || !tieDesignatedCandidates || !confirmedCandidates) {
            return ''
        }

        const tieDesignatedSet = new Set(tieDesignatedCandidates.map(c => c.id))
        const confirmedSet = new Set(confirmedCandidates.map(c => c.id))

        const filteredTieCandidates = electionResultTie.candidates
            .filter(c => !tieDesignatedSet.has(c.id))
            .filter(c => confirmedSet.has(c.id))

        const sortedCandidates = filteredTieCandidates.sort((a, b) => a.name.localeCompare(b.name))

        return formatNames(sortedCandidates.map(c => c.name), t('and'))
    }, [electionResultTie, tieDesignatedCandidates, confirmedCandidates, t])

    return (
        <span className={style.noteWrapper}>
            <Note {...noteProps} mode={noteProps?.mode || 'info'} icon={noteProps?.icon || 'filled'}>
                <span className={style.infoWrapper}>
                    {(revoteRoundTieCandidates?.length ?? 0) > 0 && <span>
                        <span className={style.textBold}>
                            {revoteRoundTieCandidates}
                        </span>
                        {` ${t('elected_via_revote_using_obs')}`}
                    </span>}
                    {((tieDesignatedCandidatesByNsa.length ?? 0) > 0 && summary?.breakingTieSkipped
                        && election?.type !== ElectionType.RBC_ELECTION)
                        && <span>
                            <span className={style.textBold}>
                                {tieDesignatedCandidatesByNsa}
                            </span>
                            {` ${t('designated_as_electee')} ${t('results:by_national_spiritual_assembly')}`}
                        </span>}
                    {(tieDesignatedCandidates?.length ?? 0) > 0
                        &&
                        <span style={summary?.breakingTieSkipped ? { color: 'var(--text-secondary)' } : undefined}>
                            <span className={style.textBold}>
                                {designatesTieCandidates}
                            </span>
                            {` ${t('designated_as_electee')}.`}
                            {electionResultTie?.resolution.comment && <span className={style.textBold}>
                                {` ${t('common:comment')}: `}
                            </span>}
                            {`${electionResultTie?.resolution.comment || ''}`}
                        </span>}
                </span>
            </Note >
        </span >
    )
}

export default TieResolvedInfo