import {
    useElectionPeriod,
    useLocalDelegateParticipantsStatistic,
    useParticipants,
    useRidvanParticipantsStatistic
} from './api'
import LocalDelegateElectionBoard from './pages/LocalDelegateElectionBoard'
import RidvanElectionEditing from './pages/RidvanElectionEditing'
import { getLocalDelegateStatus, isRidvanSecondStage, isRidvanStage } from './utils'

export const ridvan = {
    api: {
        useElectionPeriod,
        useParticipants,
        useRidvanParticipantsStatistic,
        useLocalDelegateParticipantsStatistic
    },
    pages: {
        RidvanElectionEditing,
        LocalDelegateElectionBoard
    },
    utils: {
        isRidvanStage,
        getLocalDelegateStatus,
        isRidvanSecondStage
    }
}