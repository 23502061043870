import {
    useElectionBallotsCounts,
    useElectionOnlineBallotsCount,
    useElectionParticipantsStatistic,
    useElectionStationsVotesCounts,
    useVerifiedVoterCount,
    useVotersCount,
    useVotesCount
} from 'api'
import { useAuth } from 'hooks'
import { Election, ElectionStatusType, ElectionType, VotingApproach } from 'models'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useElectionVoterSammaryReport } from '../../api/queries'
import { reportingAssets } from '../../features/reportingAssets'

type ElectionStatisticProps = {
    election: Election
}

function ElectionStatistic({
    election
}: ElectionStatisticProps) {
    const { t } = useTranslation('telling-station')
    const { auth } = useAuth()
    const isRbcElection = election.type === ElectionType.RBC_ELECTION

    const [totalInvalidVotes, setTotalInvalidVotes] = useState<number>(0)

    const { data: participantsStatistic } = useElectionParticipantsStatistic(election.id.toString() ||
        auth?.electionId, !!election.id || !!auth?.electionId, election.status === ElectionStatusType.COMPLETED)
    const { data: votersCount } = useVotersCount(election.id.toString()
        || auth!.electionId!, !!election.id || !!auth?.electionId)
    const { data: inPersonVotesCount } = useVotesCount(election.id.toString() || auth!.electionId!,
        !!election.id || !!auth?.electionId, VotingApproach.IN_PERSON)
    const { data: inAbsenceVotesCount } = useVotesCount(election.id.toString() || auth!.electionId!,
        !!election.id || !!auth?.electionId, VotingApproach.IN_ABSENCE)
    const { data: ballotsCounts } = useElectionBallotsCounts(election.id.toString() || auth!.electionId!,
        !!election.id || !!auth?.electionId)
    const { data: onlineBallotsCount } = useElectionOnlineBallotsCount(election.id.toString() || auth!.electionId!,
        !!election.id || !!auth?.electionId)
    const { data: electionVoterSammaryReport } = useElectionVoterSammaryReport(election.id.toString()
        || auth!.electionId!, !!election.id || !!auth?.electionId)
    const { data: stationsVotesCounts } = useElectionStationsVotesCounts(election.id.toString() || auth!.electionId!,
        !!election.id || !!auth?.electionId)
    const { data: votesCount } = useVotesCount(election.id.toString() || auth!.electionId!, !!election.id
        || !!auth?.electionId)
    const { data: verifiedVoterCount } = useVerifiedVoterCount(election.id.toString() || auth!.electionId!,
        !!election.id || !!auth?.electionId)

    const handleTotalInvalidVotesChange = (totalVotes: number) => {
        setTotalInvalidVotes(totalVotes)
    }

    return (
        <>
            {isRbcElection
                && <reportingAssets.components.TitleStatistic number={'1.'}
                    text={t('teller:total_of_local_assemblies_in_the_region')}
                    statistic={election.totalAssembliesInRegion ?? 0}
                />}
            <reportingAssets.components.TitleStatistic number={isRbcElection ? '2.' : '1.'}
                text={ElectionType.LOCAL_DELEGATE_ELECTION === election.type
                    ? t('teller:total_of_eligible_electors_in_the_local_unit')
                    : ElectionType.NATIONAL_DELEGATE_ELECTION === election.type
                        ? t('teller:total_of_eligible_electors_in_the_unit')
                        : isRbcElection
                            ? t('teller:total_of_eligible_electors_in_the_region')
                            : t('teller:total_of_eligible_electors_in_the_locality')}
                statistic={participantsStatistic?.voterCount ?? 0}
            />
            <reportingAssets.components.TitleStatistic number={isRbcElection ? '3.' : '2.'}
                text={t('teller:total_of_eligible_electors_voting')}
                statistic={(verifiedVoterCount?.totalCount ?? 0) + (votersCount?.totalCount ?? 0)}
            />
            <reportingAssets.components.SubtitleStatistic
                number={isRbcElection ? '3.1' : '2.1'}
                text={t('teller:voting_in_person')}
                statistic={(verifiedVoterCount?.votedInPersonCount ?? 0) +
                    (inPersonVotesCount?.totalCount ?? 0)}
            />
            <reportingAssets.components.ListItem>
                <reportingAssets.components.StatisticList
                    text={t('teller:paper_ballot_at_the_meeting')}
                    statistic={verifiedVoterCount?.votedInPersonCount ?? 0}
                />
                <reportingAssets.components.StatisticList
                    text={t('teller:voting_online_at_the_meeting')}
                    statistic={inPersonVotesCount?.totalCount ?? 0}
                />
            </reportingAssets.components.ListItem>
            <reportingAssets.components.SubtitleStatistic
                number={isRbcElection ? '3.2' : '2.2'}
                text={t('teller:voting_absentee')}
                statistic={(verifiedVoterCount?.votedInAbsenceCount ?? 0) +
                    (inAbsenceVotesCount?.totalCount ?? 0)}
            />
            <reportingAssets.components.ListItem>
                <reportingAssets.components.StatisticList
                    text={t('teller:physical_ballots')}
                    statistic={verifiedVoterCount?.votedInAbsenceCount ?? 0}
                />
                <reportingAssets.components.StatisticList
                    text={t('teller:voting_online')}
                    statistic={inAbsenceVotesCount?.totalCount ?? 0}
                />
            </reportingAssets.components.ListItem>
            <reportingAssets.components.TitleStatistic number={isRbcElection ? '4.' : '3.'}
                text={t('teller:total_of_valid_ballots')}
                statistic={(ballotsCounts?.validBallotCount ?? 0) +
                    (onlineBallotsCount?.totalCount ?? 0)}
            />
            <reportingAssets.components.ListItem>
                <reportingAssets.components.StatisticList
                    text={t('teller:from_paper_ballots')}
                    statistic={ballotsCounts?.validBallotCount ?? 0}
                />
                <reportingAssets.components.StatisticList
                    text={t('teller:from_online')}
                    statistic={onlineBallotsCount?.totalCount ?? 0}
                />
            </reportingAssets.components.ListItem>
            <reportingAssets.components.TitleStatistic number={isRbcElection ? '5.' : '4.'}
                text={t('total_of_invalid_ballots')}
                statistic={(ballotsCounts?.invalidBallotCount || 0) +
                    (electionVoterSammaryReport?.markedInnerEnvelopeCount || 0)}
            />
            <reportingAssets.components.InvalidBallotsStatistic
                electionVoterSammaryReport={electionVoterSammaryReport}
                ballotsCounts={ballotsCounts}
            />
            <reportingAssets.components.InfoBlock />
            <reportingAssets.components.TitleStatistic number={isRbcElection ? '6.' : '5.'}
                text={t('total_of_valid_votes')}
                statistic={(stationsVotesCounts?.validVoteCount ?? 0) +
                    (votesCount?.totalCount ?? 0)}
            />
            <reportingAssets.components.TitleStatistic number={isRbcElection ? '7.' : '6.'}
                text={t('total_of_invalid_votes')}
                statistic={totalInvalidVotes}
            />
            <reportingAssets.components.InvalidVotesStatistic
                stationsVotesCounts={stationsVotesCounts}
                onTotalInvalidVotesChange={handleTotalInvalidVotesChange}
            />
        </>
    )
}

export default ElectionStatistic
