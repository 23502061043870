import { useElection, useLocality } from 'api'
import PopupFooter from 'components/atoms/PopupFooter'
import Note from 'components/molecules/Note'
import OptionalTooltip from 'components/molecules/OptionalTooltip'
import { ElectionType } from 'models'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useConfirmResults } from '../../../../api'
import { useConfirmation } from '../../hooks'
import style from './index.module.scss'


type ConfirmElectionResultsProps = {
    hidePopup: () => void,
    onConfirm: () => void
}

function ConfirmElectionResults({
    hidePopup,
    onConfirm
}: ConfirmElectionResultsProps) {
    const { t } = useTranslation('results')
    const { electionId } = useParams()
    const { data: election } = useElection(electionId!, !!electionId)
    const { data: locality } = useLocality(election?.region?.locality || '', !!election)
    const { candidatesToConfirm } = useConfirmation()
    const { mutateAsync: confirmResults } = useConfirmResults()

    return (
        <>
            <div className={style.title}>
                <Trans
                    i18nKey={
                        election?.type === ElectionType.BY_ELECTION
                            ? t('you_are_about_to_confirm_the_next_member_for')
                            : election?.type === ElectionType.NATIONAL_DELEGATE_ELECTION
                                ? t('you_are_about_to_confirm_the_next_delegates_for_unit')
                                : election?.type === ElectionType.RBC_ELECTION
                                    ? t('you_are_about_to_confirm_the_next_members_for_institution')
                                    : t('you_are_about_to_confirm_the_next_local_delegates_for_the_local_unit')
                    }
                    values={
                        election?.type === ElectionType.BY_ELECTION
                            ? {
                                interpolation: { escapeValue: false },
                                localityCode: election?.region?.locality,
                                localityName: locality?.name
                            }
                            : election?.type === ElectionType.NATIONAL_DELEGATE_ELECTION
                                ? {
                                    interpolation: { escapeValue: false },
                                    euCode: election?.region?.unit
                                }
                                : election?.type === ElectionType.RBC_ELECTION
                                    ? {
                                        interpolation: { escapeValue: false },
                                        institutionName: `<strong>${election?.region?.rbc}</strong>`
                                    }
                                    : {
                                        interpolation: { escapeValue: false },
                                        unit: election?.region?.localUnit,
                                        localityCode: election?.region?.locality,
                                        localityName: locality?.name
                                    }
                    }
                    components={[<strong />]}
                />
            </div>
            {
                candidatesToConfirm
                    .sort((a, b) => b.voteCount - a.voteCount || a.name.localeCompare(b.name))
                    .map(c => <div key={c.id} className={style.delegate}>
                        <OptionalTooltip contentWrapperClassName="ellipsis">
                            {c.name}
                        </OptionalTooltip>
                    </div>)
            }
            {(election?.type === ElectionType.BY_ELECTION
                || election?.type === ElectionType.NATIONAL_DELEGATE_ELECTION)
                &&
                <>
                    <div className={style.boldText}>
                        {election?.type === ElectionType.NATIONAL_DELEGATE_ELECTION
                            ? t('upon_confirming_the_final_version_of_the_tellers')
                            : t('click_on_confirm_send_to_electronically_transmit_the_results_to_emembership2')}
                    </div>
                    <div className={style.note}>
                        <Note icon="regular" mode="info">
                            {t('confirming_and_sending_the_results_will_automatically_complete_the_election')}
                        </Note>
                    </div>
                </>
            }
            <div className={style.footer}>
                <PopupFooter>
                    <button className="btn-main-secondary"
                        onClick={hidePopup}>
                        {t('common:cancel')}
                    </button>
                    <button
                        type="submit"
                        onClick={async () => {
                            await confirmResults({
                                electionId: election?.id.toString() || '',
                                confirmedCandidatesIds: candidatesToConfirm.map(c => c.id)
                            })
                            onConfirm()
                        }
                        }
                        className={'btn-main-primary'}>
                        {election?.type === ElectionType.BY_ELECTION
                            || election?.type === ElectionType.NATIONAL_DELEGATE_ELECTION
                            ? t('confirm_and_send') : t('common:confirm')}
                    </button>
                </PopupFooter>
            </div>
        </>
    )
}

export default ConfirmElectionResults