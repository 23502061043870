import { Select as AntSelect, Form } from 'antd'
import { Select } from 'components/atoms/Select'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNdeElectoralUnits } from '../../api'
import { NdeElectoralUnit } from '../../model'
type ElectoralUnitFilterProps = {
    disabled?: boolean
    bahaiYear?: number
}

function ElectoralUnitFilter({ disabled, bahaiYear }: ElectoralUnitFilterProps) {
    const { t } = useTranslation('administration')
    const [sortedUnits, setSortedUnits] = useState<NdeElectoralUnit[]>()
    const { data: electoralUnits } = useNdeElectoralUnits(bahaiYear!, !!bahaiYear)

    useEffect(() => {
        if (electoralUnits) {
            const units = [...electoralUnits]
            units.sort((a, b) => a.unitCode?.toLowerCase().localeCompare(b.unitCode?.toLowerCase()) || 1)
            setSortedUnits(units)
        }
    }, [electoralUnits])

    return (
        <Form.Item
            name={['unitCode']}
            className="no-padding"
            label={t('electoral_unit')}
            style={{ width: 188 }}
            required
        >
            <Select
                disabled={disabled}
                showSearch
                placeholder={t('select_input', { input: t('electoral_unit') })}
                notFoundContent={t('no_results_found')}
                className="small">
                {sortedUnits?.map(eu => <AntSelect.Option key={eu.unitCode} value={eu.unitCode} >
                    {eu.unitCode}
                </AntSelect.Option>)}
            </Select>
        </Form.Item>
    )
}

export default ElectoralUnitFilter