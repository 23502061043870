import Card from 'components/atoms/Card'
import EmptyMessage from 'components/atoms/EmptyMessage'
import CardHeader from 'components/molecules/CardHeader'
import { ReactComponent as Vote } from 'icons/vote.svg'
import { Election } from 'models'
import { useTranslation } from 'react-i18next'
import { getUniqueElectionPeriods } from '../../utils'
import RbcElectionItem from '../RbcElectionItem'
import styles from './index.module.scss'

type ActiveElectionsProps = {
    elections: Election[]
    isSuccess: boolean
}

const ActiveElections = ({ elections, isSuccess }: ActiveElectionsProps) => {
    const { t } = useTranslation('election')

    const uniqueElectionPeriods = getUniqueElectionPeriods(elections)

    return (
        <div className={styles.electionsWrapper}>
            <Card title={<CardHeader title={t('active_elections')} />} noContentPadding={true}>
                {isSuccess && (
                    uniqueElectionPeriods.length ? (
                        uniqueElectionPeriods.map((period) => (
                            <RbcElectionItem
                                key={period}
                                electionPeriod={period}
                            />
                        ))
                    ) : (
                        <EmptyMessage
                            title={t('there_is_no_active_election')}
                            description={t('click_button_and_go_through_the_creation_steps')}
                        >
                            <Vote />
                        </EmptyMessage>
                    )
                )}
            </Card>
        </div>
    )
}

export default ActiveElections