import BallotingProgressBar from 'components/molecules/BallotingProgressBar'
import { ElectionBallotingProgressStatus, ElectionStatusType } from 'models'
import { useEffect, useState } from 'react'
import { DelegateElection } from '../../model'
import style from './index.module.scss'

interface DelegateElectionBallotingProgressProps {
    election: DelegateElection
}

function DelegateElectionBallotingProgress({ election }: DelegateElectionBallotingProgressProps) {
    const [status, setStatus]
        = useState<ElectionBallotingProgressStatus>(ElectionBallotingProgressStatus.TellingNotStarted)

    useEffect(() => {
        if (election) {
            const signed = !!election.chiefTellerTasks.reportSigned
            const merged = !!election.chiefTellerTasks.resultsMerged

            const notStartedElectionStatuses = [
                ElectionStatusType.NOT_CREATED,
                ElectionStatusType.DRAFT,
                ElectionStatusType.NOT_STARTED
            ]

            if (notStartedElectionStatuses.includes(election.electionStatus)
                || election.chiefTellerTasks.bypassFlowEnabled === null) {
                setStatus(ElectionBallotingProgressStatus.TellingNotStarted)

                return
            }

            if (signed) {
                setStatus(ElectionBallotingProgressStatus.ReportingComplete)

                return
            }

            if (merged) {
                if (election.tiePresent) {
                    if (!election.breakingTieResolved && !election.breakingTieSkipped) {
                        setStatus(ElectionBallotingProgressStatus.MergingInProgress)

                        return
                    }
                }
                setStatus(ElectionBallotingProgressStatus.MergingComplete)

                return
            }

            setStatus(ElectionBallotingProgressStatus.TellingInProgress)

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [election])

    return (
        <div className={style.ballotingProgress}>
            <BallotingProgressBar
                status={status}
                bypassFlowEnabled={!!election?.chiefTellerTasks.bypassFlowEnabled}
            />
        </div>
    )
}

export default DelegateElectionBallotingProgress