import Empty from 'components/molecules/Empty'
import InternalScrollTableDefaultHeader from 'components/molecules/InternalScrollTableDefaultHeader'
import { useAuth } from 'hooks'
import { Ballot, Election, InternalScrollTableDefaultColumn } from 'models'
import { ReactNode, useMemo } from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { FixedSizeList as List } from 'react-window'
import { useBallotInvalidationReasons, useVoteInvalidationReasons } from '../../api/queries'
import InternalScrollBallotsTableItem from '../InternalScrollBallotsTableItem'
import style from './index.module.scss'

const LIST_ITEM_HEIGHT = 40

type InternalScrollBallotsTableProps = {
    ballots: Ballot[]
    statistic: ReactNode
    election: Election
    noBorders?: boolean
    height?: number
}

function InternalScrollBallotsTable({
    ballots,
    statistic,
    election,
    noBorders,
    height
}: InternalScrollBallotsTableProps) {
    const { t } = useTranslation('teller')

    const { auth } = useAuth()
    const { electionId } = useParams()
    const { data: voteInvalidationReasons } = useVoteInvalidationReasons(electionId || auth?.electionId!)
    const { data: ballotInvalidationReasons } = useBallotInvalidationReasons(electionId || auth?.electionId!)

    const columns: InternalScrollTableDefaultColumn[] = useMemo(() =>
        [
            { title: t('ballot_id'), width: 110 },
            { title: t('valid'), width: 80 },
            { title: t('votes'), width: 713 },
            { title: t('submitted'), width: 200 },
            { title: t('submitted_by'), width: 140 },
            { title: t('valid_votes'), width: 100 }
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [])

    const listHeight = (ballots?.length ?? 0) * LIST_ITEM_HEIGHT

    return (
        <div className={noBorders ? style.listContainerWithoutBorders : style.listContainer}>
            {statistic}
            <InternalScrollTableDefaultHeader columns={columns} />
            {ballots?.length ?
                <List
                    style={style}
                    outerElementType={Scrollbars}
                    className={style.list}
                    height={height || listHeight}
                    itemCount={ballots.length}
                    itemSize={LIST_ITEM_HEIGHT}
                    width={'auto'}
                >
                    {({ index, style: itemStyle }) => (
                        <InternalScrollBallotsTableItem
                            election={election}
                            style={itemStyle}
                            ballot={ballots[index]}
                            voteInvalidationReasons={voteInvalidationReasons}
                            ballotInvalidationReasons={ballotInvalidationReasons}
                        />
                    )}
                </List>
                : <div className={style.empty}>
                    <Empty />
                </div>
            }
        </div>
    )
}

export default InternalScrollBallotsTable