import { useDeleteElectionPeriod } from 'api'
import CardSubheader from 'components/molecules/CardSubheader'
import ElectionBadge from 'components/molecules/ElectionBadge'
import { useConfirmPopup } from 'hooks'
import { ElectionStatusType, ElectionType, UrlPeriodType } from 'models'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DelegateElectionItem, ElectionItem } from '../../models'
import ElectionProcessCard from './ElectionProcessCard'
import ElectionResultsCard from './ElectionResultsCard'
import SubHeaderRightSection from './SubHeaderRightSection'
import TellersCard from './TellersCard'
import styles from './index.module.scss'


type ElectionListItemProps = {
    election: ElectionItem | DelegateElectionItem,
}

const ElectionListItem: React.FC<ElectionListItemProps> = ({
    election
}) => {
    const { mutateAsync: deleteElectionPeriod } = useDeleteElectionPeriod()
    const { showConfirm, confirmPortal } = useConfirmPopup()
    const { t } = useTranslation('election')

    const cardSubtitle = useMemo(() => {
        if (election.status === ElectionStatusType.NOT_STARTED) {
            return t('election_will_be_started_automatically_on_the_online_voting_start_date')
        }

        return ''
    }, [election.status])// eslint-disable-line react-hooks/exhaustive-deps

    const periodType = () => {
        switch (election.type) {
            case ElectionType.LOCAL_DELEGATE_ELECTION:
            case ElectionType.RIDVAN_ELECTION:
                return UrlPeriodType.RidvanElectionPeriods
            case ElectionType.BY_ELECTION:
                return UrlPeriodType.ByElectionPeriods
            case ElectionType.NATIONAL_DELEGATE_ELECTION:
                return UrlPeriodType.NationalDelegateElectionPeriods
            default:
                throw new Error('Cannot remove unknown Period')
        }
    }

    const deleteElection = (electionPeriodId: number) => {
        showConfirm({
            text: <div>
                <div className={styles.lose}>
                    {t('by_deleting_you_will_lose_the_data_entered')}
                </div>
                <div className={styles.highlighted}>
                    {t('are_you_sure_you_want_to_delete_this_election')}
                </div>
            </div>,
            title: t('delete_election'),
            onOk: async () => {
                await deleteElectionPeriod({
                    electionPeriodId,
                    periodType: periodType()
                })
            },
            okText: t('delete_election'),
            reverseButtons: true
        })
    }


    return (
        <div className={styles.election}>

            <CardSubheader
                subTitle={cardSubtitle}
                title={election.title}
                noPadding={true}
                badge={<ElectionBadge status={election.status} />}
            >
                <SubHeaderRightSection election={election} deleteElection={deleteElection} />
            </CardSubheader>

            <div className={styles.content}>
                <div className={styles.info}>
                    <div className={styles.item}>
                        <span className={styles.label}>
                            {t('online_voting_start')}:
                        </span> {election.onlineVotingStartDate}
                    </div>
                    <div className={styles.item}>
                        <span className={styles.label}>{
                            election.type === ElectionType.NATIONAL_DELEGATE_ELECTION
                                ? t('convention_date')
                                : t('election_date')
                        }:</span> {election.electionDay}
                    </div>
                </div>
                <TellersCard election={election} status={election.status} />
                <ElectionProcessCard election={election} status={election.status} />
                <ElectionResultsCard election={election} status={election.status} />
            </div>
            {confirmPortal}
        </div>
    )
}

export default ElectionListItem