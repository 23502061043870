import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { Election } from 'models'

export const useAdminOngoingElections = (enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getAdminOngoingElections = async (): Promise<Election[]> => {
        const restrictions = await axios<Election[]>(`${moduleUrl}me/admin/elections/ongoing`)

        return restrictions.data
    }

    return useQuery({
        queryKey: ['elections-my-ongoing-admin'],
        queryFn: () => getAdminOngoingElections(),
        enabled
    })
}