import { useElection, useElectionParticipantsStatistic, useOnlineVoting, useVotersCount } from 'api'
import MilestoneItem from 'components/atoms/MilestoneItem'
import ElectionBadge from 'components/molecules/ElectionBadge'
import { useAuth } from 'hooks'
import { ReactComponent as Chevron } from 'icons/chevron.svg'
import { ElectionStatusType, ElectionType, MilestoneColorType } from 'models'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { dayjsToString } from 'utils'
import style from './index.module.scss'

function OnlineVotingMilestone() {
    const { t } = useTranslation('teller')

    const { electionId } = useParams()
    const { auth } = useAuth()
    const { data: election } = useElection(electionId || auth!.electionId!, !!electionId || !!auth?.electionId)
    const { data: onlineVoting } = useOnlineVoting(electionId || auth!.electionId!, !!electionId || !!auth?.electionId)
    const { data: participantsStatistic } =
        useElectionParticipantsStatistic(electionId || auth?.electionId, !!electionId || !!auth?.electionId,
            election?.status === ElectionStatusType.COMPLETED)
    const { data: votersCount } = useVotersCount(electionId || auth!.electionId!, !!electionId || !!auth?.electionId)
    const [milestoneColor, setMilestoneColor] = useState<MilestoneColorType>(MilestoneColorType.DEFAULT)
    const [milestoneStatus, setMilestoneStatus] = useState<ElectionStatusType>(ElectionStatusType.NOT_STARTED)
    const [votedIndividualsTitle, setVotedIndividualsTitle] = useState<string>('')

    useEffect(() => {
        setVotedIndividualsTitle(t('voted_individuals', {
            votersTotalCount: votersCount?.totalCount,
            participantsTotalCount: participantsStatistic?.voterCount
        }))
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [votersCount, participantsStatistic])

    useEffect(() => {
        if (onlineVoting) {
            if (onlineVoting.closed) {
                if (onlineVoting.votingReportAccepted) {
                    setMilestoneColor(MilestoneColorType.SUCCESS)
                    setMilestoneStatus(ElectionStatusType.COMPLETED)
                } else {
                    setMilestoneColor(MilestoneColorType.DEFAULT)
                    setMilestoneStatus(ElectionStatusType.NOT_STARTED)
                }
            } else {
                setMilestoneColor(MilestoneColorType.ACTIVE)
                setMilestoneStatus(ElectionStatusType.IN_PROGRESS)
            }
        }
    },
        [onlineVoting])

    const getVotedIndividualsClassName = () => {
        switch (milestoneColor) {
            case MilestoneColorType.ACTIVE:
                return style.votedActive
            case MilestoneColorType.SUCCESS:
                return style.votedClosed
            default:
                return style.votedDefault
        }
    }

    const dateFrom = useMemo(() => {
        if (election?.electionStart) {
            return dayjsToString(election.electionStart, 'MM/DD/YYYY')
        }

        return null
    }, [election])

    const dateTo = useMemo(() => {
        if (election) {
            if (election.type === ElectionType.RBC_ELECTION) {
                if (election?.onlineVotingEnd) {
                    return dayjsToString(election.onlineVotingEnd, 'MM/DD/YYYY')
                }
            } else if (election.electionEnd) {
                return dayjsToString(election.electionEnd, 'MM/DD/YYYY')
            }
        }

        return null
    }, [election])

    return (
        <div className={style.contentWrapper}>
            <MilestoneItem
                descriptionWithoutMargin
                title={t('online_voting')}
                color={milestoneColor}
                description={dateFrom && dateTo ? `${dateFrom} - ${dateTo}` : ''}
                children={
                    <div className={getVotedIndividualsClassName()}>{votedIndividualsTitle}</div>
                }
                status={<ElectionBadge status={milestoneStatus} />}
            />
            <div className={style.arrowIcon}><Chevron /></div>
        </div>
    )
}

export default OnlineVotingMilestone