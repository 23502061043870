import { RbcElectionsColoring } from 'models'

export function getRbcBackgroundColor(rbcName: string, rbcElectionsColoring: RbcElectionsColoring[]) {
    const rbcColor = rbcElectionsColoring.find(color => color.rbcName === rbcName)

    if (rbcColor) {
        return {

            backgroundColor: `#${rbcColor.headerBackgroundHex}`,
            color: `#${rbcColor.headerTextHex}`
        }
    }

    return {
        backgroundColor: '',
        color: ''
    }
}