import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { DelegateElection } from '../../model'
import style from './index.module.scss'

type ElectionResultActionProps = {
    election: DelegateElection
}

function ElectionResultAction({ election }: ElectionResultActionProps) {
    const { t } = useTranslation()
    const navigate = useNavigate()

    return (
        <>
            {!!election.resultsConfirmed ? (
                <button
                    className={`${style.action} btn-main-secondary-md`}
                    onClick={() =>
                        navigate(`/confirmed-results/${election.id}`, {
                            state: { backButtonTitle: t('election:back_to_election_board') }
                        })
                    }
                >
                    {t('view_results')}
                </button>
            ) : (
                <button
                    className={`${style.action} ${!!election.chiefTellerTasks.reportSigned
                        ? 'btn-main-primary-md' : 'btn-main-secondary-md'
                        }`}
                    onClick={() =>
                        navigate(`/confirm-results/${election.id}`, {
                            state: { backButtonTitle: t('election:back_to_election_board') }
                        })
                    }
                    disabled={!election.chiefTellerTasks.reportSigned}
                >
                    {t('confirm_results')}
                </button>
            )}
        </>
    )
}

export default ElectionResultAction