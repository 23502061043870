import { ReactComponent as CheckmarkInDoubleCircleFilled } from 'icons/checkmark-in-double-circle-filled.svg'
import { ReactComponent as Circle } from 'icons/circle.svg'
import { ReactComponent as RoundedCircle } from 'icons/rounded-circle.svg'
import { ElectionBallotingProgressStatus } from 'models'
import style from './index.module.scss'

type BallotingProgressBarProps = {
    status: ElectionBallotingProgressStatus
    bypassFlowEnabled?: boolean
}

const BallotingProgressBar = ({ status, bypassFlowEnabled }: BallotingProgressBarProps) => {

    const getTellingItem = () => {
        if (bypassFlowEnabled) {
            return <div className={`${style.status} ${style.hidden}`}>
                <Circle />
            </div>
        }
        switch (status) {
            case ElectionBallotingProgressStatus.TellingNotStarted:
                return <div className={`${style.status} ${style.untouched} ${style.nextStepUntouched}`}>
                    <Circle />
                </div>
            case ElectionBallotingProgressStatus.TellingInProgress:
                return <div className={`${style.status} ${style.expected} ${style.nextStepUntouched}`}>
                    <RoundedCircle />
                </div>
            case ElectionBallotingProgressStatus.MergingInProgress:
                return <div className={`${style.status} ${style.done} ${style.nextStepExpected}`}>
                    <RoundedCircle />
                </div>
            default:
                return <div className={`${style.status} ${style.done} ${style.nextStepDone}`}>
                    <CheckmarkInDoubleCircleFilled />
                </div>
        }
    }

    const getMergingItem = () => {
        switch (status) {
            case ElectionBallotingProgressStatus.TellingNotStarted:
            case ElectionBallotingProgressStatus.TellingInProgress:
                return <div className={`${style.status} ${style.untouched} ${style.nextStepUntouched}`}>
                    <Circle />
                </div>
            case ElectionBallotingProgressStatus.MergingInProgress:
                return <div className={`${style.status} ${style.expected} ${style.nextStepUntouched}`}>
                    <RoundedCircle />
                </div>
            case ElectionBallotingProgressStatus.ReportingComplete:
                return <div className={`${style.status} ${style.done} ${style.nextStepDone}`}>
                    <CheckmarkInDoubleCircleFilled />
                </div>
            case ElectionBallotingProgressStatus.MergingComplete:
                return <div className={`${style.status} ${style.done} ${style.nextStepUntouched}`}>
                    <CheckmarkInDoubleCircleFilled />
                </div>
            default:
                return <div className={`${style.status} ${style.untouched} ${style.nextStepUntouched}`}>
                    <Circle />
                </div>
        }
    }

    const getReportingItem = () => {
        switch (status) {
            case ElectionBallotingProgressStatus.MergingComplete:
                return <div className={`${style.status} ${style.untouched} ${style.currentStepUntouched}`}>
                    <Circle />
                </div>
            case ElectionBallotingProgressStatus.ReportingComplete:
                return <div className={`${style.status} ${style.done} ${style.currentStepDone}`}>
                    <CheckmarkInDoubleCircleFilled />
                </div>
            default:
                return <div className={`${style.status} ${style.untouched} ${style.currentStepUntouched}`}>
                    <Circle />
                </div>
        }
    }

    return (
        <>
            {getTellingItem()}
            {getMergingItem()}
            {getReportingItem()}
        </>
    )
}

export default BallotingProgressBar