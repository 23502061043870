import { useQueryClient } from '@tanstack/react-query'
import { notification } from 'antd'
import Checkbox from 'components/atoms/Checkbox'
import OptionalTooltip from 'components/molecules/OptionalTooltip'
import { useAuth, useHandleStationLogoutError, useInfoPopup } from 'hooks'
import { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'
import { defaultToastProps } from 'utils'
import { useRevokeVoterVerification, useVerifyVoter } from '../../api'
import { useVoterVerificationTableData } from '../../hooks'
import { VotingApproach } from '../../models'
import style from './index.module.scss'
type VoterVerificationTableItemProps = {
    index: number,
    style?: CSSProperties,
    isScrolling?: boolean
}

const voterAlreadyVerifiedByAnotherStationMessage = 'A voter can be verified by only one station.'

function VoterVerificationTableItem({
    index,
    style: itemStyle
}: VoterVerificationTableItemProps) {
    const { t } = useTranslation('telling-station')
    const { tableData } = useVoterVerificationTableData()
    const { auth } = useAuth()
    const queryClient = useQueryClient()
    const { infoPortal, showInfo } = useInfoPopup()
    const { handleError } = useHandleStationLogoutError()
    const [toaster, toasterContext] = notification.useNotification()

    const { mutateAsync: revokeVoterVerificationAsync } = useRevokeVoterVerification()
    const { mutateAsync: verifyVoterAsync } = useVerifyVoter()

    const revokeVoterVerification = async (voterId: string) => {
        try {
            await revokeVoterVerificationAsync(voterId)
        } catch (e) {
            handleError(e, showInfo)

            return
        }
        queryClient.invalidateQueries(['verified-voters-of-election'])
    }

    const verifyVoter = async (voterId: string, votingApproach: VotingApproach) => {
        try {
            await verifyVoterAsync({ voterId, votingApproach })
        } catch (e: any) {
            if (e?.response?.data === voterAlreadyVerifiedByAnotherStationMessage) {
                queryClient.invalidateQueries(['verified-voters-of-election'])
                toaster.open({
                    ...defaultToastProps,
                    message: `${t('voter_already_verified_by_the_other_station')}.`
                })
            } else {
                handleError(e, showInfo)
            }

            return
        }
        queryClient.invalidateQueries(['verified-voters-of-election'])
    }

    const voter = tableData.voters[index]

    return (
        <div className={style.listItem} style={itemStyle}>
            {infoPortal}
            {toasterContext}
            <div style={{
                width: tableData.includeLocalUnit ? 345 : 451
            }}
                className={style.column}>
                <span style={{
                    maxWidth:
                        `calc(${tableData.includeLocalUnit ? 345 : 451}px - calc(var(--default-space) * 3)`
                }}>
                    <OptionalTooltip contentWrapperClassName="ellipsis">
                        {voter.name}</OptionalTooltip>
                </span>
            </div >
            <div style={{
                width: 148
            }}
                className={style.column}>
                {voter.id}
            </div >
            <div style={{
                width: 89
            }}
                className={style.column}>
                {voter.homeLocality?.state}
            </div >
            <div style={{
                width: 109
            }}
                className={style.column}>
                <span style={{ maxWidth: `calc(${109}px - calc(var(--default-space) * 3)` }}>
                    <OptionalTooltip contentWrapperClassName="ellipsis">
                        {voter.homeLocality?.name}</OptionalTooltip>
                </span>
            </div >
            {tableData.includeLocalUnit
                ? <div style={{
                    width: 105
                }}
                    className={style.column}>
                    {voter.homeLocalUnit?.code}
                </div >
                : <></>
            }
            <div style={{
                width: 141
            }}
                className={`${style.column} ${style.centered}`}>
                <Checkbox
                    checked={voter.inPerson}
                    disabled={voter.online === false ? (!auth?.stationId
                        || (!!voter.verifier && auth?.stationId !== voter.verifier)) : true}
                    onChange={() => voter.inPerson
                        ? revokeVoterVerification(voter.id)
                        : verifyVoter(voter.id, VotingApproach.IN_PERSON)}
                />
            </div >
            <div style={{
                width: 141
            }}
                className={`${style.column} ${style.centered}`}>
                <Checkbox
                    checked={voter.inAbsence}
                    disabled={voter.online === false ? (!auth?.stationId
                        || (!!voter.verifier && auth?.stationId !== voter.verifier)) : true}
                    onChange={() => voter.inAbsence
                        ? revokeVoterVerification(voter.id)
                        : verifyVoter(voter.id, VotingApproach.IN_ABSENCE)}
                />
            </div >
            <div style={{
                width: 119
            }}
                className={`${style.column} ${style.centered}`}>
                <Checkbox
                    checked={voter.online}
                    disabled
                />
            </div >
            <div style={{
                width: 144
            }}
                className={style.column}>
                {
                    voter.verifier ?
                        t('station:station_number', { number: voter.verifier.replace('S', '') })
                        : String.fromCharCode(8212)
                }
            </div >
        </div >

    )
}

export default VoterVerificationTableItem