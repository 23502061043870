import { Select as AntSelect } from 'antd'
import { Form } from 'antd'
import { useRbcNames } from 'api'
import { Select } from 'components/atoms/Select'
import { ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

type RbcFilterProps = {
    disabled?: boolean
}

function RbcFilter({ disabled }: RbcFilterProps) {
    const { t } = useTranslation()

    const { data: rbcNames } = useRbcNames(!disabled)

    const options = useMemo(() => {
        if (!rbcNames) return <></>

        const sortedRbcs = [...rbcNames].sort((a, b) => {
            const rbcA = a.name.toLowerCase()
            const rbcB = b.name.toLowerCase()

            return rbcA.localeCompare(rbcB)
        })

        const institutions: ReactNode[] = sortedRbcs.map(rbc => (
            <AntSelect.Option value={rbc.name} key={rbc.name}>
                {rbc.name}
            </AntSelect.Option>
        ))

        return <>{institutions}</>
    }, [rbcNames])

    return (
        <Form.Item
            name="rbc"
            className="no-padding"
            label={t('election:institution')}
            style={{ width: 200 }}
            required
        >
            <Select
                disabled={disabled}
                placeholder={t('select_input', { input: t('election:institution') })}
                className="small"
            >
                {options}
            </Select>
        </Form.Item>
    )
}

export default RbcFilter