import PageHeader from 'components/atoms/PageHeader'
import BackToDashboardBtn from 'components/molecules/BackToDashboardBtn'
import Preloader from 'components/molecules/Preloader'
import { useHandleEntityLoadingError, usePageHeader, useReportLoader } from 'hooks'
import {
    AppointedTeller,
    ElectionStatusType,
    ElectionType,
    RbcElection,
    RbcElectionPeriod,
    ReportFormat,
    ReportType,
    SubElectionViewModel,
    SubElectionViewWording
} from 'models'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import SubElectionsList from '../../../../components/SubElectionsList'
import { useElectionPeriod } from '../../api'
import { getRbcStatus } from '../../utils'

function RbcElections() {
    const { t } = useTranslation('election')
    const { handleEntityLoadingError } = useHandleEntityLoadingError()
    const { electionPeriodId } = useParams()
    const navigate = useNavigate()
    const { setPageHeader } = usePageHeader()
    const { loadReport, isReportLoading } = useReportLoader()

    const [statistic, setStatistic] = useState<{ inProgress: number, confirmed: number }>(
        { inProgress: 0, confirmed: 0 })

    const { data: rbcElectionPeriod, error } = useElectionPeriod(electionPeriodId!)

    const allElectionsIds = useMemo(() =>
        rbcElectionPeriod?.elections.map(e => e.id.toString()) || [],
        [rbcElectionPeriod]
    )

    const tableWording: SubElectionViewWording = {
        firstColumnTitle: t('rbc'),
        secondColumnTitle: t('num_of_vacancies'),
        reportName: t('election_statistics_report'),
        totalCountName: t('total_number_of_rbc')
    }

    const electionsTableData = (rbcElectionPeriod: RbcElectionPeriod): SubElectionViewModel[] =>
        rbcElectionPeriod.elections
            .map((election: RbcElection): SubElectionViewModel => ({
                id: election.id,
                name: election.region.rbc,
                status: election.status,
                numberOfVacancies: election.numberOfVacancies,
                tellers: election.tellers as AppointedTeller[],
                bypassFlow: election.bypassFlow
            }))
            .sort((a, b) => a.name.localeCompare(b.name))

    const subElectionViewModels = useMemo(() =>
        rbcElectionPeriod ? electionsTableData(rbcElectionPeriod) : [],
        [rbcElectionPeriod]
    )

    const electionsStatus = useMemo(() =>
        getRbcStatus(subElectionViewModels || []),
        [subElectionViewModels]
    )

    useEffect(() => {
        setPageHeader(
            <PageHeader
                sticky
                greeting={<BackToDashboardBtn />} />
        )

        return () => { setPageHeader(null) }
    }, [setPageHeader])

    useEffect(() => {
        if (rbcElectionPeriod && !rbcElectionPeriod.elections.length) {
            navigate('/page-not-found', { replace: true })
        }
        if (rbcElectionPeriod?.elections?.length) {
            setStatistic(() => ({ confirmed: 0, inProgress: 0 }))
            rbcElectionPeriod.elections.forEach(e => {
                if (e.status === ElectionStatusType.IN_PROGRESS) {
                    setStatistic((current) => ({ ...current, inProgress: current.inProgress + 1 }))
                } else if (e.status === ElectionStatusType.COMPLETED) {
                    setStatistic((current) => ({ ...current, confirmed: current.confirmed + 1 }))
                }
            })
        }
    }, [rbcElectionPeriod])

    const downloadStatisticsReport = () => {
        loadReport({
            reportType: ReportType.RbcElectionStatistics,
            reportFormat: ReportFormat.XLSX,
            params: {
                ballotingYear: rbcElectionPeriod?.ballotingYear,
                status: getRbcStatus(rbcElectionPeriod?.elections || []) !== ElectionStatusType.COMPLETED 
                    ? 'ACTIVE' : 'FROZEN'
            }
        })
    }

    useEffect(() => {
        handleEntityLoadingError(error)
    }, [error])

    return (
        <>
            {isReportLoading && <Preloader title={t('working_on_your_report')} />}
            <SubElectionsList
                electionsStatus={electionsStatus}
                electionType={ElectionType.RBC_ELECTION}
                elections={subElectionViewModels}
                tableWording={tableWording}
                canDownloadReport={true}
                electionPeriodBallotingYear={rbcElectionPeriod?.ballotingYear}
                allElectionsIds={allElectionsIds}
                statistic={statistic}
                downloadReport={downloadStatisticsReport}
            />
        </>
    )
}

export default RbcElections