import { useAlternativeCandidates } from 'features/reporting/api/queries'
import { useAuth } from 'hooks'
import { Election, ElectionType } from 'models'
import { useTranslation } from 'react-i18next'
import { reportingAssets } from '../../features/reportingAssets'

type AlternativeCandidatesStatisticProps = {
    election: Election
    tiePresent?: boolean
}

const ALTERNATIVE_CANDIDATES_COUNT = 3

function AlternativeCandidatesStatistic({ election, tiePresent }: AlternativeCandidatesStatisticProps) {
    const { t } = useTranslation()
    const { auth } = useAuth()

    const { data: topAlternativeCandidates } = useAlternativeCandidates(
        election.id.toString() || auth!.electionId!,
        election?.type === ElectionType.LOCAL_DELEGATE_ELECTION
        || election?.type === ElectionType.NATIONAL_DELEGATE_ELECTION,
        { limit: ALTERNATIVE_CANDIDATES_COUNT }
    )

    if (!topAlternativeCandidates ||
        topAlternativeCandidates.length === 0 ||
        (election.type !== ElectionType.LOCAL_DELEGATE_ELECTION &&
            election.type !== ElectionType.NATIONAL_DELEGATE_ELECTION)) {
        return <></>
    }

    return (
        <>
            <reportingAssets.components.TitleStatistic
                mainText={t('teller:individuals_receiving_the_next_highest_number_of_votes')}
            />
            <reportingAssets.components.ListOfCandidates
                candidates={topAlternativeCandidates}
                tiePresent={tiePresent}
            />
        </>
    )
}

export default AlternativeCandidatesStatistic