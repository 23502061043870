import { Select as AntSelect, Form } from 'antd'
import { useElections } from 'api'
import { Select } from 'components/atoms/Select'
import { ElectionType } from 'models'
import { ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

type RbcElectionFilterProps = {
    disabled?: boolean
    bahaiYear?: number
}

function RbcElectionFilter({ bahaiYear, disabled }: RbcElectionFilterProps) {
    const { t } = useTranslation()

    const { data: rbcElections } = useElections({ bahaiYear: bahaiYear || 0, type: ElectionType.RBC_ELECTION },
        !!bahaiYear)

    const options = useMemo(() => {
        if (!rbcElections) return <></>

        const sortedElections = [...rbcElections].sort((a, b) => {
            const regionA = a.region.rbc.toLowerCase()
            const regionB = b.region.rbc.toLowerCase()

            return regionA.localeCompare(regionB)
        })

        const institutions: ReactNode[] = sortedElections.map(rbcElection => (
            <AntSelect.Option value={rbcElection.id} key={rbcElection.id}>
                {rbcElection.region.rbc}
            </AntSelect.Option>
        ))

        return <>{institutions}</>
    }, [rbcElections])

    return (
        <Form.Item
            name="election"
            className="no-padding"
            label={t('election:institution')}
            style={{ width: 200 }}
            required
        >
            <Select
                disabled={disabled}
                placeholder={t('select_input', { input: t('election:institution') })}
                className="small"
            >
                {options}
            </Select>
        </Form.Item>
    )
}

export default RbcElectionFilter