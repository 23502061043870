import { useAuth } from 'hooks'
import { Election, ElectionType } from 'models'
import { useTranslation } from 'react-i18next'
import { useByElectionNoLongerServingOfficers } from '../../api/queries'
import { reportingAssets } from '../../features/reportingAssets'

type NoLongerServingOfficersProps = {
    election: Election
}

function NoLongerServingOfficers({ election }: NoLongerServingOfficersProps) {
    const { t } = useTranslation()
    const { auth } = useAuth()

    const { data: noLongerServingOfficers } = useByElectionNoLongerServingOfficers(election.id.toString()
        || auth!.electionId!, !!election.id || !!auth?.electionId)

    if (ElectionType.BY_ELECTION !== election.type) {
        return <></>
    }

    return (
        <>
            <reportingAssets.components.TitleStatistic
                mainText={t('teller:persons_no_longer_serving')} />
            <reportingAssets.components.ListOfCandidates
                candidates={noLongerServingOfficers} />
        </>
    )
}

export default NoLongerServingOfficers