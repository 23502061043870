import { ColumnsType } from 'antd/es/table'
import { useOfficersElectionParticipants, useOnlineVoters } from 'api'
import Badge, { BadgeType } from 'components/atoms/Badge'
import Card from 'components/atoms/Card'
import BackToDashboardLine from 'components/molecules/BackToDashboardLine'
import CardHeader from 'components/molecules/CardHeader'
import Note from 'components/molecules/Note'
import OptionalTooltip from 'components/molecules/OptionalTooltip'
import Table from 'components/molecules/Table'
import { queryClient } from 'config/query-client'
import { useAuth, useBackToDashboardLine, useConfirmPopup, useElectionStatusMap, useLsaOfficerRoleMap } from 'hooks'
import { ElectionParticipant, OfficersElection } from 'models'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useVotingPositionActions } from '../../../../hooks'
import style from './index.module.scss'

type OnlineVotingStatusProps = {
    election: OfficersElection
}

const OnlineVotingStatus = ({ election }: OnlineVotingStatusProps) => {
    const { t } = useTranslation('officers-election')
    const { auth } = useAuth()
    const navigate = useNavigate()
    const { setBackToDashboardLine } = useBackToDashboardLine()
    const { toString } = useLsaOfficerRoleMap()
    const { toString: statusToString } = useElectionStatusMap()
    const { showConfirm, confirmPortal } = useConfirmPopup()

    const { cancelPositionVoting, stopOnlinePositionVoting } = useVotingPositionActions()

    const [sortedParticipants, setSortedParticipants] = useState<ElectionParticipant[]>([])

    const { data: onlineVoters } = useOnlineVoters(election.id.toString(), !!election, 15 * 1000)
    const { data: participants } = useOfficersElectionParticipants(auth?.homeLocality?.code || '', !!auth?.homeLocality)

    useEffect(() => {
        if (participants?.length) {
            setSortedParticipants(participants.sort((a, b) => a.name.localeCompare(b.name) || a.id.localeCompare(b.id)))
        }
    }, [participants])

    const isVoted = (participant: ElectionParticipant) =>
        !!onlineVoters?.verification?.find(o => o.id.toString() === participant.id)

    const votersInfo = useMemo(() =>
    (
        <div className={style.votingInfo}>
            <div className={style.votingStatus}>{statusToString(election.status)}</div>
            <div className={style.votingStatistic}>
                {t('voted_x_of_y', { x: onlineVoters?.total, y: sortedParticipants?.length })}
            </div>
        </div>
    )
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [sortedParticipants, onlineVoters])

    useEffect(() => {
        setBackToDashboardLine(
            <BackToDashboardLine
                sticky={true}
                fixedWidth={false}
                onBackToDashboardBtnClick={() => { navigate('/') }}
                backButtonTitle={t('back_to_dashboard')}
            />
        )

        return () => { setBackToDashboardLine(null) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const columns: ColumnsType<ElectionParticipant> = [
        {
            title: t('voters'),
            width: 444,
            render: (participant: ElectionParticipant) => <div style={{ maxWidth: 404 }}>
                <OptionalTooltip
                    contentWrapperClassName="ellipsis">
                    {participant.name}
                </OptionalTooltip>
            </div>
        },
        {
            title: t('online_voting_status'),
            width: 160,
            render: participant => isVoted(participant) ? t('voted') : String.fromCharCode(8212)
        }
    ]

    const cancelVoting = () => {
        cancelPositionVoting(showConfirm, election.electedPosition,
            election.electionPeriod.toString(), true)
        queryClient.removeQueries(['my-officers-election'])
    }

    return (
        <div className={style.wrapper}>
            {confirmPortal}
            <div className={style.contentWrapper}>
                <Card
                    noContentPadding={true}
                    className={style.cardContainer}
                    title={
                        <CardHeader
                            title={t('voting_status_update')}
                            subTitle={t('for_the_position_of',
                                { positionName: toString(election.electedPosition) })}
                            additionalTitleContent={votersInfo}
                        />
                    }>
                    <div className={style.votingStatusWrapper}>
                        <Note icon={'regular'} mode={'info'}>
                            {t('you_may_refresh_this_browser_page_to_see_realtime_status_update')}
                        </Note>
                        <Table
                            obsSize="small"
                            obsHeaderSize="small"
                            headerDividerVisible={true}
                            dataSource={sortedParticipants}
                            columns={columns}
                            pagination={false}
                            noOuterBorder
                            rootClassName={`${style.votersTable}`}
                            rowKey="id" />
                        <div className={style.votersTableMobile}>
                            {
                                participants?.map(participant =>
                                    <div className={style.participantInfo} key={participant.id}>
                                        {participant.name}

                                        <div className={style.votedLabel}>
                                            {isVoted(participant)
                                                ? <Badge title={t('voted')} type={BadgeType.Success} />
                                                : String.fromCharCode(8212)
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className={style.actionsGroup}>
                            <button className={'btn-main-secondary'}
                                onClick={cancelVoting}>
                                {t('cancel_position_voting')}
                            </button>
                            <button className={'btn-main-primary'}
                                onClick={() => stopOnlinePositionVoting(showConfirm, election.electedPosition,
                                    election.id.toString())}>
                                {t('end_position_voting')}
                            </button>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default OnlineVotingStatus