import Tooltip from 'components/atoms/Tooltip'
import ElectionBadge from 'components/molecules/ElectionBadge'
import ElectionPushBadge from 'components/molecules/ElectionPushBadge'
import OptionalTooltip from 'components/molecules/OptionalTooltip'
import { useCopyToClipboardWithToast } from 'hooks'
import { ElectionStatusType, ElectionType } from 'models'
import { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { dayjsToString } from 'utils'
import { useLsaElectionsData } from '../../hooks'
import ElectionReportCell from '../ElectionReportCell'
import style from './index.module.scss'

type ElectionsItemProps = {
    index: number,
    style?: CSSProperties,
    data: { electionType: ElectionType }
}

function ElectionsItem({ index, style: itemStyle, data }: ElectionsItemProps) {
    const { t } = useTranslation('administration')
    const { lsaElectionsData } = useLsaElectionsData()
    const { copy, contextHolder } = useCopyToClipboardWithToast()

    const election = lsaElectionsData.elections[index]

    return (
        <div className={style.listItem} style={itemStyle}>
            {contextHolder}
            <div style={{
                width: 135
            }}
                className={style.column}>
                <OptionalTooltip contentWrapperClassName="ellipsis">
                    {
                        election.electionStatus === ElectionStatusType.NOT_STARTED
                            && data.electionType !== ElectionType.LOCAL_DELEGATE_ELECTION
                            ? election.localityName
                            : <Link
                                to={data.electionType === ElectionType.LOCAL_DELEGATE_ELECTION
                                    ? `/local-delegate-election-board/${election.electionPeriod}`
                                    : `/election-board/${election.id}`} state={{ backButtonTitle: t('back_to_list') }}>
                                {election.localityName}
                            </Link>
                    }
                </OptionalTooltip>
            </div >
            <div style={{
                width: 130
            }}
                className={style.column}>
                {election.localityCode}
            </div >
            <div style={{
                width: 286
            }}
                className={`${style.column} ${election.contactPersonIdn ? style.pointer : ''}`}
                onClick={() => { if (election.contactPersonIdn) copy(election.contactPersonIdn) }}>
                <OptionalTooltip contentWrapperClassName="ellipsis">
                    {election.contactPersonIdn} {election.contactPerson || String.fromCharCode(8212)}
                </OptionalTooltip>
            </div >

            <div style={{
                width: 110
            }}
                className={style.column}>
                {election.startDay
                    ? dayjsToString(election.startDay, 'MM/DD/YYYY')
                    : String.fromCharCode(8212)}
            </div >
            <div style={{
                width: 116
            }}
                className={style.column}>
                {election.electionDay
                    ? dayjsToString(election.electionDay, 'MM/DD/YYYY')
                    : String.fromCharCode(8212)}
            </div >
            <div style={{
                width: 110
            }}
                className={style.column}>
                <OptionalTooltip contentWrapperClassName="ellipsis">
                    {election.eligibleVoters || String.fromCharCode(8212)}
                </OptionalTooltip>
            </div >
            <div style={{
                width: 110
            }}
                className={style.column}>
                <Tooltip title={
                    <div>
                        {t('participated_voters')}
                        <ul className={style.voters}>
                            <li>{t('online')}:
                                {election.participatedVoters.markedAsInPersonOnline
                                    + election.participatedVoters.markedAsAbsenceOnline}
                            </li>
                            <li>{t('paper_ballots')}: {election.participatedVoters.markedAsAbsencePaper
                                + election.participatedVoters.markedAsInPersonPaper}</li>
                        </ul>
                    </div>
                }>
                    <div className="ellipsis">
                        {election.participatedVoters.markedAsInPersonOnline
                            + election.participatedVoters.markedAsAbsenceOnline}
                        /{election.participatedVoters.markedAsAbsencePaper
                            + election.participatedVoters.markedAsInPersonPaper}
                    </div>
                </Tooltip>
            </div >
            <div style={{
                width: 120
            }}
                className={style.column}>
                <ElectionBadge status={election.electionStatus} />
            </div >
            <div style={{
                width: 120
            }}
                className={style.column}>
                {election.electionPushErrorReason ?
                    <Tooltip title={election.electionPushErrorReason}>
                        <div><ElectionPushBadge status={election.electionPushStatus} /></div>
                    </Tooltip> :
                    <ElectionPushBadge status={election.electionPushStatus} />}
            </div >
            <div style={{
                width: 93
            }}
                className={`${style.column} ${style.centered}`}>
                <ElectionReportCell election={election} electionType={data.electionType} />
            </div >
        </div>
    )
}

export default ElectionsItem