import { useElectionSummary } from 'api'
import IconWrapper from 'components/atoms/IconWrapper'
import Preloader from 'components/molecules/Preloader'
import StatisticCounter from 'components/molecules/StatisticCounter'
import { useAuth, useReportLoader } from 'hooks'
import { ReactComponent as ArrowDownload } from 'icons/arrow-download.svg'
import { ReportFormat, ReportType } from 'models'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { isElectionFrozen } from 'utils'
import { useVotingStatisticTableData } from '../../hooks'
import style from './index.module.scss'

const VotingStatisticTableStatistic = () => {
    const { t } = useTranslation('teller')
    const { tableData } = useVotingStatisticTableData()
    const { electionId } = useParams()
    const { auth } = useAuth()
    const { loadReport, isReportLoading } = useReportLoader()

    const { data: summary, isFetched: isSummaryFetched } = useElectionSummary(electionId || '', !!electionId)


    const downloadList = () => {
        if (isSummaryFetched) {
            loadReport({
                reportType: ReportType.OnlineVotingStatistics,
                reportFormat: ReportFormat.XLSX,
                params: {
                    election: electionId || auth?.electionId,
                    status: isElectionFrozen(summary) ? 'FROZEN' : 'ACTIVE'
                }
            })
        }
    }

    return (
        <>
            {isReportLoading && <Preloader title={t('working_on_your_report')} />}
            <div className={style.headerWrapper}>
                <div className={style.caption}>
                    <div className={style.tableTitle}>
                        {t('election:voting_statistic')}
                    </div>
                    <div className={style.statistic}>
                        {<button className="btn-main-tertiary-md" onClick={downloadList}>
                            <IconWrapper>
                                <ArrowDownload
                                    className="flip-horizontal"
                                    width="16"
                                    height="16" />
                            </IconWrapper>
                            {t('download_voting_statistics')}
                        </button>
                        }
                        {tableData && <div className={style.statistic}>
                            <StatisticCounter value={tableData?.summary?.eligibleToVote!}
                                title={t('total_number_of_eligible_to_vote')} />
                            <StatisticCounter value={tableData?.summary?.votedOnlineInAbsence! +
                                tableData?.summary?.votedOnlineInPerson!}
                                title={t('total_number_of_online_ballots')} />
                            <StatisticCounter value={tableData?.summary?.votedPaperInAbsence! +
                                tableData?.summary?.votedPaperInPerson!}
                                title={t('total_number_of_paper_ballots')} />
                            <StatisticCounter value={tableData?.summary?.totalVoted!}
                                title={t('total_number_casted_ballots')} />
                        </div>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default VotingStatisticTableStatistic