import { useElectedCandidates, useElection, useElectionSummary, useTieDesignatedCandidates } from 'api'
import TextArea from 'components/atoms/TextArea'
import PopupButtons from 'components/molecules/PopupButtons'
import { useAuth } from 'hooks'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isElectionFrozen } from 'utils'
import {
    useDesignateCandidates,
    useRevokeDesignatedCandidates,
    useStartBreakTheTieRound
} from '../../../../api'
import { useMergeResultsData } from '../../hooks'
import TieErrorBlock from '../TieErrorBlock'
import TieTable from '../TieTable'
import style from './index.module.scss'

type TieVotingProps = {
    onClose?: () => void
    isDesignateElectee?: boolean
}

function TieVoting({
    onClose,
    isDesignateElectee
}: TieVotingProps) {
    const { t } = useTranslation('teller')
    const { auth } = useAuth()
    const [showError, setShowError] = useState(false)
    const [sendInvitation, setSendInvitation] = useState(true)

    const { mergedResultsData, setMergedResultsData } = useMergeResultsData()

    const { mutateAsync: designateCandidates } = useDesignateCandidates()
    const { mutateAsync: revokeDesignatedCandidates } = useRevokeDesignatedCandidates()
    const { mutateAsync: startBreakTheTieRound } = useStartBreakTheTieRound()

    const { data: election } = useElection(auth?.electionId?.toString() || '', !!auth?.electionId)
    const { data: summary, isFetched: isSummaryFetched } =
        useElectionSummary(auth?.electionId?.toString() || '', !!auth?.electionId)
    const { data: tieDesignatedCandidates } =
        useTieDesignatedCandidates(auth?.electionId?.toString() || '', isElectionFrozen(summary), isSummaryFetched)
    const { data: electedCandidates } =
        useElectedCandidates(auth?.electionId?.toString() || '', isElectionFrozen(summary), isSummaryFetched)

    const initialSelectedCandidateIds = useMemo(() => {
        const ids = new Set(tieDesignatedCandidates?.map(candidate => candidate.id))

        return mergedResultsData.electionResultTie?.candidates?.filter(candidate =>
            ids.has(candidate.id)).map(candidate => candidate.id) || []
    }, [tieDesignatedCandidates, mergedResultsData.electionResultTie])


    const numberOfVacancies = election?.numberOfVacancies || 0
    const candidatesCount = electedCandidates?.length || 0
    const revoteTiePositions = Math.max(0, numberOfVacancies - candidatesCount)
    const designateTiePositions = Math.max(0, numberOfVacancies - candidatesCount + tieDesignatedCandidates?.length!)
    const initialComment = mergedResultsData.electionResultTie?.resolution.comment || ''

    const [selectedCandidates, setSelectedCandidates] = useState<string[]>(initialSelectedCandidateIds)
    const [comment, setComment] = useState(initialComment)

    useEffect(() => {
        setSelectedCandidates(initialSelectedCandidateIds)
    }, [initialSelectedCandidateIds])

    const handleCandidateSelect = (candidateId: string, isChecked: boolean) => {
        setSelectedCandidates(prevSelected => {
            const newSelected = isChecked
                ? [...prevSelected, candidateId]
                : prevSelected.filter(id => id !== candidateId)

            setShowError(newSelected.length > designateTiePositions!)

            return newSelected
        })
    }

    const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setComment(e.target.value)
    }

    const handleCancel = () => {
        onClose?.()
    }

    const proceedDesignateElecteeObs = async () => {
        await revokeDesignatedCandidates({ electionId: auth?.electionId! })
        if (selectedCandidates.length !== 0) {
            await designateCandidates({
                electionId: auth?.electionId!,
                candidates: selectedCandidates,
                comment: comment
            })
        }
        onClose?.()
    }

    const proceedRevoteUsingObs = async () => {
        await startBreakTheTieRound({ electionId: auth?.electionId!, invitation: sendInvitation })
        setMergedResultsData(prevData => ({
            ...prevData,
            waitingForOnlineVotingStart: true
        }))
        onClose?.()
    }

    return (
        <>
            {showError && <TieErrorBlock />}
            <TieTable
                onSendInvitationChange={setSendInvitation}
                electedCandidates={electedCandidates}
                tieDesignatedCandidates={tieDesignatedCandidates}
                isDesignateElectee={isDesignateElectee}
                onCandidateSelect={handleCandidateSelect}
                selectedCandidates={selectedCandidates}
                tiePositions={revoteTiePositions}
            />
            {isDesignateElectee &&
                <>
                    <div className={style.comment}>{t('comment')}:</div>
                    <div className={style.textAreaWrapper}>
                        <TextArea
                            value={comment}
                            onChange={handleCommentChange}
                            placeholder={t('results:add_a_comment')}
                            showCount={true}
                            maxLength={1000}
                            autoSize={{ minRows: 3, maxRows: 8 }}
                            disabled={!selectedCandidates.length}
                        />
                    </div>
                </>}
            <PopupButtons
                cancelButtonName={t('cancel')}
                proccedButtonName={t('confirm')}
                onCancel={handleCancel}
                onProceed={isDesignateElectee ? proceedDesignateElecteeObs : proceedRevoteUsingObs}
                proccedDisabled={isDesignateElectee && selectedCandidates.length > designateTiePositions!}
            />
        </>
    )
}

export default TieVoting