import { useElection } from 'api'
import IconWrapper from 'components/atoms/IconWrapper'
import Preloader from 'components/molecules/Preloader'
import StatisticCounter from 'components/molecules/StatisticCounter'
import { useAuth, useReportLoader } from 'hooks'
import { ReactComponent as ArrowDownload } from 'icons/arrow-download.svg'
import { ElectionStatusType, ReportFormat, ReportType } from 'models'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useEligibleIndividualsTableData } from '../../hooks'
import style from './index.module.scss'

const EligibleIndividualsTableStatistic = () => {
    const { t } = useTranslation('election')
    const { tableData } = useEligibleIndividualsTableData()
    const { electionId } = useParams()
    const { auth } = useAuth()
    const { loadReport, isReportLoading } = useReportLoader()
    const { data: election } = useElection(electionId || '', !!electionId)

    const downloadList = () => {
        loadReport({
            reportType: ReportType.EligibleIndividuals,
            reportFormat: ReportFormat.XLSX,
            params: {
                election: electionId || auth?.electionId,
                status: election?.status === ElectionStatusType.COMPLETED ? 'FROZEN' : 'ACTIVE'
            }
        })
    }

    return (
        <>
            {isReportLoading && <Preloader title={t('working_on_your_report')} />}
            <div className={style.headerWrapper}>
                <div className={style.caption}>
                    <div className={style.tableTitles}>
                        <div className={style.tableTitle}>
                            {t('eligible_individuals')}
                        </div>
                        <div className={style.tableSubtitle}>
                            <>
                                <div>{t('all_updates_are_automatically_pulled_from_the_eMembership2')}</div>
                                <div>{t('data_won_t_be_updated_once_the_tellers_report_is_signed')}</div>
                            </>
                        </div>
                    </div>
                    <div className={style.statistic}>
                        {<button className="btn-main-tertiary-md" onClick={downloadList}>
                            <IconWrapper>
                                <ArrowDownload
                                    className="flip-horizontal"
                                    width="16"
                                    height="16" />
                            </IconWrapper>
                            {t('download_the_list_of_participants')}
                        </button>
                        }
                        {tableData && <div className={style.statistic}>
                            <StatisticCounter value={tableData?.statistics?.totalCount!}
                                title={t('election:total_number_of_individuals')} />
                            <StatisticCounter value={tableData?.statistics?.candidateCount!}
                                title={t('election:eligible_to_serve')} />
                            <StatisticCounter value={tableData?.statistics?.voterCount!}
                                title={t('election:eligible_to_vote')}
                            />
                            <StatisticCounter value={tableData?.statistics?.votedOnlineCount!}
                                title={t('election:voted_online')}
                            />

                        </div>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default EligibleIndividualsTableStatistic