import { useElection } from 'api'
import Collapse, { CollapseProps } from 'components/atoms/Collapse'
import { useAuth } from 'hooks'
import { useRef } from 'react'
import { useParams } from 'react-router-dom'
import ConsolidatedBallotEntryStatistic from '../ConsolidatedBallotEntryStatistic'
import ConsolidateBallotEntryTable from '../ConsolidatedBallotEntryTable'

function ConsolidatedBallotEntry({ collapsed = false, scrollToBottomOnExpand = false }) {
    const { auth } = useAuth()
    const { electionId } = useParams()
    const { data: election } = useElection(electionId || auth!.electionId!, !!electionId || !!auth?.electionId)
    const content = useRef<HTMLDivElement | null>(null)

    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: <ConsolidatedBallotEntryStatistic collapsed />,
            children: <ConsolidateBallotEntryTable election={election!} collapsed />
        }
    ]

    return (
        <div ref={content}>
            {!!election &&
                (collapsed ?
                    <div className="collapse-like-card">
                        <Collapse items={items} onChange={(key) => {
                            if (!!key && scrollToBottomOnExpand) {
                                setTimeout(() => {
                                    content.current?.scrollIntoView({ behavior: 'smooth' })
                                }, 200)
                            }
                        }
                        } />
                    </div>
                    : <ConsolidateBallotEntryTable election={election}
                        statistic={<ConsolidatedBallotEntryStatistic />} />
                )
            }
        </div>
    )
}

export default ConsolidatedBallotEntry