import { Form } from 'antd'
import { useAppointedTellers, useElection, useElectionSummary } from 'api'
import IconWrapper from 'components/atoms/IconWrapper'
import PageHeader from 'components/atoms/PageHeader'
import { queryClient } from 'config/query-client'
import { useConfirmPopup, useHandleEntityLoadingError, useHeaderLogoRedirect, usePageHeader } from 'hooks'
import { ReactComponent as ChevronBold } from 'icons/chevron-bold.svg'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { isFormValid } from 'utils'
import { useManageTellersActions } from '../../hooks'
import ManageElectionTellersForm from '../ManageElectionTellersForm'
import style from './index.module.scss'

function ElectionTellersManagement() {
    const [form] = Form.useForm()
    const { t } = useTranslation('election')
    const navigate = useNavigate()
    const { handleEntityLoadingError } = useHandleEntityLoadingError()
    const { showConfirm, confirmPortal } = useConfirmPopup()
    const { setPageHeader } = usePageHeader()
    const { electionId } = useParams()
    const { data: election, isFetching: electionIsFetching, error } = useElection(electionId!)
    const { data: summary } = useElectionSummary(electionId!, !!electionId!)
    const { data: appointedTellers, isFetching: appointedTellersIsFetching } = useAppointedTellers(electionId!)
    const { manageTellers } = useManageTellersActions()
    const { setConfirmationCallback } = useHeaderLogoRedirect()

    const location = useLocation()

    const goToDasboard = () => {
        showConfirm({
            title: t('common:leave_current_page'),
            text: t('common:would_you_like_to_leave_this_page_without_saving'),
            okText: <>{t('common:leave')}</>,
            cancelText: <>{t('common:continue_editing')}</>,
            onOk: async () => location.state?.backButtonTitle ? navigate(-1) : navigate('/')
        })
    }

    useEffect(() => {
        if (!!election?.bypassFlow || !!summary?.mergedBy) {
            navigate('/access-denied', { replace: true })
        }
    }, [election, summary])

    useEffect(() => {
        setConfirmationCallback(true)

        return () => {
            setConfirmationCallback(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        handleEntityLoadingError(error)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    const save = async () => {
        if (await isFormValid({ form })) {
            showConfirm({
                onOk: async () => {
                    await manageTellers({ electionId: electionId!, form })
                    await queryClient.invalidateQueries(['appointed-tellers', electionId])
                    queryClient.removeQueries(['telling-stations', electionId])
                    navigate(-1)
                },
                title: t('common:save_changes'),
                okText: t('common:save_changes'),
                text: t('common:are_you_sure_you_want_to_save_changes_of_the_entity', { entity: t('tellers') })
            })
        }
    }

    useEffect(() => {
        if (!electionIsFetching && !appointedTellersIsFetching
            && election && appointedTellers) {
            setPageHeader(
                <PageHeader
                    greeting={`${appointedTellers.length
                        ? t('manage_tellers')
                        : t('set_up_tellers')}${election?.region?.localUnit
                            ? ': ' + election.region.localUnit
                            : election?.region?.rbc
                                ? ': ' + election.region.rbc
                                : ''
                        }`}
                    subheader={
                        <div className={style.actions}>
                            <button className="btn-main-tertiary-md hasIcon" onClick={goToDasboard}>
                                <IconWrapper>
                                    <ChevronBold className="flip-horizontal" width="16" height="16" />
                                </IconWrapper>
                                <span>{location.state?.backButtonTitle || t('common:back_to_dashboard')}</span>
                            </button>
                            <button className="btn-main-primary-md" onClick={save}>
                                {t('common:save_changes')}
                            </button>
                        </div>
                    }
                />
            )
        }

        return () => setPageHeader(null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [electionIsFetching, appointedTellersIsFetching])

    if (electionIsFetching) {
        return <></>
    }

    return (
        <>
            {confirmPortal}
            <div className={style.formWrapper}>
                <ManageElectionTellersForm form={form} election={election!} />
            </div>
        </>
    )
}

export default ElectionTellersManagement