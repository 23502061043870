import { useElection, useLocality } from 'api'
import { PopupProps } from 'components/molecules/Popup'
import { InfoPopupSettings } from 'hooks'
import { ElectionType } from 'models'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import ConfirmElectionResults from '../components/ConfirmElectionResults'
import ConfirmResultsAndTransmit from '../components/ConfirmResultsAndTransmit'
import style from './style/useConfirmResultsActions.module.scss'
import { useConfirmation } from './useConfirmation'

type ConfirmResultsActionsProps = {
    showPopup: (props: PopupProps) => void,
    hidePopup: () => void,
    showInfo: (settings: InfoPopupSettings) => void,
}

export const useConfirmResultsActions = ({
    showInfo,
    showPopup,
    hidePopup
}: ConfirmResultsActionsProps) => {
    const { t } = useTranslation('results')
    const { electionId } = useParams()
    const { data: election } = useElection(electionId!, !!electionId)
    const { data: locality } = useLocality(election?.region?.locality || '', !!election && !!election?.region?.locality)
    const { candidatesToConfirm } = useConfirmation()
    const dest = t('common:eMembership2')

    const confirmResults = ({ onConfirm }: { onConfirm: () => void }) => {
        if (election?.numberOfVacancies !== candidatesToConfirm.length) {
            showInfo({
                title: t('invalid_results'),
                text: t('select_the_number_of_individuals_equal_the_number_of_election_vacancies_for_your_institution'),
                onOk: () => { }
            })

            return
        }
        switch (election?.type) {
            case ElectionType.RIDVAN_ELECTION:
                showPopup({
                    width: 625,
                    title: <>
                        {t('confirm_election_results_and_transmit')}
                        <div className={style.locality}>
                            {locality?.name} ({election?.region.locality})
                        </div>
                    </>,
                    children: <ConfirmResultsAndTransmit
                        hidePopup={hidePopup}
                        onConfirm={() => {
                            hidePopup()
                            showInfo({
                                title: t('election_results_sent'),
                                text: <>
                                    {t('thank_you_for_confirming_the_election_results')}
                                    <div className={style.subtitle}>
                                        {t('they_were_electronically_forwarded_to_dest', { dest })}
                                        <br />
                                        {t('there_is_no_need_for_manual_entry_into_dest', { dest })}
                                    </div>
                                    <strong>
                                        {t('election_completed_system_access_for_ct_and_act_is_terminated')}
                                    </strong>
                                </>,
                                stayOpenedOnCancel: true,
                                onOk: async () => { onConfirm() }
                            })
                        }} />,
                    footer: null
                })
                break
            case ElectionType.LOCAL_DELEGATE_ELECTION:
                showPopup({
                    title: <>{t('confirm_election_results')}</>,
                    children: <ConfirmElectionResults hidePopup={hidePopup}
                        onConfirm={() => {
                            hidePopup()
                            showInfo({
                                title: t('election_results_confirmed'),
                                text: <>
                                    {t('thank_you_for_confirming_the_election_results')}
                                    <br />
                                    <strong>
                                        {
                                            // eslint-disable-next-line max-len
                                            t('results_must_be_sent_to_dest_after_confirming_the_election_results_for_each_local_unit',
                                                {
                                                    dest: t('common:eMembership2')
                                                }
                                            )}
                                    </strong>
                                </>,
                                stayOpenedOnCancel: true,
                                onOk: async () => { onConfirm() }
                            })
                        }} />,
                    footer: null
                })
                break
            case ElectionType.BY_ELECTION:
                showPopup({
                    title: <>{t('confirm_election_results_and_transmit')}</>,
                    children: <ConfirmElectionResults hidePopup={hidePopup}
                        onConfirm={() => {
                            hidePopup()
                            showInfo({
                                title: t('election_results_sent'),
                                text: <>
                                    {t('thank_you_for_confirming_the_election_results')}
                                    <div className={style.subtitle}>
                                        {t('they_were_electronically_forwarded_to_dest', { dest })}
                                        <br />
                                        {t('there_is_no_need_for_manual_entry_into_dest', { dest })}
                                    </div>
                                    <strong>
                                        {t('election_completed_system_access_for_ct_and_act_is_terminated')}
                                    </strong>
                                </>,
                                stayOpenedOnCancel: true,
                                onOk: async () => { onConfirm() }
                            })
                        }} />,
                    footer: null
                })
                break
            case ElectionType.NATIONAL_DELEGATE_ELECTION:
                showPopup({
                    title: <>{t('confirm_election_results')}</>,
                    children: <ConfirmElectionResults hidePopup={hidePopup}
                        onConfirm={() => {
                            hidePopup()
                            showInfo({
                                title: t('election_results_sent'),
                                text: <>
                                    {t('thank_you_for_confirming_the_election_results')}
                                    <div className={style.subtitle}>
                                        {t('they_were_electronically_forwarded_to_dest', { dest })}
                                        <br />
                                        {t('there_is_no_need_for_manual_entry_into_dest', { dest })}
                                    </div>
                                    <strong>
                                        {t('election_completed_system_access_for_ct_and_act_is_terminated')}
                                    </strong>
                                </>,
                                stayOpenedOnCancel: true,
                                onOk: async () => { onConfirm() }
                            })
                        }} />,
                    footer: null
                })
                break
            case ElectionType.RBC_ELECTION:
                showPopup({
                    title: <>{t('confirm_election_results')}</>,
                    children: <ConfirmElectionResults hidePopup={hidePopup}
                        onConfirm={() => {
                            hidePopup()
                            showInfo({
                                title: t('election_results_confirmed'),
                                text: <>
                                    <div className={style.subtitle}>
                                        <Trans i18nKey={t('thank_you_for_confirming_the_election_results_for',
                                            {
                                                interpolation: { escapeValue: false },
                                                regionName: `<strong>${election?.region.rbc}</strong>`
                                            })}
                                            components={[<strong />]}
                                        />
                                    </div>
                                    <strong>
                                        {t('balloting_year_balloting_for_regional_bahai_councils_will_complete',
                                            { ballotingYear: election?.ballotingYear })}
                                    </strong>
                                </>,
                                stayOpenedOnCancel: true,
                                onOk: async () => { onConfirm() }
                            })
                        }} />,
                    footer: null
                })
        }
    }

    return { confirmResults }
}