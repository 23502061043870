import { useElection, useElectionTellingStations, useRbcElectionsColoring } from 'api'
import Card from 'components/atoms/Card'
import Checkbox from 'components/atoms/Checkbox'
import Note from 'components/molecules/Note'
import { useAuth } from 'hooks'
import { ElectionType } from 'models'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { reportingAssets } from '../../features/reportingAssets'
import ElectionCandidatesStatistic from '../ElectionCandidatesStatistic'
import ElectionStatistic from '../ElectionStatistic'
import style from './index.module.scss'

type ElectionResultProps = {
    onCheckboxChange: (checked: boolean) => void
    isValid: boolean
}

function ElectionResult({ onCheckboxChange, isValid }: ElectionResultProps) {
    const { t } = useTranslation('telling-station')
    const { auth } = useAuth()
    const { electionId } = useParams<{ electionId: string }>()

    const { data: election } = useElection(electionId! || auth?.electionId!, !!electionId || !!auth?.electionId)
    const { data: tellingStations } = useElectionTellingStations(electionId || auth!.electionId!,
        !!electionId || !!auth?.electionId)
    const { data: rbcElectionsColoring } = useRbcElectionsColoring(!!election?.region.rbc)

    const handleCheckboxChange = (bahaiId: string, checked: boolean) => {
        setCheckedStates({ ...checkedStates, [bahaiId]: checked })
        onCheckboxChange(checked)
    }

    const initialCheckboxStates = Array.isArray(auth)
        ? auth.reduce((states, teller) => {
            states[teller.id] = false

            return states
        }, {})
        : {}

    const [checkedStates, setCheckedStates] = useState(initialCheckboxStates)

    const reportTitle = t('teller:tellers_report')
    const isRbcElection = election?.type === ElectionType.RBC_ELECTION

    const signatureAcknowledgement = useMemo(() => {
        if (election) {
            let translationKey = ''
            switch (election.type) {
                case ElectionType.RBC_ELECTION:
                    translationKey = 'teller:rbc_election_signature_acknowledgement'
                    break
                case ElectionType.NATIONAL_DELEGATE_ELECTION:
                    translationKey = 'teller:nde_signature_acknowledgement'
                    break
                case ElectionType.RIDVAN_ELECTION:
                case ElectionType.LOCAL_DELEGATE_ELECTION:
                case ElectionType.BY_ELECTION:
                    translationKey = 'teller:lsa_elections_signature_acknowledgement'
                    break
                default:
                    return 'Add Signature Acknowledgement for this election type'
            }

            return t(translationKey, {
                name: auth!.name, interpolation: { escapeValue: false }, id: auth!.id
            })
        }

        return ''
    }, [election, auth])

    return (
        <>
            {election && <Card noContentPadding={true} noHeaderLine={true} >
                <div className={style.contentWrapper}>
                    <reportingAssets.components.ReportLeftCardBlock
                        electionType={election?.type}
                        rbcElectionsColoring={rbcElectionsColoring}
                        rbcName={election?.region.rbc}
                        title={isRbcElection
                            ? reportTitle
                            : `${reportTitle} 
                        (${t('teller:unconfirmed_results')})`}>
                        <reportingAssets.components.ElectionEndYearBlock
                            election={election}
                            rbcElectionsColoring={rbcElectionsColoring}
                        />
                        {election?.type === ElectionType.NATIONAL_DELEGATE_ELECTION &&
                            <div className={style.infoblockWrapper}>
                                <Note mode={'info'} icon={'filled'}>
                                    <div>
                                        {t('teller:results_must_be_confirmed_by')}
                                    </div>
                                </Note>
                            </div>}
                        {!election?.bypassFlow && Array.isArray(tellingStations) && tellingStations.map(station => (
                            <div key={station.id} className={style.stationBlock}>
                                <reportingAssets.components.StationInfo
                                    stationId={station.id}
                                />
                                <div className={style.tellerBlock}>
                                    {station.signers?.map(signer => (
                                        <div key={signer.id} className={style.checkboxBlock}>
                                            <Checkbox disabled checked={station.stationReportAccepted}>
                                                <span className={style.disabledCheckbox}>
                                                    {/* eslint-disable-next-line max-len */}
                                                    {t('i_name_bahaiid_id_confirm_the_telling_data_entered_by_the_station', {
                                                        name: signer.name,
                                                        interpolation: { escapeValue: false },
                                                        id: signer.id
                                                    })}
                                                </span>
                                            </Checkbox>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                        <div className={style.signBlock}>
                            <span className={style.signTitle}>{t('teller:signature_section')}</span>
                            <span className={style.signText}>{t('teller:signature_of_chief_tellers')}
                                <span className={style.asteriskMarkColor}>*</span>
                            </span>
                            <span className={style.checkboxBlock}>
                                <span className={isValid || checkedStates[auth!.id] ? ''
                                    : style.checkboxError}>
                                    <Checkbox onChange={(e) =>
                                        handleCheckboxChange(auth!.id, e.target.checked)}>
                                        <span className={checkedStates[auth!.id]
                                            ? style.checkedCheckboxText
                                            : isValid
                                                ? style.checkboxText
                                                : style.checkboxErrorText}>
                                            {signatureAcknowledgement}
                                        </span>
                                    </Checkbox>
                                </span>
                            </span>
                        </div>
                    </reportingAssets.components.ReportLeftCardBlock>
                    <reportingAssets.components.ReportRightCardBlock>
                        <ElectionStatistic election={election} />
                        <ElectionCandidatesStatistic election={election} />
                    </reportingAssets.components.ReportRightCardBlock>
                </div>
            </Card >
            }
        </>
    )
}

export default ElectionResult