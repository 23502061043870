import EmptyMessage from 'components/atoms/EmptyMessage'
import { ReactComponent as Vote } from 'icons/vote.svg'
import { Election } from 'models'
import { useTranslation } from 'react-i18next'
import { getUniqueElectionPeriods } from '../../utils'
import RbcElectionItem from '../RbcElectionItem'

type CompletedElectionsProps = {
    elections: Election[]
}

const CompletedElections = ({ elections }: CompletedElectionsProps) => {
    const { t } = useTranslation('election')

    const uniqueElectionPeriods = getUniqueElectionPeriods(elections)

    if (uniqueElectionPeriods.length === 0) {
        return (
            <EmptyMessage
                title={t('no_history')}
                description={t('there_are_currently_no_completed_elections')}
            >
                <Vote />
            </EmptyMessage>
        )
    }

    return (
        <>
            {uniqueElectionPeriods.map((period) => (
                <RbcElectionItem key={period}
                    electionPeriod={period}
                />
            ))}
        </>
    )
}

export default CompletedElections