import { useElectionsSummary } from 'api'
import Tooltip from 'components/atoms/Tooltip'
import { ElectionResultSummary, SubElectionViewModel } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { getTellers } from 'utils'
import TellersTooltipContent from '../TellersTooltipContent'
import style from './index.module.scss'

type SubElectionTellersProps = {
    allElectionsIds: string[],
    election: SubElectionViewModel
}

function SubElectionTellers({
    allElectionsIds,
    election
}: SubElectionTellersProps) {
    const { t } = useTranslation('election')

    const { data: electionsSummary } = useElectionsSummary({
        electionIds: allElectionsIds
    })

    const [electionSummary, setElectionSummary] = useState<ElectionResultSummary>({
        confirmedBy: '', mergedBy: '', signedBy: '', tiePresent: false,
        comment: '', commentedAt: '', commentedBy: '', breakingTieSkipped: false,
        breakingTieResolved: false, tieResolutionComment: ''
    })

    useEffect(() => {
        if (electionsSummary) {

            const summary = electionsSummary.find(i => i.election === election.id)

            if (summary) {
                setElectionSummary(summary.electionResults)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [electionsSummary])

    const getTellersToRender = () => {
        const team = getTellers(election)
        const firstTeller = team.chief || team.assistants?.[0] || team.tellers?.[0]

        const tellers = <Tooltip title={<TellersTooltipContent team={team} includeChief />}>
            <div className={style.other} style={{ maxWidth: '230px' }}>
                <div className="ellipsis">
                    {firstTeller!.name}
                </div>

                {(election.tellers!.length > 1) &&
                    <div className={style.andMore}>{`${'\u00A0'}${t('common:and')}`}
                        <span className={style.more}>
                            {`${'\u00A0'}${t('common:count_more',
                                { count: election.tellers!.length - 1 })}`}
                        </span>
                    </div>}
            </div>
        </Tooltip>

        if (!!electionSummary.mergedBy) {
            return <>{tellers}</>
        } else {
            return <Link to={`/election-team/${election.id}`} state={{ backButtonTitle: t('back_to_list') }}>
                {tellers}
            </Link>
        }
    }

    return (
        <>
            {
                election?.tellers?.length
                    ? getTellersToRender()
                    : <Link
                        className={style.noTellers}
                        to={`/election-team/${election.id}`}
                        state={{ backButtonTitle: t('back_to_list') }}
                    >
                        {t('set_up_tellers')}
                    </Link>
            }

        </>
    )
}

export default SubElectionTellers