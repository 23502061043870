import { Select as AntSelect, Col, Form, FormInstance } from 'antd'
import { useElection } from 'api'
import { Select } from 'components/atoms/Select'
import dayjs from 'dayjs'
import { ElectionStatusType, ElectionType, RidvanElectionPeriod } from 'models'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isFormValid, toDayjs } from 'utils'
import ElectionEditingProcess from '../../../../components/ElectionEditingProcess'
import ManageElectionTellersForm from '../../../../components/ManageElectionTellersForm'
import {
    useExtractSetUpElectionSchedule,
    useManageTellersActions,
    useSetUpElectionActions
} from '../../../../hooks'
import { SetUpElectionStepConfig, SetUpTellersStepConfig } from '../../../../models'
import {
    useAnnounceRidvanElectionPeriod, useSetUpRidvanSchedule
} from '../../api'
import { isRidvanSecondStage } from '../../utils'
import ElectedLocalDelegates from '../ElectedLocalDelegates'
import VerifyLocalDelegateParticipants from '../VerifyLocalDelegateParticipants'
import VerifyRidvanParticipants from '../VerifyRidvanParticipants'

const numberOfVacancies = 9

type RidvanEditingProcessProps = {
    ridvanElectionPeriod: RidvanElectionPeriod
}

function RidvanEditingProcess({ ridvanElectionPeriod }: RidvanEditingProcessProps) {
    const { t } = useTranslation('election')

    const [setUpElectionForm, setSetUpElectionForm] = useState<FormInstance<any>>()
    const [manageTellersForm, setManageTellersForm] = useState<FormInstance<any>>()

    const { extractSchedule } = useExtractSetUpElectionSchedule()
    const { saveSetUpElectionForm } = useSetUpElectionActions()
    const { manageTellers } = useManageTellersActions()

    const { mutateAsync: setUpSchedule } = useSetUpRidvanSchedule()
    const { mutateAsync: announceRidvanElectionPeriod } = useAnnounceRidvanElectionPeriod()
    const { data: election } = useElection(ridvanElectionPeriod.ridvanElectionStage.election.id.toString())

    const setUpElectionFormInitial = {
        ballotingYear: t('year_b_e_period',
            {
                year: ridvanElectionPeriod.ballotingYear,
                period: `${dayjs().year()}-${dayjs().year() + 1}`
            }),
        numberOfVacancies,
        timeZone: ridvanElectionPeriod.ridvanElectionStage.election.timeZone,
        electionDay: ridvanElectionPeriod.ridvanElectionStage.election.electionDay ?
            toDayjs(ridvanElectionPeriod.ridvanElectionStage.election.electionDay)
            : undefined,
        onlineVotingStart: ridvanElectionPeriod.ridvanElectionStage.election.onlineVotingStart ?
            toDayjs(ridvanElectionPeriod.ridvanElectionStage.election.onlineVotingStart)
            : undefined
    }

    const saveRidvanSetUpForm = async () => {
        if (setUpElectionForm) {
            const electionSchedule = extractSchedule(setUpElectionForm)
            await setUpSchedule({
                schedule: electionSchedule,
                electionPeriod: ridvanElectionPeriod.id.toString(),
                type: ElectionType.RIDVAN_ELECTION
            })
        }
    }

    const getVotingStartDate = () => {
        if (setUpElectionForm) {
            const formValue = setUpElectionForm.getFieldValue('onlineVotingStart')
            if (formValue)
                return formValue
            if (ridvanElectionPeriod && ridvanElectionPeriod.ridvanElectionStage.election.onlineVotingStart) {
                return dayjs(new Date(ridvanElectionPeriod.ridvanElectionStage.election.onlineVotingStart))
            }
        }

        return null
    }

    const isSetupTellersStepReady = async () => {
        if (!manageTellersForm) {
            return false
        }
        const tellersValid = await isFormValid({ form: manageTellersForm })
        if (!tellersValid) {
            return false
        }

        //TODO: Not clear why we check it onComplete cause we've already done it in init useEffect
        //TODO: Remove it if no bug reported
        // if (election?.status === ElectionStatusType.DRAFT
        //     || election?.status === ElectionStatusType.NOT_STARTED) {
        //     if (await checkIfStartDateExpired(getVotingStartDate(), () => { setInitStep(0) })) {
        //         return Promise.resolve(false)
        //     }
        // }


        return true
    }

    const completeElectionSetup = async () => {
        if (manageTellersForm) {
            await manageTellers({
                form: manageTellersForm,
                electionId: ridvanElectionPeriod.ridvanElectionStage.election.id.toString()
            })
            await announceRidvanElectionPeriod({
                type: ElectionType.RIDVAN_ELECTION,
                electionPeriod: ridvanElectionPeriod.id.toString()
            })
        }
    }

    const saveElectionAsDraftCb = async (currentStepIndex: number) => {
        switch (currentStepIndex) {
            case 0:
                if (setUpElectionForm) {
                    return await saveSetUpElectionForm({
                        form: setUpElectionForm,
                        saveFunc: saveRidvanSetUpForm
                    })
                }
                break
            case 2:
                if (manageTellersForm) {
                    await manageTellers({
                        form: manageTellersForm,
                        electionId: ridvanElectionPeriod.ridvanElectionStage.election.id.toString()
                    })
                }

                return Promise.resolve(true)
        }


        return Promise.resolve(true)
    }

    const setUpElectionStepConfig: SetUpElectionStepConfig = {
        saveSetUpForm: saveRidvanSetUpForm,
        formInitialValue: setUpElectionFormInitial,
        extraContent: <Form.Item
            name="numberOfVacancies"
            label={<>
                {t('number_of_vacancies')}
            </>}>
            <Select
                disabled={true}
                placeholder={t('number_of_vacancies')}>
                <AntSelect.Option value={numberOfVacancies} >
                    {numberOfVacancies}
                </AntSelect.Option>
            </Select>
        </Form.Item>,
        secondColumn: <>
            {
                isRidvanSecondStage(ridvanElectionPeriod)
                && <Col span={12}>
                    <ElectedLocalDelegates ridvanElectionPeriod={ridvanElectionPeriod} />
                </Col>
            }
        </>
    }

    const setUpTellersStepConfig: SetUpTellersStepConfig = {
        isStepReady: isSetupTellersStepReady,
        completeElectionSetup,
        content: <> {manageTellersForm && <ManageElectionTellersForm
            form={manageTellersForm} canSaveWithoutMandatoryTellers
            election={election!} />
        }</>
    }

    return (
        <>
            {election && <ElectionEditingProcess
                electionStatus={election.status}
                announced={ridvanElectionPeriod.ridvanElectionStage.election.status !== ElectionStatusType.DRAFT}
                ballotingYear={ridvanElectionPeriod.ballotingYear}
                electionType={ElectionType.RIDVAN_ELECTION}
                getVotingStartDate={getVotingStartDate}
                periodId={ridvanElectionPeriod.id}
                initSetUpElectionForm={setSetUpElectionForm}
                initManageTellersForm={setManageTellersForm}
                isSecondStage={isRidvanSecondStage(ridvanElectionPeriod)}
                saveElectionAsDraftCb={saveElectionAsDraftCb}
                setUpElectionStepConfig={setUpElectionStepConfig}
                verifyParticipantsContent={isRidvanSecondStage(ridvanElectionPeriod)
                    ? <VerifyLocalDelegateParticipants electionPeriodId={ridvanElectionPeriod.id.toString()}
                        isRidvanSecondStage
                        localUnits={ridvanElectionPeriod
                            .localDelegateElectionStage!.elections.map(e => e.localUnitCode)} />
                    : <VerifyRidvanParticipants
                        electionPeriodId={ridvanElectionPeriod.id.toString()} />}
                setUpTellersStepConfig={setUpTellersStepConfig}
            />
            }
        </>
    )
}

export default RidvanEditingProcess