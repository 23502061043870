import { Select as AntSelect, Col, Form, FormInstance, Row } from 'antd'
import { RangePickerProps } from 'antd/es/date-picker'
import { Store } from 'antd/es/form/interface'
import { useElectionRestrictionsOld } from 'api'
import Card from 'components/atoms/Card'
import DatePicker from 'components/atoms/DatePicker'
import Input from 'components/atoms/Input'
import { Select } from 'components/atoms/Select'
import CardHeader from 'components/molecules/CardHeader'
import Note from 'components/molecules/Note'
import dayjs from 'dayjs'

import { ElectionType, Restriction, TimeZone } from 'models'
import { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getElectionRestrictions, toDayjs } from 'utils'
import { useTimeZones } from '../../api/queries'
import { useTimeZoneMap } from '../../hooks'
import { compareTimeZones } from '../../utils'

const minElectionDurationDays = 0

type SetUpElectionFormProps = {
    form: FormInstance
    initialValues?: Store
    children?: ReactNode
    electionType: ElectionType
    electionDateLabel?: string
    importantText: ReactNode
    onOnlineVotingStartDateChange?: () => void
    electionDayPropName?: string
    onOnlineVotingEndDateChange?: () => void
    electionDateValidationLabel?: string
}

function SetUpElectionForm({ form,
    initialValues,
    children,
    electionType,
    importantText,
    electionDateLabel,
    onOnlineVotingStartDateChange,
    onOnlineVotingEndDateChange,
    electionDateValidationLabel,
    electionDayPropName }: SetUpElectionFormProps) {
    const { t } = useTranslation('election')
    const { toString } = useTimeZoneMap()
    const [restriction, setRestriction] = useState<Restriction | undefined>()
    const [isOnlineVotingStartDisabled, setIsOnlineVotingStartDisabled] = useState(false)
    const [isElectionDayDisabled, setIsElectionDayDisabled] = useState(false)
    const [availableTimeZones, setAvailableTimeZones] = useState<TimeZone[]>([])
    const { data: restrictions } = useElectionRestrictionsOld()
    const { data: timeZones } = useTimeZones()

    const electionDayProp = electionDayPropName || 'electionDay'

    useEffect(() => {
        if (restrictions) {
            const restriction = getElectionRestrictions(electionType, restrictions)
            setRestriction(restriction || undefined)

            if (restriction) {
                const now = dayjs()
                const startRangeMin = dayjs(restriction.onlineVotingStartRangeMin, 'YYYY-MM-DD')
                const startRangeMax = dayjs(restriction.onlineVotingStartRangeMax, 'YYYY-MM-DD')
                const electionDateFrom = dayjs(restriction.electionDateRangeFrom, 'YYYY-MM-DD')
                const electionDateTo = dayjs(restriction.electionDateRangeTo, 'YYYY-MM-DD')

                if (startRangeMin.isSame(startRangeMax) && (startRangeMin.isSame(now, 'day') ||
                    startRangeMin.isAfter(now, 'day'))) {
                    form.setFieldsValue({ onlineVotingStart: startRangeMin })
                    setIsOnlineVotingStartDisabled(true)
                } else {
                    setIsOnlineVotingStartDisabled(false)
                }

                if (electionDateFrom.isSame(electionDateTo) && electionDateFrom.isAfter(now, 'day')) {
                    const formFieldValue = {} as any
                    formFieldValue[electionDayProp] = electionDateFrom
                    form.setFieldsValue(formFieldValue)
                    setIsElectionDayDisabled(true)
                } else {
                    setIsElectionDayDisabled(false)
                }

                if (timeZones && timeZones.length > 0) {
                    const sortedTimeZones = timeZones.sort(compareTimeZones)
                    setAvailableTimeZones(sortedTimeZones)
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restrictions, timeZones])

    const onlineVotingStartDisabledDate: RangePickerProps['disabledDate'] = (current) => {
        if (restriction?.onlineVotingStartRangeMin) {
            const onlineVotingStartRangeMin = dayjs(restriction.onlineVotingStartRangeMin, 'YYYY-MM-DD')
            if (onlineVotingStartRangeMin.isValid() &&
                onlineVotingStartRangeMin.endOf('day').diff(current.endOf('day'), 'days') > 0) {
                return true
            }
        }

        if (restriction?.onlineVotingStartRangeMax) {
            const onlineVotingStartRangeMax = dayjs(restriction.onlineVotingStartRangeMax, 'YYYY-MM-DD')
            if (onlineVotingStartRangeMax.isValid() &&
                onlineVotingStartRangeMax.endOf('day').diff(current.endOf('day'), 'days') < 0) {
                return true
            }
        }

        const isInPast = current && current.endOf('day').diff(dayjs().endOf('day'), 'days') < 0
        const electionDay = form.getFieldValue(electionDayProp)
        if (electionDay) {
            const afterElectionDay = current && (
                electionDay.endOf('day').diff(current.endOf('day'), 'days') < minElectionDurationDays)

            return isInPast || afterElectionDay
        }

        return isInPast
    }

    const electionDayDisabledDate: RangePickerProps['disabledDate'] = (current) => {
        if (restriction?.electionDateRangeFrom) {
            const electionDateRangeFrom = dayjs(restriction.electionDateRangeFrom, 'YYYY-MM-DD')
            if (electionDateRangeFrom.isValid() &&
                current.endOf('day').isBefore(electionDateRangeFrom.startOf('day'))) {
                return true
            }
        }

        if (restriction?.electionDateRangeTo) {
            const electionDateRangeTo = dayjs(restriction.electionDateRangeTo, 'YYYY-MM-DD')
            if (electionDateRangeTo.isValid() &&
                current.startOf('day').isAfter(electionDateRangeTo.endOf('day'))) {
                return true
            }
        }

        const isInPast = current && current.endOf('day').diff(dayjs().endOf('day'), 'days') < 0
        const onlineVotingStart = form.getFieldValue('onlineVotingStart')
        if (onlineVotingStart) {
            const isBeforeOnlineStartDate =
                current.endOf('day').diff(onlineVotingStart.endOf('day'), 'days') < minElectionDurationDays

            return isInPast || isBeforeOnlineStartDate
        }

        return isInPast
    }

    const onOnlineVotingStartChange = () => {
        if (!isElectionDayDisabled) {
            form.setFieldValue(electionDayProp, null)
            onOnlineVotingStartDateChange?.()
        }
    }

    return (
        <Card title={<CardHeader title={t('set_up_election')} />} >
            <Form
                form={form}
                layout="vertical"
                initialValues={initialValues}>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            name="ballotingYear"
                            label={t('balloting_year')}
                            validateTrigger={['onBlur', 'onChange']}
                        >
                            <Input
                                disabled={true}
                                placeholder={t('balloting_year')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="timeZone"
                            label={t('time_zone')}
                            rules={[{
                                required: true,
                                message: t('please_select_the_input', { input: t('time_zone') })
                            }]}
                        >
                            <Select
                                placeholder={t('select_input', { input: t('time_zone') })}
                            >

                                {availableTimeZones.map((timeZone, index) =>
                                    <AntSelect.Option value={timeZone} key={index} >
                                        {toString(timeZone)}
                                    </AntSelect.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            name="onlineVotingStart"
                            label={t('online_voting_starts_automatically')}
                            rules={[{
                                required: true,
                                message: t('please_select_the_input', { input: t('online_voting_starts_date') })
                            },
                            {
                                message: t('please_select_either_the_current_date_or_the_future_date'),
                                validator: (_, value) => {
                                    if (value) {
                                        const dayjsValue = toDayjs(value)

                                        return onlineVotingStartDisabledDate(dayjsValue, {
                                            type: 'date'
                                        })
                                            ? Promise.reject()
                                            : Promise.resolve()
                                    }

                                    return Promise.resolve()
                                }
                            }]}
                            validateTrigger={['onBlur', 'onChange']}
                            validateDebounce={200}
                        >
                            <DatePicker
                                onChange={onOnlineVotingStartChange}
                                placeholder={t('select_input', { input: t('date') })}
                                inputReadOnly={true}
                                disabledDate={onlineVotingStartDisabledDate}
                                disabled={isOnlineVotingStartDisabled}
                                format="MM/DD/YYYY"
                                style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={electionDayProp}
                            label={
                                electionDateLabel || `${t('election_date')} (${t('day_votes_counted')})`}
                            rules={[{
                                required: true,
                                message: t('please_select_the_input',
                                    { input: electionDateValidationLabel || t('election_date') })
                            }]}
                            validateTrigger={['onBlur', 'onChange']}
                            validateDebounce={200}
                        >
                            <DatePicker
                                onChange={onOnlineVotingEndDateChange}
                                placeholder={t('select_input', { input: t('date') })}
                                disabledDate={electionDayDisabledDate}
                                disabled={isElectionDayDisabled}
                                inputReadOnly={true}
                                format="MM/DD/YYYY"
                                style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>
                {children}
            </Form>
            {importantText && <Note title={t('important')} icon={'regular'} mode={'info'}>
                {importantText}
            </Note>
            }
        </ Card >
    )
}

export default SetUpElectionForm