import { useAbility, useCopyToClipboardWithToast } from 'hooks'
import { AppointedTeam } from 'models'
import { useTranslation } from 'react-i18next'
import style from './index.module.scss'

type TellersTooltipContentProps = {
    includeChief?: boolean,
    team: AppointedTeam
}

function TellersTooltipContent({ includeChief, team }: TellersTooltipContentProps) {
    const { t } = useTranslation()
    const { copy, contextHolder } = useCopyToClipboardWithToast()
    const { ability } = useAbility()

    let chief = null
    let assistants = null
    let tellers = null

    const canCopy = ability?.can('copy', 'BahaiIdToClipboard')

    if (includeChief && team.chief) {
        chief = <>
            <div>{t('chief_teller') + ':'}</div>
            <ul className={style.tellersList}>
                <li
                    className={`${canCopy ? style.pointer : ''}`}
                    onClick={() => { if (canCopy) copy(team.chief!.id) }}
                >{team.chief.name}</li>
            </ul>
        </>
    }

    if (team.assistants?.length) {
        assistants = <>
            <div>{t('assistant_chief_teller_s') + ':'}</div>
            <ul className={style.tellersList}>
                {team.assistants.map((t, index) => <li
                    className={`${canCopy ? style.pointer : ''}`}
                    onClick={() => { if (canCopy) copy(t.id) }}
                    key={'a' + index}>
                    {t.name}
                </li>)}
            </ul>
        </>
    }
    if (team.tellers?.length) {
        tellers = <>
            <div>{t('tellers') + ':'}</div>
            <ul className={style.tellersList}>
                {team.tellers.map((t, index) => <li
                    className={`${canCopy ? style.pointer : ''}`}
                    onClick={() => { if (canCopy) copy(t.id) }}
                    key={'t' + index}>
                    {t.name}
                </li>)}
            </ul>
        </>
    }

    return <>
        {contextHolder}
        {chief}
        {assistants}
        {tellers}
    </>
}

export default TellersTooltipContent