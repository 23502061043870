import { Can } from 'components/atoms/Can'
import { useTranslation } from 'react-i18next'
import style from './index.module.scss'

type ResolvedTieTextProps = {
    isElectionSigned: boolean
    hasDesignatedCandidates: boolean
    resolutionComment?: string
    peopleElectedViaRevoteRound: string
    peopleDesignatedAsElectee: JSX.Element | null
}

function ResolvedTieText({
    isElectionSigned,
    hasDesignatedCandidates,
    resolutionComment,
    peopleElectedViaRevoteRound,
    peopleDesignatedAsElectee
}: ResolvedTieTextProps) {
    const { t } = useTranslation('teller')

    return (
        <div className={`${style.textWrapper} ${style.successTextColor}`}>
            {t('tie_resolved')} <br />
            <>
                <>
                    {peopleElectedViaRevoteRound &&
                        <span className={style.textFont}>
                            <b>{peopleElectedViaRevoteRound}</b>
                            {` ${t('elected_via_revote_using_obs')}`}
                            {!isElectionSigned && !hasDesignatedCandidates &&
                                ` ${t('please_click_proceed_to_reporting')}.`}<br />
                        </span>
                    }
                </>
                {hasDesignatedCandidates &&
                    <>
                        {peopleDesignatedAsElectee}
                        <span className={style.textFont}>
                            {resolutionComment &&
                                <>
                                    <b>{` ${t('comment')}: `}</b>
                                    {resolutionComment}
                                </>
                            }
                            <br />
                        </span>
                        <Can I="view" a="InfoWording">
                            {hasDesignatedCandidates && !isElectionSigned
                                && <span className={style.textFont}>
                                    {
                                        `${t('if_there_is_an_error_you_may_use_the_change_tie_results')} ${t(
                                            'please_click_proceed_to_reporting')}.`
                                    }
                                </span>
                            }
                        </Can>
                    </>
                }
            </>

        </div>
    )
}

export default ResolvedTieText