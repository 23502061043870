import { useElection } from 'api'
import ContentWrapper from 'components/atoms/ContentWrapper'
import { useAbility, useAuth, useBackToDashboardLine, useHandleEntityLoadingError } from 'hooks'
import { ElectionType } from 'models'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import BackToDashboardLine from '../../../../../components/molecules/BackToDashboardLine'
import EligibleIndividuals from '../components/EligibleIndividuals'

const ViewEligibleIndividuals = () => {
    const { setBackToDashboardLine } = useBackToDashboardLine()
    const navigate = useNavigate()
    const { handleEntityLoadingError } = useHandleEntityLoadingError()
    const { auth } = useAuth()
    const { ability } = useAbility()
    const { electionId } = useParams()
    const { t } = useTranslation('election')
    const { data: election, error } = useElection(electionId || '')
    const goToDashboard = useCallback(() => navigate(-1), [navigate])

    useEffect(() => {
        if (error) {
            handleEntityLoadingError(error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    const isVotingStatisticBtnAvailable = useMemo(() => {
        if (!election) return

        if (
            (election.type === ElectionType.RBC_ELECTION) ||
            (election.type === ElectionType.NATIONAL_DELEGATE_ELECTION)
        ) return true

        return false
    }, [election])

    useEffect(() => {
        setBackToDashboardLine(
            <BackToDashboardLine
                sticky={true}
                fixedWidth={true}
                onBackToDashboardBtnClick={goToDashboard}
                backButtonTitle={ability?.can('redirect', 'ElectionBoard') ? t('back_to_election_board') : undefined}
            >
                <div >
                    {isVotingStatisticBtnAvailable &&
                        <button
                            className="btn-main-tertiary-md"
                            onClick={() => { navigate(`/voting-statistic/${electionId}`) }}
                        >
                            {t('voting_statistic')}
                        </button>
                    }
                </div>
            </BackToDashboardLine >
        )

        return () => { setBackToDashboardLine(null) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setBackToDashboardLine, goToDashboard, auth, electionId, isVotingStatisticBtnAvailable])

    return (
        <ContentWrapper>
            <EligibleIndividuals />
        </ContentWrapper>
    )
}

export default ViewEligibleIndividuals