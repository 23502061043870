
import CardSubheader from 'components/molecules/CardSubheader'
import ElectionBadge from 'components/molecules/ElectionBadge'
import { electionEditing } from 'features/electionEditing'
import { useElectionTypeMap } from 'hooks'
import { ElectionStatusType, ElectionType } from 'models'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { dayjsToString } from 'utils/convertors/date'
import RbcElectionActions from '../RbcElectionActions'
import styles from './index.module.scss'

type RbcElectionItemProps = {
    electionPeriod: number
}

function RbcElectionItem({ electionPeriod }: RbcElectionItemProps) {
    const { t } = useTranslation()
    const { toString } = useElectionTypeMap()

    const { data: rbcElectionPeriod }
        = electionEditing.rbcElection.api.queries.useElectionPeriod(electionPeriod.toString())

    const electionsStatus = useMemo(() => {
        if (rbcElectionPeriod?.elections) {
            return electionEditing.rbcElection.utils.getRbcStatus(rbcElectionPeriod.elections)
        }

        return ElectionStatusType.DRAFT

    }, [rbcElectionPeriod])

    const election = useMemo(() => rbcElectionPeriod?.elections?.[0], [rbcElectionPeriod])

    const cardSubtitle = useMemo(() => {
        if (electionsStatus === ElectionStatusType.NOT_STARTED) {
            return t('election:election_will_be_started_automatically_on_the_online_voting_start_date')
        }

        return ''
    }, [electionsStatus, t])

    if (!election) return null

    return (
        <div className={styles.election}>
            <CardSubheader
                title={t('year_b_e_election', {
                    year: election.ballotingYear,
                    election: toString(ElectionType.RBC_ELECTION)
                })}
                subTitle={cardSubtitle}
                noPadding={true}
                badge={<ElectionBadge status={electionsStatus} />}
            >
                <RbcElectionActions
                    electionPeriod={electionPeriod}
                    electionStatus={electionsStatus}
                />
            </CardSubheader>
            <div className={styles.content}>
                <div className={styles.info}>
                    <div className={styles.item}>
                        <span className={styles.label}>
                            {`${t('voter:online_voting_period')} `}
                        </span>
                        {(election.onlineVotingStart && election.onlineVotingEnd)
                            ? `${dayjsToString(election.onlineVotingStart, 'MM/DD/YYYY')}
                        - ${dayjsToString(election.onlineVotingEnd, 'MM/DD/YYYY')}`
                            : '-'
                        }
                    </div>
                    <div className={styles.item}>
                        <span className={styles.label}>
                            {`${t('election:telling_weekend')}: `}
                        </span>
                        {(election.tellingWeekendStart && election.tellingWeekendEnd)
                            ? `${dayjsToString(election.tellingWeekendStart, 'MM/DD/YYYY')} 
                        - ${dayjsToString(election.tellingWeekendEnd, 'MM/DD/YYYY')}`
                            : '-'
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RbcElectionItem