import { useElection, useElectionParticipantsStatistic } from 'api'
import Card from 'components/atoms/Card'
import StatisticCounter from 'components/molecules/StatisticCounter'
import { useAuth } from 'hooks'
import { ElectionStatusType } from 'models'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import style from './index.module.scss'

function EligibleIndividuals() {
    const { t } = useTranslation('teller')
    const { auth } = useAuth()
    const { electionId } = useParams()
    const { data: election } = useElection(electionId || auth?.electionId || '', !!electionId || !!auth?.electionId)
    const { data: participantsStatistic } =
        useElectionParticipantsStatistic(electionId || auth?.electionId, !!electionId || !!auth?.electionId,
            election?.status === ElectionStatusType.COMPLETED)

    return (
        <Card noHeaderLine={true} noContentPadding={true}>
            <div className={style.content}>
                <div className={style.titleWrapper}>
                    {t('list_of_eligible_individuals')}
                    <Link to={`/eligible-individuals/${electionId || auth?.electionId}`} className={style.link}>
                        {t('view_details')}
                    </Link>
                </div>
                <div className={style.counters}>
                    <StatisticCounter value=
                        {participantsStatistic?.totalCount ?? 0} title={t('total_of_individuals')} />
                    <StatisticCounter
                        value={participantsStatistic?.candidateCount ?? 0} title={t('eligible_to_serve')} />
                    <StatisticCounter value={participantsStatistic?.voterCount ?? 0} title={t('eligible_to_vote')} />
                </div>
            </div>
        </Card>
    )
}

export default EligibleIndividuals