import { Col, Form, FormInstance, Row } from 'antd'
import { useElectionWordingConfiguration } from 'api'
import { IWizardStepProps } from 'components/molecules/Wizard/models'
import DOMPurify from 'dompurify'
import { useConfirmPopup, useElectionTypeMap, useInfoPopup } from 'hooks'
import { ReactComponent as ChevronBold } from 'icons/chevron-bold.svg'
import { ReactComponent as Chevron } from 'icons/chevron.svg'
import { ElectionStatusType, ElectionType } from 'models'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { isToday } from 'utils'
import {
    useCheckIfStartDateExpired,
    useElectionWizardChipsSettings,
    useSaveElectionAsDraft,
    useSetUpElectionActions,
    useVotingStartDateDependentTitles
} from '../../hooks'
import { SetUpElectionStepConfig, SetUpTellersStepConfig } from '../../models'
import ElectionEditingWizard from '../ElectionEditingWizard'
import SetUpElectionForm from '../SetUpElectionForm'

type ElectionEditingProcessProps = {
    electionType: ElectionType
    electionStatus: ElectionStatusType
    isSecondStage?: boolean
    announced: boolean
    ballotingYear: number
    periodId: number
    initSetUpElectionForm: (form: FormInstance<any>) => void
    initManageTellersForm: (form: FormInstance<any>) => void
    getVotingStartDate: () => any
    saveElectionAsDraftCb: (currentStepIndex: number) => Promise<boolean>
    setUpElectionStepConfig: SetUpElectionStepConfig
    setUpTellersStepConfig: SetUpTellersStepConfig
    verifyParticipantsContent: ReactNode
}

function ElectionEditingProcess({
    electionType,
    electionStatus,
    isSecondStage,
    announced,
    ballotingYear,
    periodId,
    initSetUpElectionForm,
    initManageTellersForm,
    getVotingStartDate,
    saveElectionAsDraftCb,
    setUpElectionStepConfig,
    setUpTellersStepConfig,
    verifyParticipantsContent
}: ElectionEditingProcessProps) {
    const { t } = useTranslation('election')
    const { toString } = useElectionTypeMap()
    const navigate = useNavigate()
    const { showConfirm, confirmPortal } = useConfirmPopup()
    const { showInfo, infoPortal } = useInfoPopup()

    const [currentStepIndex, setCurrentStepIndex] = useState(0)
    const [initStep, setInitStep] = useState<number>()

    const [setUpElectionForm] = Form.useForm()
    const [manageTellersForm] = Form.useForm()

    const { getCompleteSetUpButtonText, getLastStepNote } = useVotingStartDateDependentTitles()
    const { checkIfStartDateExpired } = useCheckIfStartDateExpired(showInfo)
    const { saveAsDraft } = useSaveElectionAsDraft()
    const { saveSetUpElectionForm } = useSetUpElectionActions()

    const { setUpElectionChipSettings, verifyParticipantsChipSettings, setUpTellersChipSettings }
        = useElectionWizardChipsSettings()
    const { data: wordingConfig } = useElectionWordingConfiguration(electionType, isSecondStage)

    useEffect(() => {
        switch (electionStatus) {
            case ElectionStatusType.CANCELED:
            case ElectionStatusType.COMPLETED:
                navigate('/', { replace: true })

                return
            case ElectionStatusType.DRAFT:
            case ElectionStatusType.NOT_STARTED:
                let onlineVotingStartDate = setUpElectionForm.getFieldValue('onlineVotingStart')?.format('YYYY-MM-DD')
                if (!onlineVotingStartDate) {
                    onlineVotingStartDate = setUpElectionStepConfig?.formInitialValue
                        .onlineVotingStart?.format('YYYY-MM-DD')
                }
                if (onlineVotingStartDate) {
                    checkIfStartDateExpired(onlineVotingStartDate,
                        () => { setInitStep(0) })
                }
        }
    }, [electionStatus, setUpElectionForm])

    useEffect(() => {
        initSetUpElectionForm(setUpElectionForm)
        initManageTellersForm(manageTellersForm)
    }, [])

    const importantText = useMemo(() => {
        if (!wordingConfig || !wordingConfig?.importantMessage)
            return ''

        return (<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(wordingConfig.importantMessage) }} />)
    }, [wordingConfig])

    const getLastStepButtonText = () => getCompleteSetUpButtonText(getVotingStartDate())

    const checkAndSaveAsDraft = async () => {
        const votingStartDate = getVotingStartDate()

        if (votingStartDate) {
            const isExpired = await checkIfStartDateExpired(votingStartDate.format(), () => { setInitStep(0) })
            if (isExpired) {
                return
            }
        }

        saveAsDraft({
            showConfirm: showConfirm,
            showInfo: showInfo,
            nextStepButtonText: currentStepIndex === 2 ? getLastStepButtonText() : t('next_step'),
            saveAsItIs: saveElectionAsDraftCb,
            currentStepIndex
        })
    }

    const saveForm = async () => await saveSetUpElectionForm({
        form: setUpElectionForm,
        withValidation: true,
        saveFunc: setUpElectionStepConfig.saveSetUpForm,
        checkExtraValidation: setUpElectionStepConfig.checkExtraValidation
    })

    const steps: IWizardStepProps[] = [
        {
            chipSettings: setUpElectionChipSettings,
            nextButton: {
                callback: async () => await saveForm(),
                icon: <ChevronBold height="10px" width="5.5px" />
            },
            children: <Row justify="space-evenly" gutter={16}>
                <Col span={12}>
                    <SetUpElectionForm form={setUpElectionForm}
                        onOnlineVotingStartDateChange={setUpElectionStepConfig.onOnlineVotingStartDateChange}
                        onOnlineVotingEndDateChange={setUpElectionStepConfig.onOnlineVotingEndDateChange}
                        electionDayPropName={setUpElectionStepConfig.electionDayPropName}
                        electionDateLabel={setUpElectionStepConfig.electionDateLabel}
                        electionDateValidationLabel={setUpElectionStepConfig.electionDateValidationLabel}
                        electionType={isSecondStage
                            ? 'TWO_STAGE_RIDVAN_ELECTION' as any //TWO_STAGE_RIDVAN_ELECTION is used only here
                            // Looks like back end doesn't have a single enum to describe ElectionType
                            // and defines a new one once in a while
                            : electionType}
                        initialValues={setUpElectionStepConfig.formInitialValue}
                        importantText={importantText}
                    >
                        {setUpElectionStepConfig.extraContent}
                    </SetUpElectionForm>
                </Col>
                {setUpElectionStepConfig.secondColumn}
            </Row>
        },
        {
            chipSettings: verifyParticipantsChipSettings,
            nextButton: { callback: () => Promise.resolve(true), icon: <Chevron height="10" width="5.5" /> },
            leaveStepCallback: () => Promise.resolve(true),
            children: verifyParticipantsContent
        },
        {
            chipSettings: setUpTellersChipSettings,
            minHeaderContentWidth: 1016,
            children: setUpTellersStepConfig.content,
            nextButton: {
                callback: async () => {
                    const isReady = await setUpTellersStepConfig.isStepReady()
                    if (!isReady) {
                        return false
                    }

                    const promise = new Promise<boolean>(async (resolve) => {
                        const electionStringDetails = {
                            electionYear: ballotingYear,
                            electionName: toString(electionType)
                        }

                        showConfirm({
                            onOk: async () => {
                                setUpTellersStepConfig.completeElectionSetup()
                                navigate('/')
                                resolve(true)
                            },
                            onCancel: async () => resolve(false),
                            title: isToday(getVotingStartDate())
                                ? t('complete_setup_and_start_online_election')
                                : t('complete_setup'),
                            text: isToday(getVotingStartDate())
                                ? t('you_are_about_to_start_the_year_name_would_you_like_to_proceed',
                                    electionStringDetails)
                                // eslint-disable-next-line max-len
                                : t('you_are_about_to_complete_setup_and_create_the_year_name_would_you_like_to_proceed',
                                    electionStringDetails
                                ),
                            okText: isToday(getVotingStartDate())
                                ? t('start_election') : t('complete_setup')
                        })
                    })

                    return promise
                },
                title: getLastStepButtonText()
            }
        }
    ]

    return (
        <>
            {confirmPortal}
            {infoPortal}
            <ElectionEditingWizard
                steps={steps}
                initStep={initStep}
                announced={announced}
                saveAsDraftCb={checkAndSaveAsDraft}
                lastStepNote={getLastStepNote(getVotingStartDate())
                    .map((line, index) => <div key={index}>{line}</div>)}
                type={electionType}
                year={ballotingYear}
                wizardStepStorageKey={`${electionType}_${periodId}_wizard_step`}
                onCurrentStepChange={(index) => setCurrentStepIndex(index)} />
        </>
    )
}

export default ElectionEditingProcess