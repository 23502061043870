import {
    useCandidatesOnlineVotes,
    useElection,
    useElectionOnlineBallotsCount,
    useElectionSummary,
    useOnlineBallots
} from 'api'
import Card from 'components/atoms/Card'
import ElectionBadge from 'components/molecules/ElectionBadge'
import StatisticCounter from 'components/molecules/StatisticCounter'
import { ballotingAssets } from 'features/ballotingAssets'
import { useHandleEntityLoadingError } from 'hooks'
import { Ballot, CandidatesRoundStatistic, ElectionStatusType } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { isElectionFrozen } from 'utils'
import { useElectionResultsCandidates } from '../../../../api'
import TieRoundResultsTable from '../TieRoundResultsTable'
import style from './index.module.scss'

const pageLimit = 30
const pageOffset = 30

function RoundResults() {
    const { t } = useTranslation('teller')
    const { electionId } = useParams<{ electionId: string }>()
    const { roundId } = useParams<{ roundId: string }>()
    const { handleEntityLoadingError } = useHandleEntityLoadingError()
    const [loadPage, setLoadPage] = useState(0)
    const [canLoadMore, setCanLoadMore] = useState(true)
    const [ballotsState, setBallotsState] = useState<Ballot[]>([])
    const [roundStatistic, setRoundStatistic] = useState<CandidatesRoundStatistic[]>([])
    const { data: election, error } = useElection(electionId!, !!electionId)
    const { data: originalElection } = useElection(election?.originatingElection?.toString() || '',
        !!election?.originatingElection)
    const { data: onlineBallotsCount } = useElectionOnlineBallotsCount(electionId!, !!electionId)
    const { data: originalElectionSummary, isFetched: isSummaryFetched } =
        useElectionSummary(originalElection?.id.toString() || '', !!originalElection)
    const { data: onlineBallots, isFetching } =
        useOnlineBallots(electionId!,
            { limit: pageLimit, offset: loadPage * pageOffset }, !!electionId)
    const { data: electionResultsCandidates } =
        useElectionResultsCandidates(electionId!, {},
            isElectionFrozen(originalElectionSummary),
            !!originalElection && isSummaryFetched)
    const { data: candidatesOnlineVotes } =
        useCandidatesOnlineVotes(electionId!, !!electionId)

    useEffect(() => {
        if (error) {
            handleEntityLoadingError(error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    useEffect(() => {
        if (electionResultsCandidates && candidatesOnlineVotes) {
            const mergedData = electionResultsCandidates.map(candidate => {
                const voteData = candidatesOnlineVotes.find(vote => vote.candidate === candidate.id)

                return {
                    ...candidate,
                    onlineVoteCount: voteData ? voteData.voteCount : 0
                }
            })

            const sortedData = mergedData.sort((a, b) => b.voteCount - a.voteCount)
            const finalData = sortedData.map((item, index) => ({
                ...item,
                position: index + 1
            }))

            const numberOfVacancies = election?.numberOfVacancies || 0

            let lastElecteeIndex = -1

            if (numberOfVacancies < finalData.length) {
                if (finalData.some(candidate => candidate.voteCount > 0)) {
                    lastElecteeIndex = finalData.findIndex((candidate, index) => {
                        const nextCandidate = finalData[index + 1]

                        return nextCandidate && candidate.voteCount > nextCandidate.voteCount
                    })

                    if (lastElecteeIndex === -1) {
                        lastElecteeIndex = finalData.length - 1
                    }
                }
            } else {
                lastElecteeIndex = finalData.length - 1
            }

            const finalDataWithFlags = finalData.map((candidate, index) => ({
                ...candidate,
                position: index + 1,
                isElecteeAfterTie: index <= lastElecteeIndex
            }))

            setRoundStatistic(finalDataWithFlags)

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [electionResultsCandidates, candidatesOnlineVotes])

    const handleScrollToBottom = () => {
        if (!canLoadMore || isFetching)
            return
        setLoadPage(loadPage + 1)
    }

    useEffect(() => {
        setCanLoadMore(!!onlineBallots?.length)

        if (onlineBallots) {
            setBallotsState(loadPage ? ballotsState.concat(onlineBallots) : [...onlineBallots])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onlineBallots])

    return (
        <>
            <div className={style.cardWrapper}>
                <Card
                    noHeaderLine
                    noContentPadding
                    className={style.card}
                    title={
                        <div className={style.contentWrapper}>
                            <div className={`${style.titleWrapper} ${style.space}`}>
                                <div>
                                    {t('revote_using_obs_round', { number: roundId })}
                                </div>
                                <ElectionBadge status={ElectionStatusType.COMPLETED} />
                            </div>
                            <div className={style.vacanciesCounter}>
                                {`${t('vacancies')}: ${election?.numberOfVacancies || 0}`}
                            </div>
                        </div>
                    }>
                    <div className={style.tableWrapper}>
                        <TieRoundResultsTable
                            roundStatistic={roundStatistic}
                        />
                    </div>
                </Card>
            </div>
            <div className={style.cardWrapper}>
                <Card
                    noHeaderLine
                    noContentPadding
                    className={style.card}
                    title={
                        <div className={style.titleWrapper}>
                            <div>
                                {t('online_ballots')}
                            </div>
                            <div className={style.counters}>
                                <StatisticCounter
                                    value={onlineBallotsCount?.totalCount || 0}
                                    title={t('online_ballots')} />
                                <StatisticCounter
                                    value={(onlineBallotsCount?.totalCount || 0) * (election?.numberOfVacancies || 0)}
                                    title={t('valid_votes')} />
                            </div>
                        </div>
                    }>
                    <div className={style.tableWrapper}>
                        <ballotingAssets.components.BallotsTable
                            ballots={ballotsState || []}
                            onScrollToBottom={handleScrollToBottom}
                            isStation={false}
                        />
                    </div>
                </Card>
            </div>
        </>
    )
}

export default RoundResults
