import { Select as AntSelect, Form, notification } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import { ColumnsType } from 'antd/es/table'
import { Select } from 'components/atoms/Select'
import Tooltip from 'components/atoms/Tooltip'
import OptionalTooltip from 'components/molecules/OptionalTooltip'
import Table from 'components/molecules/Table'
import { queryClient } from 'config/query-client'
import { AppointedTeam, RbcElection, TimeZone } from 'models'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { defaultToastProps, getTellers } from 'utils'
import TellersTooltipContent from '../../../../components/TellersTooltipContent'
import { useTimeZoneMap } from '../../../../hooks/map/useTimeZoneMap'
import { useDefineVacanciesAndTimezone } from '../../api'
import { convertRbcElectionToSubElectionViewModel } from '../../utils'
import style from './index.module.scss'

const vacanciesOptions = [5, 7, 9].map(num => ({ value: num, label: num.toString() }))
const timeZoneOptions = Object.entries(TimeZone).map(([key, value]) => ({
    value: value,
    label: key
}))

type RbcSetUpTellersProps = {
    rbcElections: RbcElection[]
    form: FormInstance<any>
}

function RbcSetUpTellers({ rbcElections, form }: RbcSetUpTellersProps) {
    const { t } = useTranslation('election')
    const { toString } = useTimeZoneMap()
    const [elections, setElections] = useState<RbcElection[]>()
    const [toaster, toasterContext] = notification.useNotification()

    const { mutateAsync: defineVacanciesAndTimezone } = useDefineVacanciesAndTimezone()

    useEffect(() => {
        if (rbcElections?.length) {
            queryClient.invalidateQueries(['rbc-election-period', rbcElections[0].electionPeriod.toString()])
        }
    }, [])

    useEffect(() => {
        elections?.forEach(e => {
            form.setFieldValue(['numberOfVacancies', e.id.toString()], e.numberOfVacancies || null)
            form.setFieldValue(['timeZone', e.id.toString()], e.timeZone)
        })
    }, [form, elections])

    const onChange = useMemo(() => async () => {
        const changes: any[] = []
        if (elections) {
            elections?.forEach(e => {
                const numberOfVacancies = form.getFieldValue(['numberOfVacancies', e.id.toString()])
                const timeZone = form.getFieldValue(['timeZone', e.id.toString()])
                changes.push({
                    id: e.id,
                    timeZone,
                    numberOfVacancies
                })
            })
            await defineVacanciesAndTimezone({ elections: changes, electionPeriod: elections[0].electionPeriod })
            toaster.open({
                ...defaultToastProps,
                message: `${t('time_zones_and_numbers_of_vacancies_successfully_saved')}.`
            })
        }
    }, [defineVacanciesAndTimezone, form, elections])

    useEffect(() => {
        if (rbcElections) {
            const sorted = rbcElections.sort((a, b) => a.region?.rbc?.localeCompare(b.region?.rbc))
            setElections(sorted)
        }
    }, [rbcElections])

    const columns: ColumnsType<RbcElection> = [
        {
            title: t('institution'),
            width: 200,
            render: (election: RbcElection) => (
                <div style={{ maxWidth: 168 }} className={style.pinnedToTop}>
                    <OptionalTooltip contentWrapperClassName="ellipsis">
                        {election.region.rbc}
                    </OptionalTooltip>
                </div>
            )
        },
        {
            title: t('num_of_vacancies'),
            width: 160,
            render: (election: RbcElection) => (
                <div className={style.pinnedToTopShort}>
                    <Form.Item
                        name={['numberOfVacancies', election.id.toString()]}
                        className={style.select}
                        rules={[{
                            required: true,
                            message: t('please_select')
                        }]}>
                        <Select
                            placeholder={t('select')}
                            className="small"
                            value={election.numberOfVacancies || null}
                            style={{ width: 136 }}
                            options={vacanciesOptions}
                            onChange={onChange}
                        />

                    </Form.Item>
                </div>
            )
        },
        {
            title: t('time_zone'),
            width: 220,
            render: (election: RbcElection) => (
                <div className={style.pinnedToTopShort}>
                    <Form.Item
                        name={['timeZone', election.id.toString()]}
                        className={style.select}
                    >
                        <Select
                            className="small"
                            value={election.timeZone}
                            style={{ width: 196 }}
                            onChange={onChange}
                        >
                            {timeZoneOptions.map((timeZoneOption, index) => (
                                <AntSelect.Option value={timeZoneOption.value} key={index}>
                                    {toString(timeZoneOption.value)}
                                </AntSelect.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>
            )
        },
        {
            title: t('chief_teller'),
            width: 312,
            render: (election: RbcElection) => {
                const team: AppointedTeam = getTellers(convertRbcElectionToSubElectionViewModel(election))

                return team.chief
                    ?
                    <div style={{ maxWidth: 280 }} className={style.pinnedToTop}>
                        <OptionalTooltip contentWrapperClassName={style.tellerName}>
                            {team.chief.name}
                        </OptionalTooltip>
                    </div>
                    : <div className={style.pinnedToTop}>{String.fromCharCode(8212)}</div>
            }
        },
        {
            title: t('other_tellers'),
            key: 'otherTellers',
            width: 312,
            render: (election: RbcElection) => {
                const team: AppointedTeam = getTellers(convertRbcElectionToSubElectionViewModel(election))
                const otherTellers = [...(team.assistants || []), ...(team.tellers || [])]

                if (!otherTellers.length) {
                    return <div className={style.pinnedToTop}>{String.fromCharCode(8212)}</div>
                }

                const firstTeller = otherTellers[0]
                const otherTellersLength = otherTellers.length

                return <div className={`${style.other} ${style.pinnedToTop}`}
                    style={{ maxWidth: 280 }}>
                    <OptionalTooltip contentWrapperClassName={style.tellerName}>
                        {firstTeller.name}
                    </OptionalTooltip>
                    {otherTellersLength > 1 && (
                        <div className={style.andMore}>
                            {t('and')}
                            <Tooltip title={<TellersTooltipContent team={team} />}>
                                <span className={style.more}>
                                    {t('count_more', { count: otherTellersLength - 1 })}
                                </span>
                            </Tooltip>
                        </div>
                    )}
                </div>
            }
        },
        {
            title: t('action'),
            width: 140,
            render: (election: RbcElection) => (
                <div className={style.pinnedToTop}>
                    <Link
                        to={`/election-team/${election.id}`}
                        className={election.tellers?.length ? '' : style.noTellers}
                        state={{ backButtonTitle: t('back_to_list') }}
                    >
                        {election.tellers?.length ? t('manage_tellers') : t('set_up_tellers')}
                    </Link>
                </div>
            )
        }
    ]

    return (
        <>
            {toasterContext}
            {elections &&
                <Form form={form}>
                    <Table
                        obsSize="small"
                        obsHeaderSize="small"
                        headerDividerVisible
                        dataSource={elections}
                        columns={columns}
                        pagination={false}
                        rootClassName={style.table}
                        rowKey="id"
                    />
                </Form>
            }
        </>
    )
}

export default RbcSetUpTellers