import { electionEditing } from 'features/electionEditing'
import { getRbcBackgroundColor } from 'features/reporting/utils/rbcBackgroundColors'
import { useElectionTypeMap } from 'hooks'
import { Election, ElectionType, RbcElectionsColoring } from 'models'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { dayjsToString } from 'utils'
import InstitutionName from '../InstitutionName'
import style from './index.module.scss'

type ElectionEndYearBlockProps = {
    election?: Election
    isTellersStatistics?: boolean
    rbcElectionsColoring?: RbcElectionsColoring[]
}

function ElectionEndYearBlock({ election, isTellersStatistics, rbcElectionsColoring }: ElectionEndYearBlockProps) {
    const { t } = useTranslation('telling-station')
    const { toString } = useElectionTypeMap()

    const { data: electionPeriod }
        = electionEditing.rbcElection.api.queries.useElectionPeriod(election?.electionPeriod.toString() || '',
            election?.type === ElectionType.RBC_ELECTION)

    const rbcElection = useMemo(() => electionPeriod?.elections?.[0], [electionPeriod])

    const details = {
        year: election?.ballotingYear,
        election: toString(election?.type as ElectionType),
        euCode: election?.region.unit,
        euNumberOfDelegates: election?.numberOfVacancies,
        rbcName: election?.region.rbc
    }

    const isRbcElection = election?.type === ElectionType.RBC_ELECTION
    const isNationalDelegateElection = election?.type === ElectionType.NATIONAL_DELEGATE_ELECTION

    const rbcStyles = details.rbcName && rbcElectionsColoring
        ? getRbcBackgroundColor(details.rbcName, rbcElectionsColoring)
        : { backgroundColor: '', color: '' }

    return (
        <div className={`${style.electionNameBlock} 
        ${!isRbcElection ? style.electionNameBlockColor : ''}`}
            style={isRbcElection ? rbcStyles : {}}
        >
            <span className={style.yearAndElectionWrapper}>
                {isNationalDelegateElection
                    ? `${t('year_b_e_election', details)} (${details.euCode})`
                    : t('year_b_e_election', details)}
            </span>
            <span>
                <InstitutionName
                    election={election} />
            </span>
            {isNationalDelegateElection && (
                <span className={style.yearAndElectionWrapper}>
                    {`${details.euNumberOfDelegates} ${t('teller:delegate_s')}`}
                </span>
            )}
            {!isTellersStatistics && isRbcElection && rbcElection?.tellingWeekendStart
                && rbcElection.tellingWeekendStart
                && (<span className={style.tellingWekendWrapper}>
                    {`${t('election:telling_weekends')}: 
                        ${dayjsToString(rbcElection.tellingWeekendStart, 'MM/DD/YYYY')} -
                            ${dayjsToString(rbcElection.tellingWeekendEnd, 'MM/DD/YYYY')}`}
                </span>
                )}
        </div>
    )
}

export default ElectionEndYearBlock