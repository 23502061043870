import { useTranslation } from 'react-i18next'
import style from './index.module.scss'

type UnresolvedTieTextProps = {
    isTieSkipped: boolean
    isTieAutoSkipped: boolean
    canSkipTheTie: boolean
    hasDesignatedCandidates: boolean
    peopleDesignatedAsElectee: JSX.Element | null
    resolutionComment?: string
    peopleElectedViaRevoteRound: string
    canProceedToReporting: boolean
}

function UnresolvedTieText({
    isTieSkipped,
    isTieAutoSkipped,
    canSkipTheTie,
    hasDesignatedCandidates,
    peopleDesignatedAsElectee,
    resolutionComment,
    peopleElectedViaRevoteRound,
    canProceedToReporting
}: UnresolvedTieTextProps) {
    const { t } = useTranslation('teller')

    return (
        <div className={style.textWrapper}>
            {t('election_results_included_ties')} <br />
            {!isTieSkipped && !isTieAutoSkipped &&
                <span className={style.textFont}>
                    {canSkipTheTie
                        ? t('if_the_minority_rule_applies_please_proceed_to_break_the_tie')
                        : t('please_proceed_with_the_break_the_tie_process')}<br />
                </span>
            }
            {canProceedToReporting &&
                <>
                    <span className={style.textFont}>
                        {
                            `${t('please_click_proceed_to_reporting')}. `
                        }
                    </span>
                    <br />
                </>
            }
            {peopleElectedViaRevoteRound &&
                <span className={style.textFont}>
                    <b>{peopleElectedViaRevoteRound}</b>
                    {` ${t('elected_via_revote_using_obs')}`}<br />
                </span>
            }
            {hasDesignatedCandidates &&
                <>
                    {peopleDesignatedAsElectee}
                    <span className={style.textFont}>
                        {resolutionComment &&
                            <>
                                <b>{` ${t('comment')}: `}</b>
                                {resolutionComment}
                            </>
                        }
                    </span>
                </>
            }
        </div>
    )
}

export default UnresolvedTieText