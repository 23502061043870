import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { Election } from 'models'

export const useAdminCompletedElections = (enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getAdminCompletedElections = async (): Promise<Election[]> => {
        const res = await axios<Election[]>(`${moduleUrl}me/admin/elections/completed`)

        return res.data
    }

    return useQuery({
        queryKey: ['elections-my-completed-admin'],
        queryFn: () => getAdminCompletedElections(),
        enabled
    })
}