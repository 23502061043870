import { useCompleteElectionWithPhysicalVotes, useElection, useElectionCandidates } from 'api'
import Card from 'components/atoms/Card'
import ElectionBadge from 'components/molecules/ElectionBadge'
import Note from 'components/molecules/Note'
import { queryClient } from 'config/query-client'
import { useConfirmPopup, useHandleEntityLoadingError } from 'hooks'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useCandidatesPhysicalVotes } from '../../api'
import { useMergeResultsData } from '../../hooks'
import { PhysicalBallotsFlowParticipant } from '../../model'
import TieSubmitPhysicalBallotsTable from '../TieSubmitPhysicalBallotsTable'
import style from './index.module.scss'

const TIE_PHYSICAL_BALLOT_KEY = 'tie_physical_ballot_step_'

type TieSubmitPhysicalBallotsParams = {
    onEditClick: () => void
}

function TieSubmitPhysicalBallots({ onEditClick }: TieSubmitPhysicalBallotsParams) {
    const { t } = useTranslation('teller')
    const { mergedResultsData } = useMergeResultsData()
    const { electionId } = useParams()
    const navigate = useNavigate()
    const { confirmPortal, showConfirm } = useConfirmPopup()
    const [candidates, setCandidates] = useState<PhysicalBallotsFlowParticipant[]>([])
    const { handleEntityLoadingError } = useHandleEntityLoadingError()

    const { mutateAsync: completeElectionWithPhysicalVotes } = useCompleteElectionWithPhysicalVotes()

    const { data: tieElection, error } = useElection(electionId || '', !!electionId)
    const { data: electionCandidates } = useElectionCandidates(electionId || '', {}, !!electionId)
    const { data: candidatesPhysicalVotes } = useCandidatesPhysicalVotes(electionId || '', !!electionId)

    useEffect(() => {
        if (error) {
            handleEntityLoadingError(error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    useEffect(() => {
        if (electionCandidates) {
            const allCandidates = [...electionCandidates.map(c => c as PhysicalBallotsFlowParticipant).sort((a, b) =>
                a.name.toLowerCase().localeCompare(b.name.toLowerCase()))]
            if (candidatesPhysicalVotes) {
                candidatesPhysicalVotes.forEach(physicalVotes => {
                    const candidate = allCandidates.find(c => c.id === physicalVotes.candidate)
                    if (candidate) {
                        candidate.physicalVotes = physicalVotes.voteCount
                    }
                })
            }
            setCandidates(allCandidates)
        }

    }, [electionCandidates, candidatesPhysicalVotes])

    const confirmResults = async () => {
        showConfirm({
            onOk: async () => {
                await completeElectionWithPhysicalVotes({
                    electionId: electionId!,
                    candidateVotes: candidates.map(c => ({ candidate: c.id, voteCount: c.physicalVotes || 0 }))
                })
                await queryClient.invalidateQueries(['election-results-tie', electionId])
                await queryClient.invalidateQueries(['breaking-tie-rounds', electionId])
                sessionStorage.removeItem(`${TIE_PHYSICAL_BALLOT_KEY}${electionId}`)
                navigate(`/merged-results${tieElection?.originatingElection
                    ? ('/' + tieElection.originatingElection)
                    : ''}`, { replace: true })
            },
            title: `${t('confirm_results')}?`,
            text: <>
                {t('you_are_about_to_confirm_the_results_of_the_revote_using_obs',
                    { number: mergedResultsData.lastRound?.roundNumber || '' })}<br /><br />
                <strong>{t('please_note_this_is_an_irreversible_action')}</strong>
            </>,
            okText: t('confirm_results')
        })

    }

    return (
        <>
            {confirmPortal}
            <div className={style.cardWrapper}>
                <Card
                    noContentPadding
                    className={style.card}
                    title={
                        <div className={style.titleWrapper}>
                            {t('revoting_results_round_number',
                                { number: mergedResultsData.lastRound?.roundNumber || '' })}
                            {!!tieElection && <ElectionBadge status={tieElection.status} />}
                        </div>
                    }>
                    <div className={style.noteWrapper}>
                        <Note mode="info" icon="regular">
                            {t('click_edit_votes_to_edit_physical_ballots')}<br />
                            {t('click_confirm_results_to_proceed_to_the_results_dashboard')}
                        </Note>
                    </div>
                    <div className={style.tableWrapper}>
                        <TieSubmitPhysicalBallotsTable />
                    </div>
                    <div className={style.buttonWrapper}>
                        <button
                            style={{ width: 302 }}
                            className="btn-main-secondary"
                            onClick={() => onEditClick()}>
                            {t('edit_votes')}
                        </button>
                        <button
                            style={{ width: 302 }}
                            className="btn-main-primary"
                            onClick={() => confirmResults()}
                        >
                            {t('confirm_results')}
                        </button>
                    </div>
                </Card >
            </div >
        </>
    )
}

export default TieSubmitPhysicalBallots