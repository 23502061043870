import OptionalTooltip from 'components/molecules/OptionalTooltip'
import { useTranslation } from 'react-i18next'
import { UnitElectedDelegates } from '../../models'
import style from './index.module.scss'

type UnitElectedLocalDelegatesProps = {
    unitElectedDelegates: UnitElectedDelegates
}

function UnitElectedLocalDelegates({ unitElectedDelegates }: UnitElectedLocalDelegatesProps) {
    const { t } = useTranslation()

    return (
        <div className={style.content}>
            <div className={style.unit}>
                {unitElectedDelegates.unit}
            </div>
            <div className={style.delegates}>
                {unitElectedDelegates.delegates.map(d => <div
                    key={d.id}
                    className={style.delegate}>
                    <OptionalTooltip contentWrapperClassName="ellipsis">
                        {d.name} ({t('bid')}: {d.id})</OptionalTooltip>
                </div>)}
            </div>
        </div>
    )
}

export default UnitElectedLocalDelegates