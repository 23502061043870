import { Select as AntSelect, Col, Form, FormInstance } from 'antd'
import { Select } from 'components/atoms/Select'
import { queryClient } from 'config/query-client'
import dayjs from 'dayjs'
import { useHandleEntityLoadingError, useInfoPopup } from 'hooks'
import { ElectionStatusType, ElectionType } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { isFormValid, toDayjs } from 'utils'
import ElectionEditingProcess from '../../../../components/ElectionEditingProcess'
import ManageElectionTellersForm from '../../../../components/ManageElectionTellersForm'
import {
    useExtractSetUpElectionSchedule,
    useManageTellersActions,
    useSetUpElectionActions
} from '../../../../hooks'
import { SetUpElectionStepConfig, SetUpTellersStepConfig } from '../../../../models'
import { useAnnounceByElectionPeriod, useDefineCandidates, useElectionPeriod, useSetUpSchedule } from '../../api'
import { useExtractNoLongerServing, useOfficers } from '../../hooks'
import Officers from '../Officers'
import VerifyByElectionParticipants from '../VerifyByElectionParticipants'

function ByElectionEditingProcess() {
    const { t } = useTranslation('election')
    const { showInfo, infoPortal } = useInfoPopup()
    const { electionPeriodId } = useParams()
    const { officers } = useOfficers()

    const [setUpElectionForm, setSetUpElectionForm] = useState<FormInstance<any>>()
    const [manageTellersForm, setManageTellersForm] = useState<FormInstance<any>>()

    const { handleEntityLoadingError } = useHandleEntityLoadingError()
    const { manageTellers } = useManageTellersActions()
    const { saveSetUpElectionForm } = useSetUpElectionActions()
    const { extractSchedule } = useExtractSetUpElectionSchedule()
    const { extractNoLongerServing } = useExtractNoLongerServing()

    const { data: electionPeriod, error } = useElectionPeriod(electionPeriodId!)
    const { mutateAsync: setUpSchedule } = useSetUpSchedule()
    const { mutateAsync: defineCandidates } = useDefineCandidates()
    const { mutateAsync: announce } = useAnnounceByElectionPeriod()

    useEffect(() => {
        if (error) {
            handleEntityLoadingError(error)
        }
    }, [error])

    const saveSetUpForm = async () => {
        if (setUpElectionForm) {
            const electionSchedule = extractSchedule(setUpElectionForm)
            const noLongerServing = extractNoLongerServing(setUpElectionForm)
            officers.filter(o => !o.name && o.noLongerServing).forEach(() => noLongerServing.push({
                id: '',
                officeHeld: '',
                reasonForVacancy: ''
            }))

            await defineCandidates({
                electionPeriod: electionPeriod?.id.toString() || '',
                noLongerServing
            })
            await setUpSchedule({
                schedule: electionSchedule,
                electionPeriod: electionPeriod?.id.toString() || ''
            })
            await queryClient.invalidateQueries(['by-election-period', electionPeriodId])
        }
    }

    useEffect(() => {
        if (setUpElectionForm) {
            const formValue = setUpElectionForm.getFieldsValue()
            setUpElectionForm.setFieldsValue({
                ...formValue,
                numberOfVacancies: officers.filter(o => o.noLongerServing).length
            })
        }
    }, [officers])

    const getVotingStartDate = () => {
        if (setUpElectionForm) {
            const formValue = setUpElectionForm.getFieldValue('onlineVotingStart')
            if (formValue)
                return formValue
            if (electionPeriod && electionPeriod!.byElection.onlineVotingStart) {
                return dayjs(new Date(electionPeriod!.byElection.onlineVotingStart))
            }
        }

        return null
    }

    const setUpElectionFormInitial = {
        ballotingYear: t('year_b_e_period',
            {
                year: electionPeriod?.ballotingYear,
                period: `${dayjs().year()}-${dayjs().year() + 1}`
            }),
        numberOfVacancies: 0,
        timeZone: electionPeriod?.byElection.timeZone || null as any,
        electionDay: electionPeriod?.byElection.electionDay ?
            toDayjs(electionPeriod.byElection.electionDay)
            : undefined,
        onlineVotingStart: electionPeriod?.byElection.onlineVotingStart ?
            toDayjs(electionPeriod.byElection.onlineVotingStart)
            : undefined
    }

    const isSetupTellersStepReady = async () => {
        if (!manageTellersForm) {
            return false
        }
        const tellersValid = await isFormValid({ form: manageTellersForm })
        if (!tellersValid) {
            return false
        }

        return true
    }

    const completeElectionSetup = async () => {
        if (manageTellersForm) {
            await manageTellers({
                form: manageTellersForm,
                electionId: electionPeriod?.byElection.id.toString() || ''
            })
            await announce(electionPeriod?.id.toString() || '')
        }
    }

    const setUpElectionStepConfig: SetUpElectionStepConfig = {
        saveSetUpForm,
        formInitialValue: setUpElectionFormInitial,
        checkExtraValidation: async () => {
            Promise.resolve(!!officers.filter(o => o.noLongerServing).length)

            const promise = new Promise<boolean>(async (resolve) => {
                if (!!officers.filter(o => o.noLongerServing).length) {
                    resolve(true)
                } else {
                    showInfo({
                        title: t('no_vacancies'),
                        text: t('please_mark_the_member_s_who_will_no_longer_serve'),
                        onOk: () => { }
                    })
                    resolve(false)
                }
            })

            return promise
        },
        extraContent: <Form.Item
            name="numberOfVacancies"
            label={<>
                {t('number_of_vacancies')}
            </>}>
            <Select
                disabled={true}
                placeholder={t('number_of_vacancies')}>
                <AntSelect.Option value={0}>
                    {0}
                </AntSelect.Option>
            </Select>
        </Form.Item>,
        secondColumn: setUpElectionForm && <Col span={12}>
            <Officers
                form={setUpElectionForm}
                noLongerServing={electionPeriod?.byElection?.noLongerServing || []} />
        </Col>
    }

    const setUpTellersStepConfig: SetUpTellersStepConfig = {
        isStepReady: isSetupTellersStepReady,
        completeElectionSetup,
        content: !!electionPeriod && !!manageTellersForm && <ManageElectionTellersForm
            form={manageTellersForm} canSaveWithoutMandatoryTellers
            election={electionPeriod?.byElection} />
    }

    const saveElectionAsDraftCb = async (currentStepIndex: number) => {
        if (setUpElectionForm) {
            switch (currentStepIndex) {
                case 0:
                    return await saveSetUpElectionForm({
                        form: setUpElectionForm,
                        saveFunc: saveSetUpForm
                    })
                case 2:
                    if (manageTellersForm) {
                        await manageTellers({
                            form: manageTellersForm,
                            electionId: electionPeriod?.byElection.id.toString() || ''
                        })
                    }

                    return Promise.resolve(true)
            }
        }

        return Promise.resolve(true)
    }

    return (
        <>
            {infoPortal}
            {electionPeriod &&
                <ElectionEditingProcess
                    electionStatus={electionPeriod.byElection.status}
                    announced={electionPeriod.byElection.status !== ElectionStatusType.DRAFT}
                    ballotingYear={electionPeriod.ballotingYear}
                    electionType={ElectionType.BY_ELECTION}
                    getVotingStartDate={getVotingStartDate}
                    periodId={electionPeriod.id}
                    initSetUpElectionForm={setSetUpElectionForm}
                    initManageTellersForm={setManageTellersForm}
                    saveElectionAsDraftCb={saveElectionAsDraftCb}
                    setUpElectionStepConfig={setUpElectionStepConfig}
                    verifyParticipantsContent={<VerifyByElectionParticipants
                        electionPeriodId={electionPeriod?.id.toString() || ''} />}
                    setUpTellersStepConfig={setUpTellersStepConfig}
                />
            }
        </>
    )
}

export default ByElectionEditingProcess