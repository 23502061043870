import { useElectionPeriod, usePlanRbcElectionPeriod } from './api'
import RbcEditing from './pages/RbcEditing'
import RbcElectionBoard from './pages/RbcElectionBoard'
import { getRbcStatus } from './utils'

export const rbcElection = {
    api: {
        mutations: {
            usePlanRbcElectionPeriod
        },
        queries: {
            useElectionPeriod
        }
    },
    pages: {
        RbcEditing,
        RbcElectionBoard
    },
    utils: {
        getRbcStatus
    }
}