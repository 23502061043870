import { Form } from 'antd'
import { useForm } from 'antd/es/form/Form'
import Card from 'components/atoms/Card'
import IconWrapper from 'components/atoms/IconWrapper'
import CardHeader from 'components/molecules/CardHeader'
import Preloader from 'components/molecules/Preloader'
import { useReportLoader } from 'hooks'
import { ReactComponent as Printer } from 'icons/printer.svg'
import { ElectionType } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useReportingData } from '../../hooks'
import { ReportFilter } from '../../model/report-filter'
import BallotingYear from '../BallotingYear'
import ElectionTypeFilter from '../ElectionTypeFilter'
import ElectoralUnitFilter from '../ElectoralUnitFilter'
import LsaInstitutionFilter from '../LsaInstitutionFilter'
import RbcElectionFilter from '../RbcElectionFilter'
import RbcFilter from '../RbcFilter'
import style from './index.module.scss'

function Filters() {
    const { t } = useTranslation('administration')
    const { reportingData } = useReportingData()
    const [form] = useForm()
    const [isFormValid, setIsFormValid] = useState(false)
    const { loadReport, isReportLoading } = useReportLoader()
    const [ballotingYear, setBallotingYear] = useState<number>()
    const [electionType, setElectionType] = useState<ElectionType>()

    useEffect(() => {
        form.resetFields()
        setBallotingYear(undefined)
        setElectionType(undefined)
        if (!!reportingData.selectedReport) {
            setIsFormValid(!reportingData.selectedReport.filters.length)
        } else {
            setIsFormValid(false)
        }
    }, [reportingData.selectedReport, form])

    const handleFormValuesChange = (_: any, allFields: Record<string, any>) => {
        const allFieldsValid = Object.values(allFields).every(value =>
            value !== undefined && value !== '' && value?.length !== 0)
        setIsFormValid(allFieldsValid)
    }

    const load = () => {
        const report = reportingData.selectedReport
        if (report) {
            const params = { ...form.getFieldsValue() }
            if (!params.electionType && report.electionType) {
                params.electionType = report.electionType
            }
            if (report.includeSubUnits !== undefined) {
                params.includeSubUnits = report.includeSubUnits ? 'YES' : 'NO'
            }
            loadReport({
                reportType: report.type,
                reportFormat: report.format,
                params: {
                    ...params
                }
            })
        }
    }

    return (
        <Card title={<CardHeader title={!!reportingData.selectedReport
            ? t('report_report_name', {
                reportName: reportingData.selectedReport.label,
                interpolation: { escapeValue: false }
            })
            : t('select_report')} />}>
            {isReportLoading && <Preloader title={t('working_on_your_report')} />}
            <Form
                layout="vertical"
                onValuesChange={handleFormValuesChange}
                form={form}>
                <div className={style.content}>
                    <div className={style.filters}>
                        {reportingData.selectedReport
                            && reportingData.selectedReport?.filters.map(filter => {
                                switch (filter) {
                                    case ReportFilter.BallotingYear:
                                        return <BallotingYear key="ballotingYear"
                                            disabled={!reportingData.selectedReport} onChange={setBallotingYear} />
                                    case ReportFilter.ElectoralUnit:
                                        return <ElectoralUnitFilter key="electoralUnit"
                                            bahaiYear={ballotingYear} disabled={!ballotingYear} />
                                    case ReportFilter.LsaInstitution:
                                        return <LsaInstitutionFilter key="lsaInstitution"
                                            form={form}
                                            bahaiYear={ballotingYear}
                                            electionType={reportingData.selectedReport?.electionType || electionType}
                                            disabled={!ballotingYear
                                                || (!reportingData.selectedReport?.electionType && !electionType)} />
                                    case ReportFilter.RbcElection:
                                        return <RbcElectionFilter key="rbcElection" disabled={!ballotingYear}
                                            bahaiYear={ballotingYear} />
                                    case ReportFilter.Rbc:
                                        return <RbcFilter key="rbcElection" />
                                    case ReportFilter.ElectionType:
                                        return <ElectionTypeFilter key="electionType"
                                            onChange={type => {
                                                setElectionType(type)
                                                form.resetFields(['lsaInstitution'])
                                            }}
                                            electionTypesToSelect={reportingData
                                                .selectedReport?.electionTypesInFilter} />
                                    default:
                                        return 'unknown filter'
                                }
                            })
                        }
                    </div>
                    <button
                        type="submit"
                        className={`${style.generate} btn-main-primary-md`}
                        disabled={!isFormValid}
                        onClick={() => load()}
                    >
                        <IconWrapper>
                            <Printer />
                        </IconWrapper>
                        <span>{t('generate_n_download', { interpolation: { escapeValue: false } })}</span>
                    </button>
                </div>
            </Form>
        </Card>
    )
}

export default Filters