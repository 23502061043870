import { useElectionStationsBallots, useOnlineBallots } from 'api'
import Spinner from 'components/molecules/Spinner'
import { ballotingAssets } from 'features/ballotingAssets'
import { useAuth } from 'hooks'
import { Ballot, Election } from 'models'
import { ReactNode, useEffect, useState } from 'react'
import style from './index.module.scss'

const sortAllBallots = (a: Ballot, b: Ballot): number => {
    const isAOnline = a.id.startsWith('OS-')
    const isBOnline = b.id.startsWith('OS-')

    if (!isAOnline && isBOnline) {
        return -1
    } else if (isAOnline && !isBOnline) {
        return 1
    }

    if (!isAOnline && !isBOnline) {
        const stationA = a.station
        const stationB = b.station
        const idANum = parseInt(a.id.split('-')[1], 10)
        const idBNum = parseInt(b.id.split('-')[1], 10)

        if (stationA === stationB) {
            return idBNum - idANum
        }

        return parseInt(stationB.split('S')[1], 10) - parseInt(stationA.split('S')[1], 10)
    }

    if (isAOnline && isBOnline) {
        const idANum = parseInt(a.id.replace('OS-', ''), 10)
        const idBNum = parseInt(b.id.replace('OS-', ''), 10)

        return idBNum - idANum
    }

    return 0
}

type ConsolidateBallotEntryTableProps = {
    election: Election
    collapsed?: boolean
    statistic?: ReactNode
}

function ConsolidateBallotEntryTable({ election, collapsed, statistic }: ConsolidateBallotEntryTableProps) {
    const { auth } = useAuth()
    const { data: stationsBallots, isFetching } = useElectionStationsBallots(
        election.id.toString() || auth!.electionId!, {}, !!election.id || !!auth?.electionId)
    const { data: onlineBallots, isFetching: isOnlineFetching } = useOnlineBallots(
        election.id.toString() || auth!.electionId!, {}, !!election.id || !!auth!.electionId, collapsed)

    const [ballots, setBallots] = useState<Ballot[]>([])

    useEffect(() => {
        const all = [
            ...(stationsBallots || []),
            ...(onlineBallots?.map(ballot => ({
                ...ballot,
                id: `OS-${ballot.id}`
            })) || [])
        ]
        all.sort(sortAllBallots)

        setBallots(all)
    }, [stationsBallots, onlineBallots])

    return (
        <>
            {(collapsed && (isFetching || isOnlineFetching))
                ? <div className={style.spinnerWrapper}> <Spinner /></div>
                : <ballotingAssets.components.InternalScrollBallotsTable
                    election={election}
                    ballots={ballots}
                    noBorders={collapsed}
                    statistic={statistic}
                    height={collapsed ? 320 : undefined}
                />
            }
        </>
    )
}

export default ConsolidateBallotEntryTable