import { useElection } from 'api'
import Card from 'components/atoms/Card'
import PopupTitle from 'components/atoms/PopupTitle'
import { useAbility, useConfirmPopup, useHandleStationLogoutError, useInfoPopup, usePopup } from 'hooks'
import { RefObject, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useBallotInvalidationReasons, useInvalidateBallot, useStationInvalidateBallot } from '../../api'
import { useBallotActions, useBallotVotes, useBallotVotesActions } from '../../hooks'
import { isVoteVacant, isVoteValid } from '../../utils'
import BallotEditorVote from '../BallotEditorVote'
import EnterVote from '../EnterVote'
import MarkBallotAsInvalid from '../MarkBallotAsInvalid'
import style from './index.module.scss'

type BallotEditorProps = {
    electionId: string
    ballotId: string
    ballotExists?: boolean
    onBallotInvalidate?: () => void
    ballotIdRef?: RefObject<HTMLDivElement>
}

function BallotEditor({
    electionId,
    ballotId,
    ballotExists,
    onBallotInvalidate,
    ballotIdRef
}: BallotEditorProps) {
    const { t } = useTranslation('ballot')
    const { ability } = useAbility()
    const { ballotVotes, setBallotVotes } = useBallotVotes()
    const { confirmPortal, showConfirm } = useConfirmPopup()
    const { popupPortal, show, hide } = usePopup()
    const { resetBallot } = useBallotVotesActions()
    const { markBallotAsInvalid, submitBallot, infoPortal: submitStationBallotPortal } = useBallotActions()
    const { mutateAsync: stationInvalidateBallot } = useStationInvalidateBallot()
    const { mutateAsync: invalidateBallot } = useInvalidateBallot()
    const { data: election } = useElection(electionId)
    const { infoPortal, showInfo } = useInfoPopup()
    const { handleError } = useHandleStationLogoutError()

    const { data: ballotInvalidationReasons }
        = useBallotInvalidationReasons(electionId)

    useEffect(() => {
        if (election) {
            const votes = []
            for (let i = 0; i < election?.numberOfVacancies; i++) {
                votes.push({ id: i })
            }
            setBallotVotes(votes)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [election])

    const reset = () => {
        showConfirm({
            title: t('reset_ballot'),
            text: t('are_you_sure_you_want_to_reset_this_ballot'),
            okText: t('reset_ballot'),
            onOk: async () => { resetBallot() }
        })
    }

    const showMarkBallotAsInvalidPopup = () => {
        show({
            title: <PopupTitle>{t('mark_ballot_id_as_invalid', { id: ballotId })}</PopupTitle >,
            children: <MarkBallotAsInvalid
                onCancel={() => hide()}
                onMarkAsInvalid={async (invalidationReason: string) => {
                    try {
                        if (ballotExists) {
                            if (ability?.can('edit', 'StationBallot')) {
                                await stationInvalidateBallot({ id: ballotId, invalidationReason })
                            } else {
                                await invalidateBallot({
                                    id: ballotId, electionId, stationId: ballotId.split('-')[0], invalidationReason
                                })
                            }
                        } else {
                            await markBallotAsInvalid(invalidationReason, ballotId)
                        }
                        hide()
                        onBallotInvalidate?.()
                    } catch (e) {
                        handleError(e, showInfo)

                        return
                    }
                }} />,
            footer: null
        })
    }

    const submit = () => {
        submitBallot({
            ballotId,
            hide,
            show,
            ballotInvalidationReasons
        })
    }

    return (
        <>
            {submitStationBallotPortal}
            {infoPortal}
            <Card noHeaderLine noContentPadding className={`${style.editor} ${ballotExists ? '' : style.fixedHeight}`}
                id="ballot-editor-form"
                title={<div className={style.header} >
                    {confirmPortal}
                    {popupPortal}
                    <div className={style.title}>
                        <div className={style.ballotIdRef} ref={ballotIdRef} id="ballot-id" />
                        {ballotExists ? t('edit_ballot_id') : t('ballot_id')}: {ballotId}
                    </div>
                    <div className={style.buttons}>
                        <button
                            disabled={ballotVotes?.filter(v =>
                                isVoteVacant(v)).length === election?.numberOfVacancies}
                            className={`${style.submit} btn-warning-tertiary`}
                            onClick={reset}>
                            {t('reset_ballot')}
                        </button>
                        <button
                            className={`${style.submit} btn-error-secondary`}
                            id="mark-ballot-as-invalid-btn"
                            onClick={showMarkBallotAsInvalidPopup}>
                            {t('mark_ballot_as_invalid')}
                        </button>
                        {!ballotExists && <button
                            id="submit-ballot-btn"
                            className={`${style.submit} btn-success-primary`}
                            onClick={submit}>
                            {t('submit_ballot')}
                        </button>
                        }
                    </div>
                </div>}

            >
                <div className={style.content}>
                    <EnterVote ballotId={ballotId} ballotExists={ballotExists} />
                    <div className={style.actionsColumn}
                        id="ballot-editor-actions-col"
                        style={{ height: `calc(calc(var(--default-space) * 5) * ${ballotVotes.length})` }} />
                    <div className={`${ballotExists ? '' : style.fixedVotesHeight}`}>
                        {ballotVotes.map((v, index) =>
                            <BallotEditorVote key={index} electionId={electionId} ballotVote={v} />
                        )}
                    </div>
                    <div className={style.footer}>
                        <div className={style.validVotesCounter}>
                            {t('valid_votes')}: {ballotVotes?.filter(v =>
                                isVoteValid(v)).length}/{election?.numberOfVacancies}
                        </div>
                    </div>
                </div>
            </Card>
        </>
    )
}

export default BallotEditor