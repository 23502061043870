import { ElectionPushStatus, SortOrder } from 'models'
import { DelegateElection, DelegateElectionsFilter } from '../model'

export const filterElections = (allElections: DelegateElection[], filter: DelegateElectionsFilter) => {
    let elections = [...allElections]

    let sortingOrder = SortOrder.ASC
    let sortingField: keyof DelegateElectionsFilter = 'unitName'

    if (filter.sortBy) {
        sortingOrder = filter.sortBy.order
        sortingField = filter.sortBy.field as keyof DelegateElectionsFilter
    }

    elections = elections.sort((a: DelegateElection, b: DelegateElection) => {
        const conditions = sortingOrder === SortOrder.ASC
            ? [
                (p1: any, p2: any) => p1 > p2,
                (p1: any, p2: any) => p1 < p2
            ]
            : [
                (p1: any, p2: any) => p1 < p2,
                (p1: any, p2: any) => p1 > p2
            ]

        if (sortingField === 'unitName') {
            if (!a[sortingField] && b[sortingField]) {
                return 1
            }
            if (!b[sortingField] && a[sortingField]) {
                return -1
            }
        }

        if (sortingField !== 'sortBy') {
            if (conditions[0](a[sortingField], b[sortingField])) {
                return 1
            }
            if (conditions[1](a[sortingField], b[sortingField])) {
                return -1
            }
        }

        if (sortingField !== 'unitName') {
            const field = 'unitName'
            if (a[field] > b[field]) {
                return 1
            }
            if (a[field] < b[field]) {
                return -1
            }
        }

        return 0
    })

    if (filter.unitName) {
        const names = filter.unitName!.trim().toLowerCase().split(' ').filter(n => !!n)
        names.forEach(name => {
            elections = elections.filter(e => e.unitName.toLowerCase().includes(name))
        })
    }

    if (filter.unitCode) {
        const code = filter.unitCode!.trim().toLowerCase()
        elections = elections.filter(e => e.unitCode.toLowerCase().includes(code))
    }

    if (filter.electionStatus?.length) {
        elections = elections.filter(e => filter.electionStatus?.includes(e.electionStatus))
    }

    if (filter.electionPushStatus?.length) {
        elections = elections.filter(e => {
            const statusToCheck = e.electionPushStatus === ElectionPushStatus.AZURE_ERROR
                ? ElectionPushStatus.REJECTED
                : e.electionPushStatus

            return filter.electionPushStatus?.includes(statusToCheck)
        })
    }

    return elections
}