import Preloader from 'components/molecules/Preloader'
import { useJsonReportLoader } from 'hooks'
import { ElectionType, ReportType } from 'models'
import { useEffect } from 'react'
import { useLsaElectionsData } from '../../hooks'
import { ElectionsReport } from '../../model'

type ElectionsLoaderProps = {
    electionType: ElectionType
}

function ElectionsLoader({ electionType }: ElectionsLoaderProps) {
    const { lsaElectionsData, setLsaElectionsData } = useLsaElectionsData()

    const { isLoading, reportError } = useJsonReportLoader<ElectionsReport>({
        reportType: ReportType.LsaElections,
        params: {
            ballotingYear: lsaElectionsData.badiYear,
            electionType
        },
        onReportReady: (report) => {
            setLsaElectionsData((current) => ({
                ...current,
                allElections: report.elections,
                summary: report.summary
            }))
        }
    })

    useEffect(() => {
        setLsaElectionsData((current) => ({
            ...current,
            isReportLoading: isLoading,
            reportError: reportError || undefined
        }))
    }, [isLoading, reportError, setLsaElectionsData])

    return <>{isLoading && <Preloader />}</>
}

export default ElectionsLoader