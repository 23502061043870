import { Can } from 'components/atoms/Can'
import { useTranslation } from 'react-i18next'
import style from './index.scss'

type TieInfoBlockActionProps = {
    canChangeTieResults: boolean
    breakTheTie: () => void
    designateElectee: () => void
    canDesignateElecteeOnly: boolean
    canResolveTheTie: boolean
    canSkipTheTie: boolean
    skipBreakingTheTie: () => void
}

function TieInfoBlockAction({
    canChangeTieResults,
    breakTheTie,
    designateElectee,
    canDesignateElecteeOnly,
    canResolveTheTie,
    canSkipTheTie,
    skipBreakingTheTie
}: TieInfoBlockActionProps) {
    const { t } = useTranslation('teller')

    return (
        <>
            {canChangeTieResults &&
                <Can I="view" a="ChangeTieResultsButton">
                    <button
                        className="btn-main-secondary-md"
                        onClick={designateElectee}
                    >
                        {t('change_tie_results')}
                    </button>
                </Can>
            }
            {canResolveTheTie &&
                <div className={style.buttonsWrapper}>
                    {canSkipTheTie &&
                        <button
                            className="btn-main-secondary-md"
                            onClick={skipBreakingTheTie}
                        >
                            {t('skip_break_the_tie')}
                        </button>
                    }
                    <button
                        className="btn-main-primary-md"
                        onClick={canDesignateElecteeOnly ? designateElectee : breakTheTie}
                    >
                        {t('break_the_tie')}
                    </button>
                </div>
            }
        </>
    )
}

export default TieInfoBlockAction