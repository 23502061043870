import { ColumnsType } from 'antd/es/table'
import OptionalTooltip from 'components/molecules/OptionalTooltip'
import Preloader from 'components/molecules/Preloader'
import StatisticCounter from 'components/molecules/StatisticCounter'
import Table from 'components/molecules/Table'
import { useJsonReportLoader } from 'hooks'
import { ReportType } from 'models'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InstitutionDetails, Participants } from '../../models'
import style from './index.module.scss'

type RbcVerifyParticipantsProps = {
    ballotingYear: number
}

function RbcVerifyParticipants({ ballotingYear }: RbcVerifyParticipantsProps) {
    const { t } = useTranslation('election')
    const [participants, setParticipants] = useState<Participants>()

    const { isLoading } = useJsonReportLoader<Participants>({
        reportType: ReportType.RegionalBahaiCouncilInstitutions,
        params: {
            ballotingYear,
            status: 'ACTIVE'
        },
        onReportReady: (result) => {
            if (result?.institutions) {
                const sortedInstitutions = result.institutions.sort((a, b) => a.name?.localeCompare(b.name))
                setParticipants({ ...result, institutions: sortedInstitutions })
            }
        }
    })

    const columns: ColumnsType<InstitutionDetails> = [
        {
            title: t('institution'),
            width: 345,
            render: (details: InstitutionDetails) => (
                <div style={{ maxWidth: 313 }}>
                    <OptionalTooltip contentWrapperClassName="ellipsis">
                        {details.name}
                    </OptionalTooltip>
                </div>
            )
        },
        {
            title: t('state'),
            width: 200,
            render: (details: InstitutionDetails) => (
                <div style={{ maxWidth: 168 }}>
                    <OptionalTooltip contentWrapperClassName="ellipsis">
                        {details.states}
                    </OptionalTooltip>
                </div>
            )
        },
        {
            title: t('administration:total_number_of_lsa'),
            width: 200,
            render: (details: InstitutionDetails) => (
                <div style={{ maxWidth: 168 }}>
                    <OptionalTooltip contentWrapperClassName="ellipsis">
                        {details.totalNumberOfLsa}
                    </OptionalTooltip>
                </div>
            )
        },
        {
            title: t('total_number_of_individuals'),
            width: 200,
            render: (details: InstitutionDetails) => (
                <div style={{ maxWidth: 168 }}>
                    <OptionalTooltip contentWrapperClassName="ellipsis">
                        {details.totalNumberOfEligibleIndividuals}
                    </OptionalTooltip>
                </div>
            )
        },
        {
            title: t('eligible_to_serve'),
            width: 200,
            render: (details: InstitutionDetails) => (
                <div style={{ maxWidth: 168 }}>
                    <OptionalTooltip contentWrapperClassName="ellipsis">
                        {details.candidates}
                    </OptionalTooltip>
                </div>
            )
        },
        {
            title: t('eligible_to_vote'),
            width: 200,
            render: (details: InstitutionDetails) => (
                <div style={{ maxWidth: 168 }}>
                    <OptionalTooltip contentWrapperClassName="ellipsis">
                        {details.voters}
                    </OptionalTooltip>
                </div>
            )
        }
    ]

    return (
        <>
            {isLoading && <Preloader />}
            {participants && <Table
                obsSize="small"
                obsHeaderSize="small"
                headerDividerVisible
                dataSource={participants.institutions}
                columns={columns}
                pagination={false}
                stickyOffset="159px"
                rootClassName={style.table}
                rowKey="electionId"
                caption={
                    <div className={style.captionWrapper}>
                        <div className={style.statistic}>
                            <StatisticCounter
                                value={participants.summary.totalNumberOfRecords ?? 0}
                                title={t('total_number_of_records')}
                            />
                            <StatisticCounter
                                value={participants.summary.totalNumberOfLsa ?? 0}
                                title={t('administration:total_number_of_lsa')}
                            />
                            <StatisticCounter
                                value={participants.summary.totalNumberOfEligibleToServe ?? 0}
                                title={t('total_number_of_eligible_to_serve')}
                            />
                            <StatisticCounter
                                value={participants.summary.totalNumberOfEligibleToVote ?? 0}
                                title={t('total_number_of_eligible_to_vote')}
                            />
                        </div>
                    </div>
                }
            />
            }
        </>
    )
}

export default RbcVerifyParticipants